<template>
  <FullModal>
    <section class="learn-more">
      <div class="right-col">
        <div class="header">
          <h1 class="title">
            {{ props.scooter.scooter_brand_name }} {{ props.scooter.scooter_model_name }}
          </h1>
          <p class="text" v-if="!props.scooter.is_custom">{{ $t('t-or-similar') }}</p>
        </div>
        <dl class="features">
          <div v-if="props.scooter.scooter_category_name" class="feature">
            <dt>{{ $t('t-category') }}:</dt>
            <dd>{{ props.scooter.scooter_category_name }}</dd>
          </div>
          <div v-if="props.scooter.transmission" class="feature">
            <dt>{{ $t('t-transmission') }}:</dt>
            <dd>{{ props.scooter.transmission }}</dd>
          </div>
        </dl>
        <p v-if="props.scooter.total_cost" class="price">
          <span>
            <span class="price-amount">{{ priceConverter(props.scooter.total_cost) + ' ' }}</span>
            <span class="price-currency">{{ props.scooter.currency }}</span>
          </span>
          <span class="price-detail"
            >{{ $t('t-price-for') }} {{ props.scooter.total_days }} {{ $t('t-days') }}</span
          >
        </p>
        <div v-if="props.scooter.company" class="company">
          <div class="company-wrapper">
            <span class="company-name">{{ props.scooter.company.name }}</span>
            <img
              class="company-logo"
              width="40"
              height="40"
              :src="props.scooter.company.logo_url.replace('https', 'http')"
              :alt="`${props.scooter.company.name} company's logo`"
            />
            <div class="company-rating">
              <img
                width="16"
                height="16"
                :src="require('@/assets/images/icons/star.svg')"
                alt="rating"
              />
              <span>{{
                props.scooter.company.average_rating === 0
                  ? this.$t('t-not-enough-rating')
                  : props.scooter.company.average_rating
              }}</span>
            </div>
          </div>
          <ImportantInfoButton />
        </div>
        <button class="button-book" @click="bookScooter">{{ $t('t-book-scooter') }}</button>
        <ul class="advantages">
          <li v-for="scooter_tag in props.scooter.scooter_tags" :key="scooter_tag.id">
            {{ scooter_tag.name }}
          </li>
        </ul>
        <p class="description" v-html="props.scooter.description"></p>
      </div>
      <Gallery class="gallery" :pictures="props.scooter.photo_urls" />
    </section>
  </FullModal>
</template>

<script>
import FullModal from '@/components/FullModal.vue'
import Gallery from './components/Gallery.vue'
import ImportantInfoButton from '@/components/ImportantInfo/ImportantInfoButton.vue'

export default {
  components: {
    FullModal,
    Gallery,
    ImportantInfoButton
  },
  methods: {
    priceConverter (price) {
      return `${price
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
    },
    bookScooter () {
      this.$emit('viewDeal', this.scooter.id)
    }
  },
  props: ['scooter'],
  emits: ['viewDeal'],
  setup (props) {
    return {
      props
    }
  }
}
</script>

<style scoped lang="scss">
h1,
p,
dl,
dd,
ul {
  margin: 0;
  padding: 0;
}

.learn-more {
  font-family: 'Raleway', sans-serif;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;

  @media (max-width: $mobile-max-width) {
    grid-template-columns: 1fr;
    min-height: 100vh;
  }
}
.right-col {
  display: flex;
  flex-direction: column;
  order: 1;
}

.header {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  gap: 4px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  color: #0e0e0e;

  @media (max-width: $mobile-max-width) {
    font-size: 12px;
  }
}

.title {
  font-size: 36px;
  font-weight: 700;
  color: #0e0e0e;
  order: 1;

  @media (max-width: $mobile-max-width) {
    font-size: 24px;
    font-weight: 400;
    order: 0;
  }
}

.features {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;

  @media (max-width: $mobile-max-width) {
    gap: 16px;
    margin-top: 20px;
  }
}

.feature {
  display: flex;
  gap: 4px;

  & dt,
  & dd {
    font-size: 16px;
    font-weight: 400;
    color: #0e0e0e;
  }
}

.price {
  display: flex;
  gap: 16px;
  align-items: baseline;
  margin-top: 24px;

  @media (max-width: $mobile-max-width) {
    gap: 16px;
    justify-content: space-between;
  }
}

.price-amount,
.price-currency {
  font-size: 20px;
  font-weight: 600;
  color: #0e0e0e;
}

.price-amount {
  font-size: 28px;
}

.company {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-top: 24px;

  @media (max-width: 1100px) {
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 20px;
  }
}

.company-wrapper {
  display: grid;
  column-gap: 16px;
  justify-content: start;
  align-content: space-between;
  grid-template-areas:
    'logo name'
    'logo rating';
  color: #0e0e0e;
  font-size: 14px;
}

.company-logo {
  grid-area: logo;
}

.company-name {
  grid-area: name;
  font-weight: 700;
}

.company-rating {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  grid-area: rating;
  font-weight: 400;
}
.advantages {
  column-count: 2;
  font-size: 16px;
  font-weight: 400;
  color: #2c1d2a;
  list-style: none;
  margin-top: 24px;

  @media (max-width: $mobile-max-width) {
    column-count: 1;
  }

  li {
    display: flex;
    gap: 16px;
    margin-bottom: 8px;

    &::before {
      content: '';
      width: 22px;
      height: 22px;
      background-image: url('~@/assets/images/icons/check.svg');
    }
  }
}

.button-book {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #fc9544;
  width: 90%;
  padding: 10px 20px;
  margin-top: 24px;
  border: 0.5px solid #fc9544;
  border-radius: 100px;
  text-decoration: none;
  transition-duration: $transition-duration;
  background: #fff;

  @media (max-width: $mobile-max-width) {
    position: fixed;
    bottom: 16px;
    z-index: 2;
  }

  &:hover,
  &:focus,
  &:active {
    border-width: 1px;
    background-color: #fff5ed;
  }

  &:active {
    border-color: #ff7e18;
    color: #ff7e18;
  }
}

.description {
  margin-top: 16px;
}

.gallery {
  @media (max-width: $mobile-max-width) {
    margin-top: 52px;
  }
}
</style>
