<template>
  <div class="filter-and-sort-buttons-wrapper">
    <div @click="this.showSorting">
      <img src="@/assets/images/icons/sort.svg" alt="sort" />
      {{ $t('t-sort-by') }}
    </div>
    <div @click="this.showFilters">
      <img src="@/assets/images/icons/filter.svg" alt="filter" />
      {{ $t('t-filters') }}
    </div>
  </div>
</template>

<script>
export default {
  emits: ['showFilters', 'showSorting'],
  methods: {
    showFilters () {
      this.$emit('showFilters')
    },
    showSorting () {
      this.$emit('showSorting')
    }
  }
}
</script>

<style scoped>
.filter-and-sort-buttons-wrapper {
  justify-content: space-around;
  color: #0e0e0e;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  display: none;
}
.filter-and-sort-buttons-wrapper div {
  cursor: pointer;
}
@media (max-width: 576px) {
  .filter-and-sort-buttons-wrapper {
    display: flex;
  }
}
</style>
