<template>
  <div class="main-driver-details-wrapper">
    <h2 class="title">{{ $t('t-main-driver-details') }}</h2>
    <div class="driver-details-grid">
      <div class="driver-details-grid-item">
        <div>{{ $t('t-full-name') }}</div>
        <div>{{ this.name }}</div>
      </div>
      <div class="driver-details-grid-item">
        <div>{{ $t('t-email-company') }}</div>
        <div>{{ this.email }}</div>
      </div>
      <div class="driver-details-grid-item">
        <div>{{ $t('t-contact-number-with-whatsup') }}</div>
        <div>{{ this.phone }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['name', 'email', 'phone']
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.main-driver-details-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 24px;
  border-radius: 32px;
  border: 1px solid #d4d1d0;
  font-family: Raleway;
}
.title {
  color: #2c1d2a;
  font-size: 24px;
  font-weight: 600;
}
.driver-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 24px;
  column-gap: 8px;
}
.driver-details-grid-item {
  grid-column: 1;
  height: fit-content;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  color: #2c1d2a;
}
.driver-details-grid-item:nth-child(3) {
  grid-column: 2;
  grid-row: 2;
  height: fit-content;
}
.driver-details-grid-item div:first-child {
  color: #888888;
}
</style>
