<template>
  <div class="price-breakdown-wrapper">
    <h2 class="title">{{ $t('t-price-breakdown') }}</h2>
    <div class="record">
      <div>{{ $t('t-scooter-price-for') }} {{ daysDifference }} {{ $t('t-days') }}</div>
      <div>{{ priceConverter(totalCost - additionalCost) }} {{ currency_symbol }}</div>
    </div>
    <a-divider v-if="filteredAdditionalServices.length" />
    <div class="additional-services" v-if="filteredAdditionalServices.length">
      <h3 class="title">{{ $t('t-additional-services') }}</h3>
      <div v-for="service in filteredAdditionalServices" :key="service.id" class="record">
        <div v-if="!service.price_per_day">
          {{ formatServiceName(service) }}
        </div>
        <div v-if="service.price_per_day && getServiceCount(service) > 1">
          {{ service.name }} x {{ getServiceCount(service) }} x {{ daysDifference }} {{ $t('t-days') }}
        </div>
        <div v-else-if="service.price_per_day">
          {{ service.name }} x {{ daysDifference }} {{ $t('t-days') }}
        </div>
        <div v-if="service.price_per_day">
          {{ priceConverter(service.price_per_day * daysDifference * (getServiceCount(service) || 1)) }} {{ currency_symbol }}
        </div>
        <div v-else-if="service.price_one_time">
          {{ priceConverter(service.price_one_time * (getServiceCount(service) || 1)) }} {{ currency_symbol }}
        </div>
        <div v-else>{{ $t('t-free') }}</div>
      </div>
    </div>
    <a-divider />
    <div class="record">
      <div class="title">{{ $t('t-total-cost') }}</div>
      <div class="title">{{ priceConverter(totalCost) }} {{ currency_symbol }}</div>
    </div>

    <div v-if="this.showCheckboxes" class="legal">
      <div class="legal-block">
        <input v-model="policyChecked" type="checkbox" />
        <a @click.prevent="state.isOpened = true" class="clickable-text">
          {{ $t('t-checkbox-agree-privacy-policy') }}
        </a>
        <ImportantInfoModal v-model:open="state.isOpened" />
      </div>

      <div class="legal-block">
        <input v-model="updatesChecked" type="checkbox" />
        <div>{{ $t('t-checkbox-subscribe-to-marketing-emails') }}</div>
      </div>
    </div>

    <button v-if="this.buttonTitle"
     :disabled="(!this.policyChecked || !this.allFieldsFilled) && this.showCheckboxes"
      @click="$emit('submit')" class="submit">
      {{ $t(this.buttonTitle) }}
    </button>
  </div>
</template>

<script>
import { Divider } from 'ant-design-vue'
import ImportantInfoModal from './ImportantInfo/ImportantInfoModal.vue'
import { reactive } from 'vue'

export default {
  props: ['pickUpDate', 'dropOffDate', 'totalCost', 'showCheckboxes', 'buttonTitle', 'additionalServices', 'selectedServices', 'allFieldsFilled'],
  emits: ['submit', 'updateMarketingEmails'],
  watch: {
    updatesChecked (newValue) {
      this.$emit('updateMarketingEmails', newValue)
    }
  },

  setup () {
    const state = reactive({
      isOpened: false
    })

    return {
      state
    }
  },
  data () {
    return {
      policyChecked: false,
      updatesChecked: false
    }
  },
  components: {
    ADivider: Divider,
    ImportantInfoModal
  },
  computed: {
    filteredAdditionalServices () {
      if (!this.selectedServices || !this.additionalServices) {
        return []
      }
      const selectedServiceIds = this.selectedServices.map(s => s.additional_service_id)
      return this.additionalServices.filter(service => selectedServiceIds.includes(service.id))
    },
    additionalCost () {
      return this.filteredAdditionalServices.reduce((total, service) => {
        let cost = 0
        const count = this.getServiceCount(service)
        if (service.price_per_day) {
          cost += this.daysDifference * service.price_per_day * count
        }
        if (service.price_one_time) {
          cost += service.price_one_time * count
        }
        return total + cost
      }, 0)
    },
    currency_symbol () {
      return localStorage.getItem('selectedCurrencySymbol')
    },
    daysDifference () {
      const pickup = new Date(this.pickUpDate)
      const dropoff = new Date(this.dropOffDate)
      const diffTime = Math.abs(dropoff.getTime() - pickup.getTime())
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      return diffDays
    }
  },
  methods: {
    priceConverter (price) {
      if (price === null || price === undefined || isNaN(price)) {
        return null
      } else {
        return price
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
    },
    formatServiceName (service) {
      let result = service.name
      const count = this.getServiceCount(service)
      if (count) {
        result += ` x ${count}`
      }
      return result
    },
    getServiceCount (service) {
      const selectedService = this.selectedServices.find(selected => selected.additional_service_id === service.id)
      return selectedService ? selectedService.count : 0
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.price-breakdown-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 24px;
  border-radius: 32px;
  background-color: #fff5ed;
  font-family: Raleway;
}

.title {
  color: #2c1d2a;
  font-size: 24px;
  font-weight: 600;
}

.record {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #050505;
  font-size: 20px;
  font-weight: 400;
}

.submit {
  border: none;
  outline: none;
  border-radius: 100px;
  background: #fc9544;
  height: 60px;
  color: #fff;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  transition: background-color 0.3s;
}

.submit:not(:disabled):hover {
  background: #861920;
}

.submit:disabled {
  background: #d4d1d0;
}

.legal {
  display: flex;
  flex-direction: column;
  color: #2c1d2a;
  font-size: 14px;
  font-weight: 400;
}

.legal-block {
  display: flex;
  gap: 8px;
}

.clickable-text {
  text-decoration: underline;
  color: #fc9544;
  cursor: pointer;
}

.clickable-text:hover {
  color: #ff7e18;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 16px;
  }
}
</style>
