<template>
  <footer class="footer">
      <div class="wrapper">
        <img class="logo" width="67" height='80' src="../assets/logo.svg" alt="Logo" />
        <address class="contacts">
          <a class="phone" href="tel:+6281239570946">(+62) 812-3957-09-46</a>
          <p class="address">Graha Merdeka Renon Unit 15, Jl. Merdeka, Desa/Kelurahan Sumerta
Kelod, Kec. Denpasar Timur, Kota Denpasar, Provinsi Bali,
Kode Pos: 80239</p>
        </address>
        <p class="copyright">
        &copy; 2023 Mikes Hub Solutions. All rights reserved.
        </p>
      </div>
      <ul class="nav">
        <!-- <li><router-link class="nav-item" to="/about-us" data-key="t-about-us">{{ $t("t-about-us") }}</router-link></li>
        <li><router-link class="nav-item" to="/contact" data-key="t-contact">{{ $t("t-contact") }}</router-link></li> -->
        <li><router-link class="nav-item" to="/faq" data-key="t-faqs">{{ $t("t-faq") }}</router-link></li>
        <li><router-link class="nav-item" to="/privacy-policy" data-key="t-privacy-policy">{{ $t("t-privacy-policy") }}</router-link></li>
        <!-- <li><router-link class="nav-item" to="/support" data-key="t-support">{{ $t("t-support") }}</router-link></li> -->
      </ul>
  </footer>
</template>

<style scoped lang="scss">
$mobile-max-width: 767px;
.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 24px 0;

  @media (max-width: $mobile-max-width) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.wrapper {
  display: grid;
  grid-template-areas:
            "logo contacts"
            "logo copyright";
  gap: 10px 24px;

  @media (max-width: $mobile-max-width) {
    grid-template-areas:
            "contacts logo"
            "copyright logo";
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    order: 1;
  }
}

.contacts,
.phone,
.copyright {
  margin: 0;
}
.contacts {
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 400;
  grid-area: contacts;
}
.logo {
  grid-area: logo;
}
.phone {
  color: #050505;
  text-decoration: none;
}
.copyright {
  grid-area: copyright;
  color: #888888;
}
.nav {
  display: flex;
  gap:10px 24px;

  @media (max-width: $mobile-max-width) {
    flex-direction: column;
    gap: 15px;
}
}
.nav-item {
  color: #050505;
  text-decoration: none;
}
</style>
