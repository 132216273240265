<template>
  <section class="faq" id="faq">
    <h2 class="title">{{ $t("t-faq") }}</h2>
    <Collapse :items="formattedQuestions" />
    <a class="button" v-if="currentPath !== '/faq'" href="/faq">{{ $t('t-open-all-faq') }}</a>
  </section>
</template>

<script>
import Collapse from '@/components/Collapse.vue'

export default {
  components: {
    Collapse
  },
  setup () {
    const mocksFaq = [
      {
        title: 't-faq-how-to-rent-scooter',
        id: 1,
        text: 't-faq-how-to-rent-scooter-text'
      },
      {
        title: 't-faq-what-documents-need',
        id: 2,
        text: 't-faq-what-documents-need-text'
      },
      {
        title: 't-faq-how-get-bali-documents',
        id: 3,
        text: 't-faq-how-get-bali-documents-text',
        isHtml: true
      },
      {
        title: 't-faq-do-you-have-age-restristions',
        id: 4,
        text: 't-faq-do-you-have-age-restristions-text'
      },
      {
        title: 't-faq-rules-for-rent',
        id: 5,
        text: 't-faq-rules-for-rent-text'
      },
      {
        title: 't-faq-what-pays-available',
        id: 6,
        text: 't-faq-what-pays-available-text'
      },
      {
        title: 't-faq-can-i-cancel-order',
        id: 7,
        text: 't-faq-can-i-cancel-order-text'
      },
      {
        title: 't-faq-how-work-delivery',
        id: 8,
        text: 't-faq-how-work-delivery-text'
      },
      {
        title: 't-faq-what-to-do-if-accedent',
        id: 9,
        text: 't-faq-what-to-do-if-accedent-text'
      },
      {
        title: 't-faq-how-to-record-condition-of-the-scooter',
        id: 10,
        text: 't-faq-how-to-record-condition-of-the-scooter-text'
      },
      {
        title: 't-faq-how-to-extend-scooter',
        id: 11,
        text: 't-faq-how-to-extend-scooter-text'
      },
      {
        title: 't-faq-how-to-check-scooter-after-delivery',
        id: 12,
        text: 't-faq-how-to-check-scooter-after-delivery-text'
      },
      {
        title: 't-faq-what-to-do-if-broken',
        id: 13,
        text: 't-faq-what-to-do-if-broken-text'
      },
      {
        title: 't-faq-what-to-do-if-broken-when-delivery',
        id: 14,
        text: 't-faq-what-to-do-if-broken-when-delivery-text'
      },
      {
        title: 't-faq-what-to-do-if-broken-when-use',
        id: 15,
        text: 't-faq-what-to-do-if-broken-when-use-text'
      },
      {
        title: 't-faq-how-work-drop-off-scooter',
        id: 16,
        text: 't-faq-how-work-drop-off-scooter-text'
      },
      {
        title: 't-faq-how-work-if-i-didnt-provide-scooter',
        id: 17,
        text: 't-faq-how-work-if-i-didnt-provide-scooter-text'
      }
    ]

    return {
      mocksFaq
    }
  },
  data () {
    return {
      questions: [],
      currentPath: this.$route.path
    }
  },

  computed: {
    filteredQuestions () {
      if (this.$route.path === '/faq') {
        return this.questions.filter(question => question.id in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17])
      } else {
        return this.questions.filter(question => question.id in [1, 2, 3, 10, 12, 13])
      }
    },
    formattedQuestions () {
      return this.filteredQuestions.map(question => {
        if (question.isHtml) {
          return { ...question, text: this.$t(question.text) }
        }
        return { ...question, text: this.$t(question.text) }
      })
    }
  },

  methods: {
    getQuestions () {
      this.questions = this.mocksFaq
    }
  },

  created () {
    this.getQuestions()
  }
}
</script>

<style scoped lang="scss">
.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 120px;

  @media (max-width: $mobile-max-width) {
    gap: 32px;
    margin-bottom: 60px;
  }
}

.title {
  font-family: 'Raleway', sans-serif;
  font-size: 42px;
  font-weight: 700;
  margin: 0;

  @media (max-width: $mobile-max-width) {
    font-family: 'Raleway', sans-serif;
    font-size: 32px;
    font-weight: 600;
  }
}

.button {
  border-radius: 100px;
  background-color: #fc9544;
  padding: 16px 40px;
  color: #fff;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  margin: 40px auto 0 auto;
  border: none;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background-color: #861920;
}
</style>
