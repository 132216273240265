<template>
  <div id="app">
    <PageHeader />
    <router-view />
    <PageFooter />
    <TawkToChat />
  </div>
</template>

<script>
import PageHeader from './components/PageHeader/PageHeader.vue'
import PageFooter from './components/PageFooter.vue'
import TawkToChat from './components/TawkToChat.vue'

export default {
  name: 'App',
  components: {
    TawkToChat,
    PageHeader,
    PageFooter
  }
}
</script>
<style>
#app {
  width: 90%;
  max-width: 1400px;
  margin: auto;
  overflow: hidden;
}
@media (max-width: 768px) {
  #app {
    width: calc(100% - 16px);
  }
}
</style>
