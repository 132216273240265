<template>
  <div>
    <spinner-view v-if="this.isLoading || this.isUploading" :isLoading="this.isLoading || this.isUploading" />
    <div :title="$t('t-payment-information')" v-else-if="iframeUrl">
      <iframe :src="iframeUrl" height="500px" width="100%" frameborder="0"></iframe>
    </div>
    <div v-else-if="booking" class="wrapper">
      <ConfirmationBlock class="grid-item" :status="this.booking.status" />
      <BookingInformation
        class="grid-item"
        :brand="this.scooterDetails.brand_name"
        :model="this.scooterDetails.model_name"
        :pickUpDate="this.booking.pickup_date"
        :pickUpPlace="this.booking.pick_up_place"
        :dropOffDate="this.booking.dropoff_date"
        :photo="this.scooterDetails.photo_urls[0]"
        :bookingNumber="this.bookingReferenceNumber"
        :lat="this.booking.lat"
        :lng="this.booking.lng"
        :is_custom="this.scooterDetails.is_custom"
      />
      <CompanyInformation
        class="grid-item"
        :logo="this.company.logo"
        :companyName="this.company.name"
        :companyRating="this.companyRating.average_rating"
      />
      <MainDriverDetails
        class="grid-item"
        :name="this.booking.full_name"
        :email="this.booking.email"
        :phone="this.booking.phone"
      />
      <PriceBreakdown
        class="grid-item"
        :pickUpDate="this.booking.pickup_date"
        :dropOffDate="this.booking.dropoff_date"
        :totalCost="this.booking.total_cost"
        :additionalServices="this.additionalServices"
        :selectedServices="this.selectedServices"
      />
      <UploadFilesBlock
        class="grid-item"
        :status="this.booking.status"
        :uploadedImages="this.uploadedImages"
        :uploadedVideos="this.uploadedVideos"
        @handleFileUpload="handleFileUpload"
        @playVideo="playVideo"
        @viewImage="viewImage"
      />
      <FullModal v-model:open="state.isVideoModalOpened" :url="videoUrl" :isImage="isImage" />
    </div>
  </div>
</template>

<script>
import SpinnerView from '@/components/SpinnerView.vue'
import ConfirmationBlock from './components/Confirmation.vue'
import BookingInformation from '../../components/BookingInformation.vue'
import CompanyInformation from '../DealPage/components/CompanyInformation.vue'
import MainDriverDetails from './components/MainDriverDetails.vue'
import PriceBreakdown from '@/components/PriceBreakdown.vue'
import UploadFilesBlock from './components/UploadFilesBlock.vue'
import FullModal from '../../components/FullModal.vue'
import fetchWrapper from '@/utils/fetchWrapper'
import performRedirect from '@/utils/redirectLogic'
import formatFileSize from '@/utils/formatFileSize'
import { useRouter } from 'vue-router'
import { useCurrencyStore } from '@/stores/currency'
import { storeToRefs } from 'pinia'
import { watch, onMounted, ref, reactive } from 'vue'
import { IMAGE_EXTENSIONS } from '../../constants/constants'

export default {
  components: {
    SpinnerView,
    ConfirmationBlock,
    BookingInformation,
    CompanyInformation,
    MainDriverDetails,
    PriceBreakdown,
    UploadFilesBlock,
    FullModal
  },
  setup (props, { emit }) {
    const state = reactive({
      isVideoModalOpened: false
    })

    const currencyStore = useCurrencyStore()
    const { currencyCode, id: currencyId } = storeToRefs(currencyStore)
    const bookingReferenceNumber = window.location.pathname.split('/')[2]
    const router = useRouter()
    const selectedServices = ref(null)
    const isLoading = ref(false)
    const booking = ref(null)
    const iframeUrl = ref('')
    const scooterDetails = ref(null)
    const additionalServices = ref(null)
    const company = ref(null)
    const companyRating = ref(null)
    const uploadedImages = ref([])
    const uploadedVideos = ref([])
    const videoUrl = ref('')
    const isImage = ref(false)
    const isUploading = ref(false)

    const fetchAdditionalServices = async () => {
      try {
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/additional_service/all?currency_id=${currencyId.value}`
        )
        if (response.ok) {
          const data = await response.json()
          additionalServices.value = data.content.map((service) => ({
            ...service,
            count: service.max_count ? 0 : service.count
          }))
        } else {
          console.error('Error fetching additional services:', response.statusText)
        }
      } catch (error) {
        console.error('Error fetching additional services:', error)
      }
    }

    const fetchBookingDetails = async () => {
      try {
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/booking/${bookingReferenceNumber}?currency_id=${currencyId.value}`
        )
        if (response.ok) {
          const data = await response.json()
          booking.value = data
          const status = data.status
          if (status === 'FINISHED') {
            emit('toggleIsFinishedStatus')
          }

          performRedirect(router, status, bookingReferenceNumber)
          selectedServices.value = data.additional_services
          additionalServices.value.forEach((service) => {
            const selectedService = selectedServices.value.find(
              (selected) => selected.additional_service_id === service.id
            )
            if (selectedService) {
              if (service.max_count) {
                service.count = selectedService.count
              } else {
                service.selected = selectedService.count === 1
              }
            }
          })

          if (data.payment_info && data.payment_info.invoice_url) {
            iframeUrl.value = data.payment_info.invoice_url
            emit('toggleToOnlinePayment')
          }
        }
      } catch (error) {
        console.error('Error fetching booking details:', error)
        router.push('/not-found')
      }
      if (booking.value && booking.value.scooter_id) {
        await Promise.all([
          fetchScooterDetails(booking.value.scooter_id),
          fetchAdditionalServices()
        ])

        await Promise.all([fetchCompanyData(), fetchCompanyRating()])
      }

      isLoading.value = false
    }

    const fetchCompanyData = async () => {
      const companyResponse = await fetchWrapper(
        `${API_BASE_URL}v1/company/${scooterDetails.value.company_id}`
      )
      const companyData = await companyResponse.json()
      company.value = {
        name: companyData.name,
        address: companyData.address,
        description: companyData.description,
        email: companyData.email,
        logo: companyData.logo_url
      }
    }

    const fetchCompanyRating = async () => {
      const companyReviewResponse = await fetchWrapper(
        `${API_BASE_URL}v1/company_rating/${scooterDetails.value.company_id}`
      )
      const companyReviewData = await companyReviewResponse.json()
      companyRating.value = {
        average_rating: companyReviewData.average_rating,
        total_reviews: companyReviewData.total_reviews,
        ratings: companyReviewData.ratings
      }
    }

    const fetchScooterDetails = async (scooterId) => {
      try {
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/scooter/${scooterId}?currency_id=${currencyId.value}`
        )
        if (response.ok) {
          scooterDetails.value = await response.json()
        } else {
          console.error('Error fetching scooter details:', response.statusText)
        }
      } catch (error) {
        console.error('Error fetching scooter details:', error)
      }
    }

    const handleFileUpload = async (files) => {
      if (!files.length) return

      isUploading.value = true

      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i])
      }

      try {
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/upload/bookings/${bookingReferenceNumber}/multiple`,
          {
            method: 'POST',
            headers: {
              accept: 'application/json'
            },
            body: formData
          },
          true,
          'Success!'
        )

        if (response.ok) {
          await response.json()

          // Process uploaded files
          files.forEach((file) => {
            const reader = new FileReader()
            reader.onload = (event) => {
              const uploadedFile = {
                name: file.name,
                url: event.target.result,
                type: file.type,
                size: formatFileSize(file.size)
              }

              if (file.type.startsWith('image/') || file.type.endsWith('heic')) {
                uploadedImages.value.push(uploadedFile)
              } else if (file.type.startsWith('video/') || file.type.endsWith('hevc')) {
                uploadedVideos.value.push(uploadedFile)
              } else {
                console.log(`Unsupported file type: ${file.type}`)
              }
            }
            reader.readAsDataURL(file)
          })
        } else {
          console.error('Error uploading files:', response.statusText)
        }
      } catch (error) {
        console.error('Error uploading files:', error)
      } finally {
        isUploading.value = false
        window.location.reload()
      }
    }

    const fetchUploadedFiles = async () => {
      try {
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/upload/bookings/${bookingReferenceNumber}`
        )
        if (response.ok) {
          const data = await response.json()

          data.files.forEach((file) => {
            const extension = file.slice(file.lastIndexOf('.')).toLowerCase()
            if (IMAGE_EXTENSIONS.includes(extension) || extension === '.heic') {
              uploadedImages.value.push(file)
            } else if (extension === '.hevc') {
              uploadedVideos.value.push(file)
            } else {
              uploadedVideos.value.push(file)
            }
          })
        } else {
          console.error('Error fetching uploaded files:', response.statusText)
        }
      } catch (error) {
        console.error('Error fetching uploaded files:', error)
      }
    }

    const playVideo = (file) => {
      const isFromStorage = !file?.name

      if (isFromStorage) {
        videoUrl.value = file
      } else {
        const video = uploadedVideos.value.find((video) => video.name === file.name)
        videoUrl.value = video?.url
      }

      isImage.value = false
      state.isVideoModalOpened = true
      setTimeout(() => {
        const videoPlayer = document.querySelector('video')
        if (videoPlayer) {
          videoPlayer.load()
          videoPlayer.play()
        }
      }, 0)
    }

    const viewImage = (url) => {
      videoUrl.value = url
      isImage.value = true
      state.isVideoModalOpened = true
    }

    watch(currencyCode, async () => {
      await fetchAdditionalServices()
      isLoading.value = false
    })

    onMounted(async () => {
      isLoading.value = true
      await fetchAdditionalServices()
      await fetchBookingDetails()
      await fetchUploadedFiles()
    })

    return {
      currencyCode,
      currencyId,
      selectedServices,
      isLoading,
      booking,
      scooterDetails,
      iframeUrl,
      additionalServices,
      company,
      companyRating,
      uploadedImages,
      handleFileUpload,
      uploadedVideos,
      bookingReferenceNumber,
      videoUrl,
      state,
      playVideo,
      viewImage,
      isImage,
      isUploading
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
.grid-item {
  grid-column: span 1;
}
.booking-info,
.upload-files {
  min-width: 0;
}
video {
  width: 80%;
  margin: 0 auto;
}
.grid-item {
  grid-column: 1;
}
.grid-item:nth-child(5),
.grid-item:nth-child(6) {
  grid-column: 2;
  height: fit-content;
}
.grid-item:nth-child(5) {
  grid-row: 1;
}
.grid-item:nth-child(6) {
  grid-row: 2;
}
@media (max-width: 900px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
}
</style>
