<template>
  <div class="total">{{ this.quantity }} {{ $t("t-scooters-found") }}</div>
</template>

<script>
export default {
  props: ['quantity']
}
</script>

<style scoped>
.total {
  color: #0e0e0e;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
}
</style>
