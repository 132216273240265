<template>
  <div class="wrapper">
    <div>
      <h2 class="title">{{ $t('t-main-driver-details') }}</h2>
      <p class="addition">{{ $t('t-driver-license') }}</p>
    </div>
    <div>
      <input
        class="input"
        type="text"
        required
        :placeholder="$t('t-full-name')"
        :value="this.fullName"
        @input="this.onFullNameChange"
      />
      <div v-if="formSubmitted && v$.mainDriver.fullName.$invalid" class="error">
        {{ $t('t-error-full-name-required') }}
      </div>
    </div>
    <div>
      <input
        class="input"
        type="email"
        required
        :placeholder="$t('t-email-address')"
        :value="this.emailAddress"
        @input="this.onEmailAddressChange"
      />
      <div v-if="formSubmitted && v$.mainDriver.emailAddress.$invalid" class="error">
        {{ $t('t-error-email-address-required') }}
      </div>
    </div>
    <div class="input-group" data-input-flag>
      <button
        class="flag-button btn btn-light border custom-flag-button"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          src="@/assets/images/flags/id.svg"
          alt="flag img"
          height="20"
          class="country-flagimg rounded"
        /><span class="ms-2 country-codeno"> {{ countryCode }}</span>
      </button>
      <input
        class="input form-control flag-input custom-flag-input"
        type="text"
        required
        :placeholder="$t('t-contact-number-with-whatsup')"
        :value="this.contactNumber.number"
        @input="this.onContactNumberChange"
      />
      <div class="dropdown-menu w-100">
        <div class="p-2 px-3 pt-1 searchlist-input">
          <input
            type="text"
            class="form-control form-control-sm border search-countryList"
            :placeholder="$t('t-search-country-name-or-country-code')"
          />
        </div>
        <ul class="list-unstyled dropdown-menu-list mb-0"></ul>
      </div>
      <div v-if="formSubmitted && v$.mainDriver.contactNumber.number.$invalid" class="error">
        {{ $t('t-error-contact-number-required') }}
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { COUNTRY_LIST } from '../../../constants/countryList'
import { ref, onMounted } from 'vue'

export default {
  props: ['fullName', 'emailAddress', 'contactNumber'],
  emits: ['update:fullName', 'update:emailAddress', 'update:contactNumber'],
  data () {
    return {
      v$: useValidate(),
      formSubmitted: false
    }
  },
  methods: {
    onFullNameChange (event) {
      this.formSubmitted = false
      this.$emit('update:fullName', event.target.value)
    },
    onEmailAddressChange (event) {
      this.formSubmitted = false
      this.$emit('update:emailAddress', event.target.value)
    },
    onContactNumberChange (event) {
      this.formSubmitted = false
      const number = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
      this.$emit('update:contactNumber', { number, code: this.countryCode })
    }
  },
  validations () {
    return {
      mainDriver: {
        fullName: { required },
        emailAddress: { required },
        contactNumber: { required }
      }
    }
  },
  setup (props, context) {
    const countryCode = ref('+62')

    function loadCountryListData (countryList) {
      const mainArray = Array.from(document.querySelectorAll('[data-input-flag]'))
      let flags = ''
      const arr = Array.from(countryList)

      for (let index = 0; index < arr.length; index++) {
        flags +=
          // eslint-disable-next-line no-multi-str
          '<li class="dropdown-item d-flex">\
            <div class="flex-shrink-0 me-2"><img src="' +
          arr[index].flagImg +
          // eslint-disable-next-line no-multi-str
          '" alt="country flag" class="options-flagimg" height="20"></div>\
                <div class="flex-grow-1">\
                <div class="d-flex"><div class="country-name me-1">' +
          arr[index].countryName +
          '</div><span class="countrylist-codeno text-muted">' +
          arr[index].countryCode +
          // eslint-disable-next-line no-multi-str
          '</span></div>\
            </div>\
            </li>'
      }
      for (let i = 0; i < mainArray.length; i++) {
        mainArray[i].querySelector('.dropdown-menu-list').innerHTML = ''
        mainArray[i].querySelector('.dropdown-menu-list').innerHTML = flags
        countryListClickEvent(mainArray[i])
      }
    }

    function countryListClickEvent (item) {
      let countryFlagImg
      if (item.querySelector('.country-flagimg')) {
        countryFlagImg = item.querySelector('.country-flagimg').getAttribute('src')
      }
      Array.from(item.querySelectorAll('.dropdown-menu li')).forEach(function (subitem) {
        const optionFlagImg = subitem.querySelector('.options-flagimg').getAttribute('src')
        subitem.addEventListener('click', function () {
          const optionCodeNo = subitem.querySelector('.countrylist-codeno').innerHTML
          countryCode.value = optionCodeNo
          context.emit('update:contactNumber', {
            number: props.contactNumber.number,
            code: optionCodeNo
          })
          if (item.querySelector('button')) {
            item.querySelector('button img').setAttribute('src', optionFlagImg)
            if (item.querySelector('button span')) {
              item.querySelector('button span').innerHTML = optionCodeNo
            }
          }
        })
        if (countryFlagImg === optionFlagImg) {
          subitem.classList.add('active')
        }
      })
      // data option flag img with name
      Array.from(document.querySelectorAll('[data-option-flag-img-name]')).forEach(function (item) {
        const flagImg = getComputedStyle(item.querySelector('.flag-input')).backgroundImage
        const countryFlagImg = flagImg.substring(
          flagImg.indexOf('/as') + 1,
          flagImg.lastIndexOf('"')
        )
        Array.from(item.querySelectorAll('.dropdown-menu li')).forEach(function (subitem) {
          const optionFlagImg = subitem.querySelector('.options-flagimg').getAttribute('src')
          subitem.addEventListener('click', function () {
            const optionCountryName = subitem.querySelector('.country-name').innerHTML
            item.querySelector('.flag-input').style.backgroundImage = 'url(' + optionFlagImg + ')'
            item.querySelector('.flag-input').value = optionCountryName
          })
          if (countryFlagImg === optionFlagImg) {
            subitem.classList.add('active')
            item.querySelector('.flag-input').value =
              subitem.querySelector('.country-name').innerHTML
          }
        })
      })
      // data option flag img with name
      Array.from(document.querySelectorAll('[data-option-flag-name]')).forEach(function (item) {
        const countryName = item.querySelector('.flag-input').value
        Array.from(item.querySelectorAll('.dropdown-menu li')).forEach(function (subitem) {
          const optionCountryName = subitem.querySelector('.country-name').innerHTML
          subitem.addEventListener('click', function () {
            item.querySelector('.flag-input').value = optionCountryName
          })
          if (countryName === optionCountryName) {
            subitem.classList.add('active')
            item.querySelector('.flag-input').value =
              subitem.querySelector('.country-name').innerHTML
          }
        })
      })
    }

    onMounted(async () => {
      loadCountryListData(COUNTRY_LIST)
      const countryListData = COUNTRY_LIST
      Array.from(document.querySelectorAll('[data-input-flag]')).forEach(function (item) {
        const searchInput = item.querySelector('.search-countryList')
        if (searchInput) {
          searchInput.addEventListener('keyup', function () {
            const inputVal = searchInput.value.toLowerCase()
            function filterItems (arr, query) {
              return arr.filter(function (el) {
                return (
                  el.countryName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                  el.countryCode.indexOf(query) !== -1
                )
              })
            }
            const filterData = filterItems(countryListData, inputVal)
            setTimeout(function () {
              item.querySelector('.dropdown-menu-list').innerHTML = ''
              Array.from(filterData).forEach(function (listData) {
                item.querySelector('.dropdown-menu-list').innerHTML +=
                  // eslint-disable-next-line no-multi-str
                  '<li class="dropdown-item d-flex">\
                        <div class="flex-shrink-0 me-2"><img src="' +
                  listData.flagImg +
                  // eslint-disable-next-line no-multi-str
                  '" alt="country flag" class="options-flagimg" height="20"></div>\
                        <div class="flex-grow-1">\
                        <div class="d-flex"><div class="country-name me-1">' +
                  listData.countryName +
                  '</div><span class="countrylist-codeno text-muted">' +
                  listData.countryCode +
                  // eslint-disable-next-line no-multi-str
                  '</span></div>\
                        </div>\
                        </li>'
              })
              countryListClickEvent(item)
            }, 350)
          })
        }
      })
    })

    return {
      countryCode
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  outline: none;
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 24px;
  border-radius: 32px;
  border: 1px solid #d4d1d0;
  font-family: Raleway;
}
.title {
  color: #2c1d2a;
  font-size: 24px;
  font-weight: 600;
}
.addition {
  color: #2c1d2a;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}
.input {
  display: flex;
  padding: 12px 40px 12px 24px;
  height: 53px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 100px;
  border: 1px solid #d4d1d0;
  background: #fff;
  border-top-right-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
}
.input::placeholder {
  color: #888;
  font-size: 18px;
  font-weight: 400;
}
.error {
  color: #d9432b;
  font-size: 14px;
  font-weight: 400;
}
.flag-button {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.dropdown-menu {
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  position: absolute;
  z-index: 1000;
}

.custom-flag-input {
  width: 1%;
}
.custom-flag-button {
  padding: 0.375rem 0.75rem !important;
}

[data-input-flag] .dropdown-menu .dropdown-menu-list {
  max-height: 158px;
  overflow-y: auto;
}
[data-input-flag] .dropdown-menu .dropdown-menu-list::-webkit-scrollbar {
  -webkit-appearance: none;
}
.dropdown-menu-list::-webkit-scrollbar-thumb {
  background-color: #d4d1d0;
  border-radius: 8px;
}
.dropdown-menu-list::-webkit-scrollbar-track {
  border-radius: 8px;
}
@media (max-width: 768px) {
  .wrapper {
    padding: 16px;
  }
}
</style>
