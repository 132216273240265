import { ref } from 'vue'
import { defineStore } from 'pinia'

const DEFAULT_LAT = -8.496109
const DEFAULT_LNG = 115.2247536

export const useUserGeolocationStore = defineStore('userGeolocationStore', () => {
  const urlParams = new URLSearchParams(window.location.search)
  const latQuery = +urlParams.get('lat') || 0
  const lngQuery = +urlParams.get('lng') || 0
  const lat = ref(latQuery)
  const lng = ref(lngQuery)

  const initGeolocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (!(lat.value && lng.value)) {
          lat.value = position.coords.latitude
          lng.value = position.coords.longitude
        }
      }
      )
    } else {
      if (!(lat.value && lng.value)) {
        lat.value = DEFAULT_LAT
        lng.value = DEFAULT_LNG
      }
    }
  }

  return { lat, lng, initGeolocation }
})
