<template>
  <div class="date-picker">
    <label class="pickr-icon">
      <flat-pickr
        v-model="searchStore.pickUpDate"
        :config="{...config, minDate: 'today', maxDate: maxPickUpDate}"
        :placeholder="$t('t-pick-up-date')"
        class="pickr"
        @change="onPickUpDateChange"
      />
    </label>
    <label class="pickr-icon">
      <flat-pickr
        v-model="searchStore.dropOffDate"
        :config="{...config, minDate: minDropOffDate}"
        :placeholder="$t('t-dropoff-date')"
        class="pickr"
      />
    </label>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useSearchStore } from '@/stores/search'
import 'flatpickr/dist/flatpickr.css'
import { computed, watch } from 'vue'

export default {
  setup () {
    const config = {
      dateFormat: 'Y-m-d',
      enableTime: false,
      disableMobile: true
    }

    const searchStore = useSearchStore()

    const validationRules = {
      pickUpDate: { required },
      dropOffDate: { required }
    }

    const v$ = useVuelidate(validationRules, searchStore)

    const minDropOffDate = computed(() => {
      if (searchStore.pickUpDate) {
        const pickUpDate = new Date(searchStore.pickUpDate)
        pickUpDate.setDate(pickUpDate.getDate() + 1)
        return pickUpDate.toISOString().split('T')[0]
      }
      return null
    })

    const maxPickUpDate = computed(() => {
      if (searchStore.dropOffDate) {
        const dropOffDate = new Date(searchStore.dropOffDate)
        dropOffDate.setDate(dropOffDate.getDate() - 1)
        return dropOffDate.toISOString().split('T')[0]
      }
      return null
    })

    const onPickUpDateChange = (selectedDates) => {
      if (selectedDates.length > 0) {
        const newPickUpDate = selectedDates[0]
        const today = new Date().toISOString().split('T')[0]
        if (new Date(newPickUpDate) < new Date(today)) {
          searchStore.pickUpDate = null
        } else {
          searchStore.pickUpDate = newPickUpDate
        }
      }
    }

    watch(() => searchStore.pickUpDate, (newVal) => {
      const today = new Date().toISOString().split('T')[0]
      if (newVal && new Date(newVal) < new Date(today)) {
        searchStore.pickUpDate = null
      }
    })

    watch(() => searchStore.dropOffDate, (newVal) => {
      if (newVal && new Date(newVal) <= new Date(searchStore.pickUpDate)) {
        const newPickUpDate = new Date(newVal)
        newPickUpDate.setDate(newPickUpDate.getDate() - 1)
        searchStore.pickUpDate = newPickUpDate.toISOString().split('T')[0]
      }
    })

    return {
      config,
      searchStore,
      v$,
      minDropOffDate,
      maxPickUpDate,
      onPickUpDateChange
    }
  },
  components: {
    flatPickr
  }
}
</script>

<style scoped lang="scss">
.date-picker {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @media (max-width: 767px) {
      grid-template-columns: 1fr;
  }
}

.pickr {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  min-height: 53px;
  padding: 12px 24px 12px 24px;
  border: 1px solid #d4d1d0;
  border-radius: 100px;
  overflow-x: scroll;

  &:hover,
  &:focus {
      border-color: #fc9544;
      outline: none;
      caret-color: #fc9544;
  }
}

.is-invalid {
  border: 1px solid #D9432B;
}

.pickr-icon {
  position: relative;
  cursor: pointer;

  & .pickr {
      padding-right: 50px;
  }

  &::after {
      content: '';
      position: absolute;
      top: 17px;
      right: 25px;
      width: 16px;
      height: 16px;
      background-image: url('@/assets/images/icons/calendar.svg');
      background-repeat: no-repeat;
  }
}
</style>
