<template>
  <section class="rentals">
    <p class="title">{{ $t('t-verified-rentals') }}</p>
    <div id="map" class="map" ref="mapContainer"></div>
    <!-- <div class="logos">
      <img class="logo" src="@/assets/images/map/logo-1.svg" alt="logo" />
      <img class="logo" src="@/assets/images/map/logo-2.svg" alt="logo" />
      <img class="logo" src="@/assets/images/map/logo-3.svg" alt="logo" />
      <img class="logo" src="@/assets/images/map/logo-4.svg" alt="logo" />
    </div> -->
    <a class="button" href="#search-form">{{ $t('t-rent-a-bike') }}</a>
  </section>
</template>

<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import fetchWrapper from '@/utils/fetchWrapper'
import { useUserGeolocationStore } from '@/stores/user-geolocation'
import { useSearchStore } from '@/stores/search'
import { toRefs } from 'vue'

export default {
  setup (props, { attrs }) {
    const userGeolocationStore = useUserGeolocationStore()
    const searchStore = useSearchStore()
    const { geocoder } = toRefs(attrs)

    return {
      userGeolocationStore,
      searchStore,
      geocoder
    }
  },
  data () {
    return {
      map: null,
      userMarker: null,
      serviceAreas: []
    }
  },
  methods: {
    setPickUpPlace (location) {
      if (this.userGeolocationStore.lat && this.userGeolocationStore.lng) {
        this.geocoder.geocode({ location }, (results, status) => {
          if (status === 'OK' && results[0]) {
            this.searchStore.pickUpPlace = results[0].formatted_address
            this.searchStore.isPlaceSelected = true
          } else {
            console.error('Geocoder failed due to: ' + status)
          }
        })
      }
    },
    onMapClick (e) {
      const location = e.latlng
      this.userGeolocationStore.lat = location.lat
      this.userGeolocationStore.lng = location.lng
      this.setPickUpPlace(location)
    },
    initMapLeaflet () {
      if (this.$refs.mapContainer) {
        this.map = L.map(this.$refs.mapContainer, {
          zoomControl: false,
          center: [this.userGeolocationStore.lat, this.userGeolocationStore.lng],
          zoom: 9,
          zoomAnimation: false
        })

        this.map.addEventListener('click', this.onMapClick)

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map)
        this.addUserMarker()
      }
    },
    addServiceAreas () {
      const serviceAreasGeoJSON = this.serviceAreas.map((area) => ({
        type: 'Feature',
        properties: {
          id: area.id,
          name: area.name
        },
        geometry: area.geom
      }))

      const geoJsonLayer = L.geoJSON(serviceAreasGeoJSON, {
        style: {
          color: '#FC9544',
          weight: 3,
          dashArray: '3, 5',
          fillOpacity: 0
        },
        onEachFeature: (feature, layer) => {
          if (feature.properties?.name) {
            layer.bindPopup(feature.properties.name)
          }
        }
      }).addTo(this.map)

      geoJsonLayer.addEventListener('click', this.onMapClick)
    },
    addUserMarker () {
      const market = L.icon({
        iconUrl: icon,
        iconRetinaUrl,
        iconSize: [25, 41],
        iconAnchor: [12, 41]
      })
      this.userMarker = L.marker([this.userGeolocationStore.lat, this.userGeolocationStore.lng], {
        icon: market,
        draggable: true
      }).addTo(this.map)

      this.userMarker.addEventListener('dragend', (e) => {
        const location = e.target.getLatLng()
        this.userGeolocationStore.lat = location.lat
        this.userGeolocationStore.lng = location.lng
        this.setPickUpPlace(location)
      })
    },
    async fetchServiceAreas () {
      try {
        const response = await fetchWrapper(`${API_BASE_URL}v1/service_area/all`)
        if (!response.ok) {
          throw new Error(`Ошибка HTTP: статус ${response.status}`)
        }
        const data = await response.json()
        if (data.content) {
          this.serviceAreas = data.content
        } else {
          console.error('Ответ от сервера не содержит ожидаемого свойства content:', data)
        }
      } catch (error) {
        console.error('Error fetching service areas:', error)
      }
    }
  },
  watch: {
    userGeolocationStore: {
      handler () {
        this.map.setView([this.userGeolocationStore.lat, this.userGeolocationStore.lng])
        this.userMarker.setLatLng([this.userGeolocationStore.lat, this.userGeolocationStore.lng])

        this.setPickUpPlace({
          lat: this.userGeolocationStore.lat,
          lng: this.userGeolocationStore.lng
        })
      },
      deep: true
    },
    serviceAreas () {
      this.addServiceAreas()
    }
  },
  mounted () {
    this.fetchServiceAreas()
    this.initMapLeaflet()

    this.setPickUpPlace({
      lat: this.userGeolocationStore.lat,
      lng: this.userGeolocationStore.lng
    })
  }
}
</script>

<style scoped lang="scss">
.rentals {
  display: flex;
  flex-direction: column;
}
.title {
  color: #2c1d2a;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 140%;
  margin: 0;
}
.map {
  width: 100%;
  height: 522px;
  margin-top: 24px;

  @media (max-width: $mobile-max-width) {
    height: 308px;
    margin-top: 32px;
  }
}
.logos {
  display: flex;
  width: 100%;
  margin-top: 25px;
  gap: 40px;
  flex-wrap: wrap;
}
.logo {
  padding: 20px;
  width: calc(25% - 30px);
  box-sizing: border-box;
}
.button {
  border-radius: 100px;
  background-color: #fc9544;
  padding: 16px 40px;
  color: #fff;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  margin: 40px auto 0 auto;
  border: none;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button:hover {
  background-color: #861920;
}
.leaflet-container {
  z-index: 1;
}

@media (max-width: 768px) {
  .logos {
    gap: 20px;
    margin-top: 20px;
  }
  .logo {
    width: calc(50% - 10px);
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
  }
  .button {
    margin-top: 30px;
  }
}
</style>
