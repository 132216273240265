<template>
  <div class="wrapper">
    <spinner-view :isLoading="isLoading" />
    <SearchForm />
    <AdvantagesBlock />
    <FeaturesBlock />
    <AdvicesBlock />
    <MapBlock v-if="!isLoading" :geocoder="geocoder" />
    <FaqBlock />
  </div>
</template>

<script>
import FeaturesBlock from './components/Features.vue'
import SearchForm from './components/SearchForm.vue'
import AdvantagesBlock from './components/Advantages.vue'
import AdvicesBlock from './components/Advices.vue'
import MapBlock from './components/Map.vue'
import FaqBlock from './components/Faq.vue'
import SpinnerView from '@/components/SpinnerView.vue'
import { ref, onMounted } from 'vue'
import { useUserGeolocationStore } from '@/stores/user-geolocation'
import { getGeocoder } from '@/utils/googleMaps'

export default {
  components: {
    FeaturesBlock,
    SearchForm,
    AdvantagesBlock,
    AdvicesBlock,
    MapBlock,
    FaqBlock,
    SpinnerView
  },

  setup () {
    const userGeolocationStore = useUserGeolocationStore()

    const isLoading = ref(true)
    const geocoder = ref(null)

    onMounted(async () => {
      userGeolocationStore.initGeolocation()
      Promise.all([
        new Promise((resolve) => {
          const geocoder = getGeocoder()
          resolve(geocoder)
        }).then((resolve) => {
          geocoder.value = resolve
        })
      ]).finally(() => {
        isLoading.value = false
      })
    })

    return {
      isLoading,
      geocoder
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 120px;
}
@media (max-width: 768px) {
  .wrapper {
    gap: 60px;
  }
}
.leaflet-container {
  height: calc(100vh - 200px);
  width: 100%;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.image {
  width: 100%;
  max-height: 800px;
  object-fit: cover;
  margin-bottom: 20px;
}

.content {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);
}

.title {
  color: #06d286;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  color: #0f0000;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.form {
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  color: #182430;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 8px;
}

.form-group select {
  appearance: none;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 16px;
  padding-right: 30px;
}

.book-button {
  background-color: #06d286;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.book-button:hover {
  background-color: #05a276;
}

.border-gray-300 {
  border-color: #d9432b;
}

.focus\:ring-blue-500 {
  box-shadow: 0 0 0 3px #fc9544;
}

.focus\:border-blue-500 {
  border-color: #fc9544;
}

h3 {
  font-family: 'Galana Grotesque', sans-serif;
  color: #333;
}

.b-button {
  background-color: #fc9544;
  color: #fff;
}

.b-button:hover {
  background-color: #ff6f00;
  color: #fff;
}

.map-container {
  height: 400px;
  margin-top: 40px;
}
</style>
