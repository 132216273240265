<template>
  <div v-if="slidesToShow" class="gallery">
    <div class="current-picture">
      <img
        :src="props.pictures[state.currentPicture].replace('https', 'http')"
        alt="selected picture"
      />
    </div>
    <div class="carousel-container">
      <a-carousel
        ref="carousel"
        arrows
        :focusOnSelect="true"
        :dots="false"
        :slidesToShow="slidesToShow"
        :beforeChange="
          (from, to) => {
            state.currentPicture = to
          }
        "
      >
        <template #prevArrow>
          <button>
            <div class="arrow-wrapper">
              <svg
                class="icon-prev"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.70062 8.64662C4.43313 8.90916 4.43313 9.33483 4.70062 9.59738L11.4813 16.2527C11.6235 16.3923 11.8127 16.4577 11.999 16.4488C12.1859 16.4582 12.3759 16.3929 12.5187 16.2528L19.2994 9.59748C19.5669 9.33493 19.5669 8.90926 19.2994 8.64672C19.0319 8.38417 18.5982 8.38417 18.3307 8.64672L12.0001 14.8603L5.66929 8.64662C5.4018 8.38407 4.96811 8.38407 4.70062 8.64662Z"
                  fill="#0E0E0E"
                />
              </svg>
            </div>
          </button>
        </template>
        <template #nextArrow>
          <button>
            <div class="arrow-wrapper">
              <svg
                class="icon-next"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.70062 8.64662C4.43313 8.90916 4.43313 9.33483 4.70062 9.59738L11.4813 16.2527C11.6235 16.3923 11.8127 16.4577 11.999 16.4488C12.1859 16.4582 12.3759 16.3929 12.5187 16.2528L19.2994 9.59748C19.5669 9.33493 19.5669 8.90926 19.2994 8.64672C19.0319 8.38417 18.5982 8.38417 18.3307 8.64672L12.0001 14.8603L5.66929 8.64662C5.4018 8.38407 4.96811 8.38407 4.70062 8.64662Z"
                  fill="#0E0E0E"
                />
              </svg>
            </div>
          </button>
        </template>
        <img
          @click="onPictureClick"
          v-for="(picture, index) in props.pictures"
          :key="index"
          :src="picture.replace('https', 'http')"
          alt="picture"
          :data-index="index"
          data-name="caroucelPicture"
        />
      </a-carousel>
    </div>
  </div>
</template>

<script>
import { Carousel } from 'ant-design-vue'
import { computed, reactive, ref } from 'vue'

const DEFAULT_SLIDES_COUNT = 3

export default {
  props: ['pictures'],
  setup (props) {
    const state = reactive({
      currentPicture: 0
    })

    const carousel = ref(null)

    const slidesToShow = computed(() => {
      let slides = DEFAULT_SLIDES_COUNT
      if (props.pictures.length < DEFAULT_SLIDES_COUNT) {
        slides = props.pictures.length
      }

      return slides
    })

    const onPictureClick = (e) => {
      console.log(e.target)
      if (e.target.dataset.name === 'caroucelPicture') {
        state.currentPicture = e.target.dataset.index
        carousel.value.goTo(state.currentPicture)
      }
    }

    return {
      props,
      state,
      carousel,
      slidesToShow,
      onPictureClick
    }
  },
  components: {
    ACarousel: Carousel
  }
}
</script>

<style lang="scss">
.gallery {
  position: relative;
}

.current-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vw / 2.381);

  @media (max-width: $mobile-max-width) {
    height: calc(100vw / 1.147);
  }

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}

.carousel-container {
  position: relative;
  margin-top: 24px;
  height: calc(100vw / 14);

  @media (max-width: $mobile-max-width) {
    height: calc(100vw / 5);
  }
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: auto !important;
}

.ant-carousel {
  position: absolute;
  inset: 0;

  .slick-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    margin-top: 0;
    width: 24px;
  }

  .slick-prev {
    left: 3.28vw;

    @media (max-width: $mobile-max-width) {
      left: 0;
    }
  }

  .slick-next {
    right: 3.28vw;

    @media (max-width: $mobile-max-width) {
      right: 0;
    }
  }

  .arrow-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    inset: 10px 0;
  }

  .icon-prev {
    transform: rotate(90deg);
  }

  .icon-next {
    transform: rotate(-90deg);
  }

  .slick-list {
    max-width: 70%;
    overflow: hidden;
    margin: 0 auto;

    @media (max-width: $mobile-max-width) {
      max-width: 80%;
    }
  }

  .slick-slider {
    position: relative;
    pointer-events: auto;
  }

  .slick-slide {
    padding: 0 12px;
    position: relative;
    height: calc(100vw / 14);

    @media (max-width: $mobile-max-width) {
      width: calc(100vw / 5);
      height: calc(100vw / 5);
    }

    img {
      object-fit: contain;
      width: calc(100vw / 14);
      height: calc(100vw / 14);
      cursor: pointer;

      @media (max-width: $mobile-max-width) {
        width: calc(100vw / 5);
        height: calc(100vw / 5);
      }
    }
  }
}
</style>
