<template>
  <div class="manage-my-booking-page">
    <a-menu v-model:selectedKeys="current" mode="horizontal">
      <a-menu-item key="main">{{ $t('t-main-information') }}</a-menu-item>
      <a-menu-item v-if="!this.isOnlinePayment" key="chat">
        <div
          style="display: flex; justify-content: space-between; align-items: center; width: 100%"
        >
          <span>{{ $t('t-chat') }}</span>
          <unread-messages-count
            v-if="unreadMessagesCount > 0"
            :unreadCount="unreadMessagesCount"
          />
        </div>
      </a-menu-item>
      <a-menu-item v-if="this.isFinishedStatus" key="review">{{
        $t('t-review')
      }}</a-menu-item>
    </a-menu>
    <manage-my-booking-main-info
      @toggleIsFinishedStatus="toggleIsFinishedStatus"
      @toggleToOnlinePayment="toggleToOnlinePayment"
      v-if="current[0] === 'main'"
    />
    <chat-tab v-else-if="current[0] === 'chat'"/>
    <review-tab v-else-if="current[0] === 'review'"/>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import { Menu, MenuItem } from 'ant-design-vue'
import ManageMyBookingMainInfo from './ManageMyBookingMainInfo.vue'
import ChatTab from './ChatTab.vue'
import ReviewTab from './ReviewTab.vue'
import { useRouter } from 'vue-router'
import fetchWrapper from '@/utils/fetchWrapper'
import UnreadMessagesCount from './components/UnreadMessageCount.vue'

export default {
  components: {
    AMenu: Menu,
    AMenuItem: MenuItem,
    ManageMyBookingMainInfo,
    ChatTab,
    ReviewTab,
    UnreadMessagesCount
  },
  setup () {
    const router = useRouter()
    const bookingReferenceNumber = window.location.pathname.split('/')[2]
    const unreadMessagesCount = ref(0)
    const isOnlinePayment = ref(false)
    const isFinishedStatus = ref(false)

    const current = ref([
      window.location.pathname.endsWith('chat')
        ? 'chat'
        : window.location.pathname.endsWith('review')
          ? 'review'
          : 'main'
    ])

    const items = ref([
      {
        key: 'main',
        label: 'Main Information',
        title: 'Main Information'
      },
      {
        key: 'chat',
        label: 'Chat',
        title: 'Chat'
      },
      {
        key: 'review',
        label: 'Review',
        title: 'Review'
      }
    ])

    const toggleToOnlinePayment = () => {
      isOnlinePayment.value = true
    }

    const toggleIsFinishedStatus = () => {
      isFinishedStatus.value = true
    }

    const REQUEST_DELAY = 2000

    const fetchUnreadMessagesCount = async () => {
      setTimeout(async () => {
        try {
          const response = await fetchWrapper(
          `${API_BASE_URL}v1/chats/unread_messages/${bookingReferenceNumber}/role/customer`
          )
          const data = await response.json()
          if (data.unread_count !== undefined && data.unread_count !== 0) {
            unreadMessagesCount.value = data.unread_count
          }
        } catch (error) {
          console.error('Failed to fetch unread messages count:', error)
        }
      }, REQUEST_DELAY)
    }

    onMounted(() => {
      fetchUnreadMessagesCount()
      setInterval(fetchUnreadMessagesCount, 30000)
    })

    watch(current, async (newVal) => {
      const endsWithChat = window.location.pathname.endsWith('chat')
      const endsWithReview = window.location.pathname.endsWith('review')

      if (newVal[0] === 'chat' && !endsWithChat) {
        router.push(`/manage-my-booking/${bookingReferenceNumber}/chat`)
        unreadMessagesCount.value = 0

        await fetchUnreadMessagesCount()
      } else if (newVal[0] === 'review' && !endsWithReview) {
        router.push(`/manage-my-booking/${bookingReferenceNumber}/review`)
      } else if (newVal[0] === 'main' && (endsWithChat || endsWithReview)) {
        router.push(`/manage-my-booking/${bookingReferenceNumber}`)
      }
    })

    return {
      current,
      items,
      unreadMessagesCount,
      toggleToOnlinePayment,
      isOnlinePayment,
      toggleIsFinishedStatus,
      isFinishedStatus
    }
  }
}
</script>

<style>
.manage-my-booking-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.manage-my-booking-page .ant-menu-item-selected::after,
.manage-my-booking-page .ant-menu-item:hover::after {
  border-bottom-color: #fc9544 !important;
}
.manage-my-booking-page .ant-menu-item-selected {
  color: #fc9544 !important;
}
.manage-my-booking-page .ant-menu-title-content {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
}
</style>
