<template>
  <div class="company-information-wrapper">
    <h2 class="title">{{ $t('t-company-information') }}</h2>
    <div class="flex">
      <CompanyProfile
        :logo="this.logo"
        :companyName="this.companyName"
        :companyRating="this.companyRating"
      />
      <ImportantInfoButton />
    </div>
  </div>
</template>

<script>
import CompanyProfile from '@/components/CompanyProfile.vue'
import ImportantInfoButton from '@/components/ImportantInfo/ImportantInfoButton.vue'

export default {
  props: ['logo', 'companyName', 'companyRating'],
  components: {
    CompanyProfile,
    ImportantInfoButton
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.company-information-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 24px;
  border-radius: 32px;
  border: 1px solid #d4d1d0;
  font-family: Raleway;
}
.title {
  color: #2c1d2a;
  font-size: 24px;
  font-weight: 600;
}
.flex {
  display: flex;
  justify-content: space-between;
}
</style>
