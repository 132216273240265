<template>
  <div class="wrapper">
    <h2 class="title">{{ $t('t-payment-method') }}</h2>
    <div class="grid-wrapper">
      <div
        @click="$emit('selectMethod', 'cash')"
        class="method"
        :class="{ selected: this.paymentMethod === 'cash' }"
      >
        <img src="@/assets/images/checkout/cash.png" alt="Cash" />
        <div>{{ $t('t-cash-payment') }}</div>
      </div>
      <div
        @click="$emit('selectMethod', 'card')"
        class="method"
        :class="{ selected: this.paymentMethod === 'card' }"
      >
        <img src="@/assets/images/checkout/card.png" alt="Card" />
        <div>{{ $t('t-online-payment') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['paymentMethod'],
  emits: ['selectMethod']
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 24px;
  border-radius: 32px;
  border: 1px solid #d4d1d0;
  font-family: Raleway;
}
.title {
  color: #2c1d2a;
  font-size: 24px;
  font-weight: 600;
}
.grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
.method {
  border-radius: 32px;
  background: #fff5ed;
  display: flex;
  gap: 8px;
  height: 120px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.method img {
  width: 72px;
  height: 72px;
}
.selected {
  border: 1px solid #fc9544;
}
@media (max-width: 768px) {
  .wrapper {
    padding: 16px;
  }
  .method {
    flex-direction: column;
  }
}
</style>
