<template>
  <div>
    <spinner-view v-if="isLoading" :isLoading="isLoading" />
    <div v-else class="chat-wrapper" ref="chatWrapper">
      <div class="messages-wrapper" ref="messagesWrapper">
        <div
          v-for="message in chatMessagesData"
          :key="message.id"
          :class="{
            'message-customer': message.sender.toLowerCase() === 'customer',
            'message-rental': message.sender.toLowerCase() === 'rental',
            'message-system': message.sender.toLowerCase() === 'system'
          }"
        >
          <div class="message-content">
            <img
              v-if="message.sender.toLowerCase() === 'customer'"
              class="user-avatar-image"
              src="@/assets/images/icons/avatar.jpg"
              alt="avatar"
            />
            <p>{{ message.sender.toLowerCase() === 'system' ? 'System message: ' : '' }}{{ message.text }}</p>
            <div class="message-timestamp">{{ new Date(message.timestamp).toLocaleString() }}</div>
          </div>
        </div>
        <div class="chat-bottom-anchor" ref="chatBottomAnchor"></div>
      </div>
    </div>
    <div class="chat-input">
      <textarea
        class="message-input"
        v-model="formMessage"
        placeholder="Type your message..."
        @keyup.enter="sendMessage"
      ></textarea>
      <div @click="sendMessage" class="send-message-block">
        <img src="@/assets/images/icons/send.png" alt="send" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref as firebaseRef, onValue, push, off, update, query, orderByChild, equalTo, get, database } from '@/firebase/init'
import { ref as vueRef, onBeforeUnmount, onMounted, nextTick, watch } from 'vue'
import { useRouter } from 'vue-router'
import fetchWrapper from '@/utils/fetchWrapper'
import SpinnerView from '@/components/SpinnerView'

export default {
  components: {
    SpinnerView
  },
  setup () {
    const isLoading = vueRef(false)
    const chatMessagesData = vueRef([])
    const formMessage = vueRef('')
    const bookingReferenceNumber = window.location.pathname.split('/')[2]
    const currentUserRole = 'customer'
    const oppositeRole = 'rental'
    const selectedCurrencyId = localStorage.getItem('selectedCurrencyId')
    const router = useRouter()
    const chatBottomAnchor = vueRef(null)
    const messagesWrapper = vueRef(null)
    const hasMessagesLoaded = vueRef(false)

    const fetchMessages = async () => {
      isLoading.value = true
      try {
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/booking/${bookingReferenceNumber}?currency_id=${selectedCurrencyId}`
        )
        if (!response.ok) {
          router.push('/not-found')
          return
        }
      } catch (error) {
        console.error('Error fetching booking details:', error)
        router.push('/not-found')
        return
      }

      const messagesRef = firebaseRef(database, `bookingMessages/${bookingReferenceNumber}`)
      onValue(messagesRef, snapshot => {
        const messages = []
        snapshot.forEach(snap => {
          messages.push({
            id: snap.key,
            ...snap.val()
          })
        })
        chatMessagesData.value = messages
        hasMessagesLoaded.value = true
        nextTick(() => {
          scrollToLastMessage()
          markMessagesAsRead(messages)
          isLoading.value = false
        })
      }, error => {
        console.error('Failed to fetch messages:', error)
        isLoading.value = false
      })
    }

    const sendMessage = () => {
      if (!formMessage.value.trim()) return
      const messagesRef = firebaseRef(database, `bookingMessages/${bookingReferenceNumber}`)
      push(messagesRef, {
        sender: currentUserRole,
        text: formMessage.value,
        timestamp: Date.now(),
        read: false // Messages are unread by default
      })
      formMessage.value = ''
      nextTick(() => {
        scrollToLastMessage()
      })
    }

    const markMessagesAsRead = async () => {
      const messagesRef = firebaseRef(database, `bookingMessages/${bookingReferenceNumber}`)
      const q = query(messagesRef, orderByChild('sender'), equalTo(oppositeRole))

      const snapshot = await get(q)

      const updates = {}
      snapshot.forEach(childSnapshot => {
        if (!childSnapshot.val().read) { // Check if the message is not already marked as read
          updates[`${childSnapshot.key}/read`] = true
        }
      })

      if (Object.keys(updates).length > 0) {
        update(messagesRef, updates) // Perform the update
          .then(() => console.log('Messages marked as read'))
          .catch(error => console.error('Error updating message read status:', error))
      }
    }

    const scrollToLastMessage = () => {
      nextTick(() => {
        if (chatBottomAnchor.value) {
          chatBottomAnchor.value.scrollIntoView({ block: 'end', behavior: 'smooth' })
        }
      })
    }

    onMounted(fetchMessages)

    onBeforeUnmount(() => {
      const messagesRef = firebaseRef(database, `bookingMessages/${bookingReferenceNumber}`)
      off(messagesRef)
    })

    watch(chatMessagesData, (newValue, oldValue) => {
      if (newValue.length !== oldValue.length) {
        nextTick(() => {
          scrollToLastMessage()
        })
      }
    })

    return {
      chatMessagesData,
      formMessage,
      sendMessage,
      isLoading,
      chatBottomAnchor,
      messagesWrapper,
      hasMessagesLoaded
    }
  }
}
</script>

<style scoped>
  .chat-wrapper {
    padding: 24px 5px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    background: url('@/assets/images/chat-bg.png');
    background-color: #f2f2f2;
    height: 60vh;
    overflow: hidden scroll;
    width: 100%;
    margin: 0 auto;
  }

  .chat-wrapper::-webkit-scrollbar {
    width: 5px;
  }

  .chat-wrapper::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }

  .message-system p {
    color: #f06548;
    margin: 0;
    text-align: center;
    padding: 12px 20px;
    background-color: #f3f6f9;
    border-radius: 3px;
    box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  }

  .message-system {
    align-self: center;
    margin: 0 55px;
  }

  .message-system .message-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .message-rental .message-content,
  .message-customer .message-content {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .message-rental .message-content {
    flex-direction: row-reverse;
  }

  .message-rental .message-content p,
  .message-customer .message-content p {
    margin: 0;
    border-radius: 3px;
    padding: 12px 20px;
  }

  .message-rental .message-content p {
    background-color: rgba(10, 179, 156, 0.15);
    color: #0ab39c;
  }

  .message-customer .message-content p {
    background-color: #f3f6f9;
    box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  }

  .messages-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 19px;
    gap: 25px;
  }

  .message-rental {
    align-self: flex-end;
  }

  .message-timestamp {
    color: rgb(135, 138, 153);
    font-size: 0.875em;
    font-weight: 500;
  }

  .chat-input {
    width: 100%;
    display: grid;
    grid-template-columns: auto 38px;
    gap: 10px;
    padding: 1.5rem;
    background-color: #fff;
  }

  .send-message-block {
    width: 38px;
    height: 38px;
    cursor: pointer;
    background-color: #0ab39c;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .send-message-block:hover {
    background-color: #099885;
  }

  .message-input {
    height: 38px;
    border: none;
    outline: none;
    padding: 0.5rem 0.9rem;
    border-radius: 0.25rem;
    background-color: #f2f2f2;
    overflow-y: hidden;
    resize: none;
  }

  .user-avatar-image {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  @media (max-width: 576px) {
    .message-system {
      margin: 0 10px;
    }
    .messages-wrapper {
      padding: 0;
    }
    .message-customer .message-content {
      gap: 3px;
      padding: 6px 10px;
    }
  }
</style>
