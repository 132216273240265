<template>
  <div>
    <spinner-view v-if="this.isLoading" :isLoading="this.isLoading" />
    <div v-else class="wrapper">
      <Steps :currentStep="3" @back="backToPreviousStep" />
      <div class="grid-wrapper">
        <DriverDetails
          class="grid-item"
          :fullName="this.fullName"
          @update:fullName="(newValue) => (this.fullName = newValue)"
          :emailAddress="this.emailAddress"
          @update:emailAddress="(newValue) => (this.emailAddress = newValue)"
          :contactNumber="this.contactNumber"
          @update:contactNumber="(newValue) => (this.contactNumber = newValue)"
        />
        <PaymentMethod
          class="grid-item"
          :paymentMethod="this.paymentMethod"
          @selectMethod="this.selectPaymentMethod"
        />
        <PriceBreakdown
          class="grid-item"
          :pickUpDate="this.pickUpDate"
          :dropOffDate="this.dropOffDate"
          :totalCost="this.totalCost"
          :buttonTitle="$t('t-submit')"
          :showCheckboxes="true"
          :additionalServices="this.additionalServices"
          :selectedServices="this.selectedServices"
          @submit="this.submit"
          @updateMarketingEmails="handleMarketingEmailsUpdate"
          :allFieldsFilled="allFieldsFilled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PriceBreakdown from '@/components/PriceBreakdown.vue'
import DriverDetails from './components/DriverDetails.vue'
import PaymentMethod from './components/PaymentMethod.vue'
import SpinnerView from '@/components/SpinnerView.vue'
import Steps from '@/components/Steps.vue'

import fetchWrapper from '@/utils/fetchWrapper'
import performRedirect from '@/utils/redirectLogic'
import { useRouter } from 'vue-router'
import { useCurrencyStore } from '@/stores/currency'
import { storeToRefs } from 'pinia'
import { computed, watch, onMounted, ref } from 'vue'

export default {
  components: {
    DriverDetails,
    PaymentMethod,
    PriceBreakdown,
    SpinnerView,
    Steps
  },
  setup () {
    const currencyStore = useCurrencyStore()
    const { currencyCode, id: currencyId } = storeToRefs(currencyStore)
    const urlParams = new URLSearchParams(window.location.search)
    const bookingReferenceNumber = urlParams.get('bookingReferenceNumber')
    const router = useRouter()

    const pickUpPlace = ref(null)
    const pickUpDate = ref(null)
    const dropOffDate = ref(null)
    const totalCost = ref(null)
    const bookingId = ref(null)
    const selectedServices = ref(null)
    const scooter = ref(null)
    const isLoading = ref(false)
    const fullName = ref('')
    const emailAddress = ref('')
    const contactNumber = ref({ number: '', code: '' })
    const paymentMethod = ref('cash')
    const xenditId = ref(null)
    const marketingEmails = ref(false)

    const handleMarketingEmailsUpdate = (newValue) => {
      marketingEmails.value = newValue
    }

    const additionalServices = ref(null)
    const allFieldsFilled = computed(() => {
      return (
        fullName.value.trim() !== '' &&
        emailAddress.value.trim() !== '' &&
        contactNumber.value.number.length > 6
      )
    })

    const fetchAdditionalServices = async () => {
      isLoading.value = true
      try {
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/additional_service/all?is_available=true&currency_id=${currencyId.value}`
        )
        const data = await response.json()
        additionalServices.value = data.content.map((service) => ({
          ...service,
          count: service.max_count ? 0 : service.count
        }))
      } catch (error) {
        console.error('Error when fetching additional services:', error)
      }
    }

    const fetchData = async () => {
      try {
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/booking/${bookingReferenceNumber}?currency_id=${currencyId.value}`
        )
        const data = await response.json()
        const status = data.status
        pickUpPlace.value = data.pick_up_place
        pickUpDate.value = data.pickup_date
        dropOffDate.value = data.dropoff_date
        totalCost.value = data.total_cost
        bookingId.value = data.id
        selectedServices.value = data.additional_services
        additionalServices.value.forEach((service) => {
          const selectedService = selectedServices.value.find(
            (selected) => selected.additional_service_id === service.id
          )
          if (selectedService) {
            if (service.max_count) {
              service.count = selectedService.count
            } else {
              service.selected = selectedService.count === 1
            }
          }
        })
        performRedirect(router, status, bookingReferenceNumber)
        const scooterResponse = await fetchWrapper(`${API_BASE_URL}v1/scooter/${data.scooter_id}`)
        const scooterData = await scooterResponse.json()
        scooter.value = {
          photo_urls: scooterData.photo_urls,
          brand_name: scooterData.brand_name,
          model_name: scooterData.model_name,
          dailyRate: scooterData.price_per_day,
          company_id: scooterData.company_id,
          currency: currencyCode.value
        }
        const companyResponse = await fetchWrapper(
          `${API_BASE_URL}v1/company/${scooterData.company_id}`
        )
        const companyData = await companyResponse.json()
        xenditId.value = companyData.xendit_id
      } catch (error) {
        console.error('Error when fetching data:', error)
      } finally {
        isLoading.value = false
      }
    }

    const submit = async () => {
      try {
        isLoading.value = true
        const payload =
          paymentMethod.value === 'card'
            ? {
                full_name: fullName.value,
                email: emailAddress.value,
                mobile_number: contactNumber.value.code + contactNumber.value.number,
                booking_reference_number: bookingReferenceNumber,
                amount: totalCost.value,
                currency: currencyCode.value,
                xendit_id: xenditId.value
              }
            : {
                full_name: fullName.value,
                email: emailAddress.value,
                phone: contactNumber.value.code + contactNumber.value.number,
                booking_reference_number: bookingReferenceNumber,
                currency: currencyCode.value,
                status: 'WAITING_APPROVE',
                marketing_emails: marketingEmails.value,
                payment_info: { method: 'cash' }
              }

        const url =
          paymentMethod.value === 'card'
            ? `${API_BASE_URL}v1/xendit/create_payment_link`
            : `${API_BASE_URL}v1/booking/update/${bookingReferenceNumber}?currency_id=${currencyId.value}`

        const response = await fetchWrapper(url, {
          method: paymentMethod.value === 'card' ? 'POST' : 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        })

        const data = await response.json()
        fetchData()

        data.success
          ? router.push(
              `/manage-my-booking?bookingReferenceNumber=${bookingReferenceNumber}&currency_id=${currencyId.value}`
          )
          : console.error('Error when updating booking:', data.message)
      } catch (error) {
        console.error('Error when updating booking:', error)
        isLoading.value = false
      }
    }

    const selectPaymentMethod = (method) => {
      paymentMethod.value = method
    }

    const backToPreviousStep = async (step) => {
      if (step === 2) {
        try {
          const response = await fetchWrapper(
            `${API_BASE_URL}v1/booking/update/${bookingReferenceNumber}`,
            {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ status: 'OPEN', currency: currencyCode.value })
            }
          )
          if (response.status === 200) {
            performRedirect(router, 'OPEN', bookingReferenceNumber)
          }
        } catch (error) {
          console.error('Error updating booking status:', error)
        }
      }
    }

    watch(currencyCode, async () => {
      await fetchAdditionalServices()
      isLoading.value = false
    })

    onMounted(async () => {
      isLoading.value = true
      await fetchAdditionalServices()
      await fetchData()
    })

    return {
      currencyCode,
      currencyId,
      pickUpPlace,
      pickUpDate,
      dropOffDate,
      totalCost,
      bookingId,
      selectedServices,
      scooter,
      isLoading,
      submit,
      fullName,
      emailAddress,
      contactNumber,
      paymentMethod,
      additionalServices,
      selectPaymentMethod,
      allFieldsFilled,
      handleMarketingEmailsUpdate,
      marketingEmails,
      backToPreviousStep
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
.grid-item {
  grid-column: 1;
}
.grid-item:nth-child(3) {
  grid-column: 2;
  grid-row: 1;
  height: fit-content;
}
@media (max-width: 768px) {
  .grid-wrapper {
    display: flex;
    flex-direction: column;
  }
}
</style>
