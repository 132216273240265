<template>
    <div class="faq-page">
      <FaqBlock />
    </div>
  </template>

<script>
import FaqBlock from '../pages/HomePage/components/Faq.vue'

export default {
  components: {
    FaqBlock
  }
}
</script>
