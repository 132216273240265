<template>
  <div>
    <spinner-view v-if="this.isLoading" :isLoading="this.isLoading" />
    <div v-else class="wrapper">
      <Steps :currentStep="2" />
      <div class="grid-wrapper">
        <BookingInformation
          v-if="this.scooter"
          class="grid-item"
          :brand="this.scooter.brand_name"
          :model="this.scooter.model_name"
          :photo="this.scooter.photo_urls[0]"
          :pickUpPlace="this.pickUpPlace"
          :pickUpDate="this.pickUpDate"
          :dropOffDate="this.dropOffDate"
          :lat="this.lat"
          :lng="this.lng"
          :is_custom="this.scooter.is_custom"
        />
        <CompanyInformation
          v-if="this.company"
          :logo="this.company.logo"
          :companyName="this.company.name"
          :companyRating="this.companyRating.average_rating"
          class="grid-item"
        />
        <AdditionalServices
          v-if="this.additionalServices"
          class="grid-item"
          :services="this.additionalServices"
          @set-count="this.setCount"
          @toggle-checkbox="this.toggleCheckbox"
        />
        <PriceBreakdown
          class="grid-item"
          :totalCost="this.totalCost"
          :dropOffDate="this.dropOffDate"
          :pickUpDate="this.pickUpDate"
          :additionalServices="this.additionalServices"
          :selectedServices="this.selectedServices"
          :buttonTitle="$t('t-checkout')"
          @submit="this.checkout"
        />
      </div>
    </div>
  </div>
</template>

<script>
import fetchWrapper from '@/utils/fetchWrapper'
import SpinnerView from '@/components/SpinnerView.vue'
import Steps from '@/components/Steps.vue'
import BookingInformation from '@/components/BookingInformation.vue'
import CompanyInformation from './components/CompanyInformation.vue'
import AdditionalServices from './components/AdditionalServices.vue'
import PriceBreakdown from '@/components/PriceBreakdown.vue'
import performRedirect from '@/utils/redirectLogic'
import { useRouter } from 'vue-router'
import { useCurrencyStore } from '@/stores/currency'
import { storeToRefs } from 'pinia'
import { watch, onMounted, ref } from 'vue'

function debounce (func, wait) {
  let timeout
  return function (...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}

export default {
  components: {
    SpinnerView,
    Steps,
    BookingInformation,
    CompanyInformation,
    AdditionalServices,
    PriceBreakdown
  },
  methods: {
    setCount (count, id) {
      const service = this.additionalServices.find((s) => s.id === id)
      service.count = count
      this.debouncedSendUpdatedServicesToServer()
    },
    toggleCheckbox (id) {
      const service = this.additionalServices.find((service) => service.id === id)
      service.selected = !service.selected
      this.isLoading = true
      this.debouncedSendUpdatedServicesToServer()
    }
  },
  setup () {
    const currencyStore = useCurrencyStore()
    const { currencyCode, id: currencyId } = storeToRefs(currencyStore)
    const urlParams = new URLSearchParams(window.location.search)
    const bookingReferenceNumber = urlParams.get('bookingReferenceNumber')
    const router = useRouter()

    const pickUpPlace = ref(null)
    const pickUpDate = ref(null)
    const dropOffDate = ref(null)
    const totalCost = ref(null)
    const bookingId = ref(null)
    const selectedServices = ref(null)
    const additionalServices = ref(null)
    const scooter = ref(null)
    const company = ref(null)
    const companyRating = ref(null)
    const isLoading = ref(false)
    const lat = ref(null)
    const lng = ref(null)

    const fetchAdditionalServices = async () => {
      isLoading.value = true
      try {
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/additional_service/all?is_available=true&currency_id=${currencyId.value}`
        )
        const data = await response.json()
        additionalServices.value = data.content.map((service) => ({
          ...service,
          count: service.max_count ? 0 : service.count
        }))
      } catch (error) {
        console.error('Error when fetching additional services:', error)
      }
    }

    const fetchData = async () => {
      try {
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/booking/${bookingReferenceNumber}?currency_id=${currencyId.value}`
        )
        const data = await response.json()
        const status = data.status
        pickUpPlace.value = data.pick_up_place
        pickUpDate.value = data.pickup_date
        dropOffDate.value = data.dropoff_date
        totalCost.value = data.total_cost
        bookingId.value = data.id
        lat.value = data.lat
        lng.value = data.lng
        selectedServices.value = data.additional_services
        additionalServices.value.forEach((service) => {
          const selectedService = selectedServices.value.find(
            (selected) => selected.additional_service_id === service.id
          )
          if (selectedService) {
            if (service.max_count) {
              service.count = selectedService.count
            } else {
              service.selected = selectedService.count === 1
            }
          }
        })
        performRedirect(router, status, bookingReferenceNumber)
        const scooterResponse = await fetchWrapper(`${API_BASE_URL}v1/scooter/${data.scooter_id}`)
        const scooterData = await scooterResponse.json()
        scooter.value = {
          photo_urls: scooterData.photo_urls,
          brand_name: scooterData.brand_name,
          model_name: scooterData.model_name,
          dailyRate: scooterData.price_per_day,
          company_id: scooterData.company_id,
          currency: currencyCode.value,
          is_custom: scooterData.is_custom // Добавляем is_custom в объект scooter
        }

        const companyResponse = await fetchWrapper(
          `${API_BASE_URL}v1/company/${scooterData.company_id}`
        )
        const companyData = await companyResponse.json()
        company.value = {
          name: companyData.name,
          address: companyData.address,
          description: companyData.description,
          email: companyData.email,
          logo: companyData.logo_url
        }

        const companyReviewResponse = await fetchWrapper(
          `${API_BASE_URL}v1/company_rating/${scooterData.company_id}`
        )
        const companyReviewData = await companyReviewResponse.json()
        companyRating.value = {
          average_rating: companyReviewData.average_rating,
          total_reviews: companyReviewData.total_reviews,
          ratings: companyReviewData.ratings
        }
      } catch (error) {
        console.error('Error when fetching data:', error)
      } finally {
        isLoading.value = false
      }
    }

    const sendUpdatedServicesToServer = async () => {
      const payload = {
        currency: currencyCode.value,
        additional_services: additionalServices.value
          .filter((service) => service.selected || service.count > 0)
          .map((service) => ({
            additional_service_id: service.id,
            count: service.count || (service.selected ? 1 : 0)
          }))
      }

      try {
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/booking/update/${bookingReferenceNumber}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
          }
        )

        const updatedData = await response.json()
        totalCost.value = updatedData.total_cost
        selectedServices.value = updatedData.additional_services
      } catch (error) {
        console.error('Error when updating booking with additional services:', error)
      } finally {
        isLoading.value = false
      }
    }

    const debouncedSendUpdatedServicesToServer = debounce(sendUpdatedServicesToServer, 1000)

    const checkout = async () => {
      try {
        isLoading.value = true
        const payload = {
          status: 'IN_PROGRESS',
          currency: currencyCode.value
        }
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/booking/update/${bookingReferenceNumber}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
          }
        )
        const data = await response.json()
        fetchData()
        if (data.success) {
          router.push(
            `/checkout?bookingReferenceNumber=${bookingReferenceNumber}&currency_id=${currencyId.value}`
          )
        } else {
          console.error('Error updating booking:', data.message)
        }
      } catch (error) {
        console.error('Error when updating booking:', error)
        isLoading.value = false
      }
    }

    watch(currencyCode, async () => {
      await fetchAdditionalServices()
      await sendUpdatedServicesToServer()
      isLoading.value = false
    })

    onMounted(async () => {
      isLoading.value = true
      await fetchAdditionalServices()
      await fetchData()
    })

    return {
      lat,
      lng,
      currencyCode,
      currencyId,
      pickUpPlace,
      pickUpDate,
      dropOffDate,
      totalCost,
      bookingId,
      selectedServices,
      additionalServices,
      scooter,
      company,
      companyRating,
      isLoading,
      sendUpdatedServicesToServer,
      debouncedSendUpdatedServicesToServer,
      checkout
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
.grid-item {
  grid-column: 1;
}
.grid-item:nth-child(4) {
  grid-column: 2;
  grid-row: 1;
  height: fit-content;
}
@media (max-width: 768px) {
  .grid-wrapper {
    display: flex;
    flex-direction: column;
  }
}
</style>
