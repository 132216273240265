<template>
    <div class="unread-messages-count">{{ unreadCount }}</div>
  </template>

<script>
export default {
  props: {
    unreadCount: Number
  }
}
</script>

  <style scoped>
  .unread-messages-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fd0044;
    color: white;
    font-size: 12px;
    position: relative;
    top: -5px;
    right: -10px;
    box-shadow: 0 0 8px rgba(255,61,113,0.6);
    animation: pulse 2s infinite;
  }
  </style>
