<template>
  <div class="price-slider">
    <a-slider
      @change="this.changePrice"
      :min="0"
      :max="this.highestPrice"
      :value="[this.minPrice, this.maxPrice]"
      :step="0.01"
      range
    />
  </div>
</template>

<script>
import { Slider } from 'ant-design-vue'
export default {
  props: ['highestPrice', 'minPrice', 'maxPrice'],
  emits: ['change'],
  components: {
    ASlider: Slider
  },
  methods: {
    changePrice (range) {
      this.$emit('change', range)
    }
  }
}
</script>

<style>
.price-slider {
  padding: 0 6px;
  margin-top: 5px;
}
.ant-slider .ant-slider-track {
  background-color: #fc9544 !important;
}
.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #fc9544 !important;
}
</style>
