import { ref } from 'vue'
import { defineStore } from 'pinia'

const DEFAULT_ID = '1'
const DEFAULT_SYMBOL = '$'
const DEFAULT_CODE = 'USD'

export const useCurrencyStore = defineStore('currencyStore', () => {
  const id = ref(localStorage.getItem('selectedCurrencyId') || DEFAULT_ID)
  const currencySymbol = ref(localStorage.getItem('selectedCurrencySymbol') || DEFAULT_SYMBOL)
  const currencyCode = ref(localStorage.getItem('selectedCurrencyCode') || DEFAULT_CODE)

  return { id, currencySymbol, currencyCode }
})
