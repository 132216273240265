<template>
  <FullModal>
    <section class="info">
      <h1 class="info-title">{{ $t('t-important-information') }}</h1>
      <Collapse class="info-collapse" :items="items">
        <div v-for="item in items" :key="item.id">
          <h2>{{ item.title }}</h2>
          <div v-html="item.text"></div>
        </div>
      </Collapse>
    </section>
  </FullModal>
</template>

<script>
import FullModal from '@/components/FullModal.vue'
import Collapse from '@/components/Collapse.vue'

export default {
  components: {
    FullModal,
    Collapse
  },
  setup () {
    const items = [
      {
        title: 't-terms-and-conditions',
        id: 1,
        text: `<div class="responsive-margin">
                    <div style="text-align: center!important; margin: 0 auto!important;" class="col-12 col-md-8">
                        <h1 class="display-2 mb-3" style="color:#3f4554;">Terms &amp; Conditions</h1>
                        <p class="mb-0" style="color:#3f4554;">Updated at December 17th, 2023</p>
                    </div>
                    <br><br><br>
                    <h1 style="color: #474c52 !important;" class="text-muted">General Terms</h1>
                    <p class="text-muted">By accessing and placing an order with Ursa Rent, you confirm that you are in agreement with and bound by the terms of service contained in the Terms &amp; Conditions outlined below. These terms apply to the entire website and any email or other type of communication between you and Ursa Rent.</p>
                    <p class="text-muted">Under no circumstances shall Ursa Rent team be liable for any direct, indirect, special, incidental or consequential damages, including, but not limited to, loss of data or profit, arising out of the use, or the inability to use, the materials on this site, even if Ursa Rent team or an authorized representative has been advised of the possibility of such damages. If your use of materials from this site results in the need for servicing, repair or correction of equipment or data, you assume any costs thereof.</p>
                    <p class="text-muted">Ursa Rent will not be responsible for any outcome that may occur during the course of usage of our resources. We reserve the rights to change prices and revise the resources usage policy in any moment. </p>
                    <br>
                    <h1 style="color: #474c52 !important;" class="text-muted">License</h1>
                    <p class="text-muted">Ursa Rent grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the website strictly in accordance with the terms of this Agreement.</p>
                    <p class="text-muted">These Terms &amp; Conditions are a contract between you and Ursa Rent (referred to in these Terms &amp; Conditions as "Ursa Rent", "us", "we" or "our"), the provider of the Ursa Rent website and the services accessible from the Ursa Rent website (which are collectively referred to in these Terms &amp; Conditions as the "Ursa Rent Service").</p>
                    <p class="text-muted">You are agreeing to be bound by these Terms &amp; Conditions. If you do not agree to these Terms &amp; Conditions, please do not use the Ursa Rent Service. In these Terms &amp; Conditions, "you" refers both to you as an individual and to the entity you represent. If you violate any of these Terms &amp; Conditions, we reserve the right to cancel your account or block access to your account without notice.</p>
                    <br>
                    <h1 style="color: #474c52 !important;" class="text-muted">Definitions and key terms</h1>
                    <p class="text-muted">To help explain things as clearly as possible in this Terms &amp; Conditions, every time any of these terms are referenced, are strictly defined as:
                      </p><ul class="text-muted">
                        <li>Cookie:&nbsp;small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</li>
                                                <li>Company: when this terms mention “Company,” “we,” “us,” or “our,” it refers to Mikes Hub Solutions, (Graha Merdeka Renon Unit 15, Jl. Merdeka, Desa/Kelurahan Sumerta Kelod, Kec. Denpasar Timur, Kota Denpasar, Provinsi Bali, Kode Pos: 80239), that is responsible for your information under this Terms &amp; Conditions.</li>
                                                                        <li>Country: where Ursa Rent or the owners/founders of Ursa Rent are based, in this case is Indonesia</li>
                        <li>Device:&nbsp;any internet connected device such as a phone, tablet, computer or any other device that can be used to visit Ursa Rent and use the services.</li>
                        <li>Service: refers to the service provided by Ursa Rent as described in the relative terms (if available) and on this platform.</li>
                        <li>Third-party service:&nbsp;refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.</li>
                                                <li>Website: Ursa Rent’s site, which can be accessed via this URL: https://ursarent.com/</li>
                                                                        <li>You: a person or entity that is registered with Ursa Rent to use the Services.</li>
                      </ul>
                    <p></p>
                    <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Restrictions</h1>
                   <p class="text-muted">You agree not to, and you will not permit others to:</p>
                   <ul class="text-muted">
                     <li>License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the website or make the platform available to any third party.</li>
                     <li>Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the website.</li>
                     <li>Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of Ursa Rent or its affiliates, partners, suppliers or the licensors of the website.</li>
                   </ul>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Payment</h1>
                   <p class="text-muted">If you register to any of our recurring payment plans, you agree to pay all fees or charges to your account for the Service in accordance with the fees, charges and billing terms in effect at the time that each fee or charge is due and payable. Unless otherwise indicated in an order form, you must provide Ursa Rent with a valid credit card (Visa, MasterCard, or any other issuer accepted by us) (“Payment Provider”) as a condition to signing up for the Premium plan. Your Payment Provider agreement governs your use of the designated credit card account, and you must refer to that agreement and not these Terms to determine your rights and liabilities with respect to your Payment Provider. By providing Ursa Rent with your credit card number and associated payment information, you agree that Ursa Rent is authorized to verify information immediately, and subsequently invoice your account for all fees and charges due and payable to Ursa Rent hereunder and that no additional notice or consent is required. You agree to immediately notify Ursa Rent of any change in your billing address or the credit card used for payment hereunder. Ursa Rent reserves the right at any time to change its prices and billing methods, either immediately upon posting on our Site or by e-mail delivery to your organization’s administrator(s).</p>
                   <p class="text-muted">Any attorney fees, court costs, or other costs incurred in collection of delinquent undisputed amounts shall be the responsibility of and paid for by you.</p>
                   <p class="text-muted">No contract will exist between you and Ursa Rent for the Service until Ursa Rent accepts your order by a confirmatory e-mail, SMS/MMS message, or other appropriate means of communication.</p>
                   <p class="text-muted">You are responsible for any third-party fees that you may incur when using the Service.</p>
                   <br>
                                                         <h1 style="color: #474c52 !important;" class="text-muted">Return and Refund Policy</h1>
                   <p class="text-muted">Thanks for shopping at Ursa Rent. We appreciate the fact that you like to buy the stuff we build. We also want to make sure you have a rewarding experience while you’re exploring, evaluating, and purchasing our products.</p>
                   <p class="text-muted">As with any shopping experience, there are terms and conditions that apply to transactions at Ursa Rent. We’ll be as brief as our attorneys will allow. The main thing to remember is that by placing an order or making a purchase at Ursa Rent, you agree to the terms along with Ursa Rent’s&nbsp;Privacy Policy.</p>
                   <p class="text-muted">If, for any reason, You are not completely satisfied with any good or service that we provide, don't hesitate to contact us and we will discuss any of the issues you are going through with our product.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Your Suggestions</h1>
                   <p class="text-muted">Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you to Ursa Rent with respect to the website shall remain the sole and exclusive property of Ursa Rent.</p>
                   <p class="text-muted">Ursa Rent shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any way without any credit or any compensation to you.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Your Consent</h1>
                   <p class="text-muted">We've updated our&nbsp;Terms &amp; Conditions&nbsp;to provide you with complete transparency into what is being set when you visit our site and how it's being used. By using our website, registering an account, or making a purchase, you hereby consent to our Terms &amp; Conditions.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Links to Other Websites</h1>
                   <p class="text-muted">This Terms &amp; Conditions applies only to the Services. The Services may contain links to other websites not operated or controlled by Ursa Rent. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Terms &amp; Conditions are no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Cookies</h1>
                   <p class="text-muted">Ursa Rent uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the performance and functionality of our website but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the website as we would not be able to remember that you had logged in previously. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Changes To Our Terms &amp; Conditions</h1>
                   <p class="text-muted">You acknowledge and agree that Ursa Rent may stop (permanently or temporarily) providing the Service (or any features within the Service) to you or to users generally at Ursa Rent’s sole discretion, without prior notice to you. You may stop using the Service at any time. You do not need to specifically inform Ursa Rent when you stop using the Service. You acknowledge and agree that if Ursa Rent disables access to your account, you may be prevented from accessing the Service, your account details or any files or other materials which is contained in your account.</p>
                   <p class="text-muted">If we decide to change our Terms &amp; Conditions, we will post those changes on this page, and/or update the Terms &amp; Conditions modification date below.</p>
                    <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Modifications to Our website</h1>
                   <p class="text-muted">Ursa Rent reserves the right to modify, suspend or discontinue, temporarily or permanently, the website or any service to which it connects, with or without notice and without liability to you.</p>
                    <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Updates to Our website</h1>
                   <p class="text-muted">Ursa Rent may from time to time provide enhancements or improvements to the features/ functionality of the website, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").</p>
                   <p class="text-muted">Updates may modify or delete certain features and/or functionalities of the website. You agree that Ursa Rent has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the website to you.</p>
                   <p class="text-muted">You further agree that all Updates will be (i) deemed to constitute an integral part of the website, and (ii) subject to the terms and conditions of this Agreement.</p>
                    <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Third-Party Services</h1>
                   <p class="text-muted">We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services").</p>
                   <p class="text-muted">You acknowledge and agree that Ursa Rent shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Ursa Rent does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.</p>
                   <p class="text-muted">Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.</p>
                    <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Term and Termination</h1>
                   <p class="text-muted">This Agreement shall remain in effect until terminated by you or Ursa Rent.</p>
                   <p class="text-muted">Ursa Rent may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.</p>
                   <p class="text-muted">This Agreement will terminate immediately, without prior notice from Ursa Rent, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the website and all copies thereof from your computer.</p>
                   <p class="text-muted">
                   Upon termination of this Agreement, you shall cease all use of the website and delete all copies of the website from your computer.</p>
                   <p class="text-muted">
                   Termination of this Agreement will not limit any of Ursa Rent's rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Copyright Infringement Notice</h1>
                   <p class="text-muted">If you are a copyright owner or such owner’s agent and believe any material on our website constitutes an infringement on your copyright, please contact us setting forth the following information: (a) a physical or electronic signature of the copyright owner or a person authorized to act on his behalf; (b) identification of the material that is claimed to be infringing; (c) your contact information, including your address, telephone number, and an email; (d) a statement by you that you have a good faith belief that use of the material is not authorized by the copyright owners; and (e) the a statement that the information in the notification is accurate, and, under penalty of perjury you are authorized to act on behalf of the owner.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Indemnification</h1>
                   <p class="text-muted">You agree to indemnify and hold Ursa Rent and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the website; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">No Warranties</h1>
                   <p class="text-muted">The website is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, Ursa Rent, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the website, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, Ursa Rent provides no warranty or undertaking, and makes no representation of any kind that the website will meet your requirements, achieve any intended results, be compatible or work with any other software, websites, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
                   <p class="text-muted">Without limiting the foregoing, neither Ursa Rent nor any Ursa Rent's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the website, or the information, content, and materials or products included thereon; (ii) that the website will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the website; or (iv) that the website, its servers, the content, or e-mails sent from or on behalf of Ursa Rent are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
                   <p class="text-muted">Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Limitation of Liability</h1>
                   <p class="text-muted">Notwithstanding any damages that you might incur, the entire liability of Ursa Rent and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you for the website.</p>
                   <p class="text-muted">To the maximum extent permitted by applicable law, in no event shall Ursa Rent or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the website, third-party software and/or third-party hardware used with the website, or otherwise in connection with any provision of this Agreement), even if Ursa Rent or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
                   <p class="text-muted">Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Severability</h1>
                   <p class="text-muted">If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
                   <p class="text-muted">This Agreement, together with the Privacy Policy and any other legal notices published by Ursa Rent on the Services, shall constitute the entire agreement between you and Ursa Rent concerning the Services. If any provision of this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or any other term, and Ursa Rent’s failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision. YOU AND Ursa Rent AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Waiver</h1>
                   <p class="text-muted">Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute waiver of any subsequent breach.</p>
                   <p class="text-muted">No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under this Agreement shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under this Agreement preclude further exercise of that or any other right granted herein. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Amendments to this Agreement</h1>
                   <p class="text-muted">Ursa Rent reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                   <p class="text-muted">By continuing to access or use our website after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Ursa Rent.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Entire Agreement</h1>
                   <p class="text-muted">The Agreement constitutes the entire agreement between you and Ursa Rent regarding your use of the website and supersedes all prior and contemporaneous written or oral agreements between you and Ursa Rent.</p>
                   <p class="text-muted">You may be subject to additional terms and conditions that apply when you use or purchase other Ursa Rent's  services, which Ursa Rent will provide to you at the time of such use or purchase.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Updates to Our Terms</h1>
                   <p class="text-muted">We may change our Service and policies, and we may need to make changes to these Terms so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your account.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Intellectual Property</h1>
                   <p class="text-muted">The website and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by Ursa Rent, its licensors or other providers of such material and are protected by Indonesia and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. The material may not be copied, modified, reproduced, downloaded or distributed in any way, in whole or in part, without the express prior written permission of Ursa Rent, unless and except as is expressly provided in these Terms &amp; Conditions. Any unauthorized use of the material is prohibited.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Agreement to Arbitrate</h1>
                   <p class="text-muted">This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR Ursa Rent’s INTELLECTUAL PROPERTY RIGHTS. The term “dispute” means any dispute, action, or other controversy between you and Ursa Rent concerning the Services or this agreement, whether in contract, warranty, tort, statute, regulation, ordinance, or any other legal or equitable basis. “Dispute” will be given the broadest possible meaning allowable under law.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Notice of Dispute</h1>
                   <p class="text-muted">In the event of a dispute, you or Ursa Rent must give the other a Notice of Dispute, which is a written statement that sets forth the name, address, and contact information of the party giving it, the facts giving rise to the dispute, and the relief requested. You must send any Notice of Dispute via email to:&nbsp;. Ursa Rent will send any Notice of Dispute to you by mail to your address if we have it, or otherwise to your email address. You and Ursa Rent will attempt to resolve any dispute through informal negotiation within sixty (60) days from the date the Notice of Dispute is sent. After sixty (60) days, you or Ursa Rent may commence arbitration.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Binding Arbitration</h1>
                   <p class="text-muted">If you and Ursa Rent don’t resolve any dispute by informal negotiation, any other effort to resolve the dispute will be conducted exclusively by binding arbitration as described in this section. You are giving up the right to litigate (or participate in as a party or class member) all disputes in court before a judge or jury. The dispute shall be settled by binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association. Either party may seek any interim or preliminary injunctive relief from any court of competent jurisdiction, as necessary to protect the party’s rights or property pending the completion of arbitration. Any and all legal, accounting, and other costs, fees, and expenses incurred by the prevailing party shall be borne by the non-prevailing party.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Submissions and Privacy</h1>
                   <p class="text-muted">In the event that you submit or post any ideas, creative suggestions, designs, photographs, information, advertisements, data or proposals, including ideas for new or improved products, services, features, technologies or promotions, you expressly agree that such submissions will automatically be treated as non-confidential and non-proprietary and will become the sole property of Ursa Rent without any compensation or credit to you whatsoever. Ursa Rent and its affiliates shall have no obligations with respect to such submissions or posts and may use the ideas contained in such submissions or posts for any purposes in any medium in perpetuity, including, but not limited to, developing, manufacturing, and marketing products and services using such ideas.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Promotions</h1>
                   <p class="text-muted">Ursa Rent may, from time to time, include contests, promotions, sweepstakes, or other activities (“Promotions”) that require you to submit material or information concerning yourself. Please note that all Promotions may be governed by separate rules that may contain certain eligibility requirements, such as restrictions as to age and geographic location. You are responsible to read all Promotions rules to determine whether or not you are eligible to participate. If you enter any Promotion, you agree to abide by and to comply with all Promotions Rules.</p>
                   <p class="text-muted">Additional terms and conditions may apply to purchases of goods or services on or through the Services, which terms and conditions are made a part of this Agreement by this reference.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Typographical Errors</h1>
                   <p class="text-muted">In the event a product and/or service is listed at an incorrect price or with incorrect information due to typographical error, we shall have the right to refuse or cancel any orders placed for the product and/or service listed at the incorrect price. We shall have the right to refuse or cancel any such order whether or not the order has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is canceled, we shall immediately issue a credit to your credit card account or other payment account in the amount of the charge.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Miscellaneous</h1>
                   <p class="text-muted">If for any reason a court of competent jurisdiction finds any provision or portion of these Terms &amp; Conditions to be unenforceable, the remainder of these Terms &amp; Conditions will continue in full force and effect. Any waiver of any provision of these Terms &amp; Conditions will be effective only if in writing and signed by an authorized representative of Ursa Rent. Ursa Rent will be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety) in the event of any breach or anticipatory breach by you. Ursa Rent operates and controls the Ursa Rent Service from its offices in Indonesia. The Service is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation. Accordingly, those persons who choose to access the Ursa Rent Service from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable. These Terms &amp; Conditions (which include and incorporate the Ursa Rent Privacy Policy) contains the entire understanding, and supersedes all prior understandings, between you and Ursa Rent concerning its subject matter, and cannot be changed or modified by you. The section headings used in this Agreement are for convenience only and will not be given any legal import.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Disclaimer</h1>
                   <p class="text-muted">Ursa Rent is not responsible for any content, code or any other imprecision.</p>
                   <p class="text-muted">Ursa Rent does not provide warranties or guarantees.</p>
                   <p class="text-muted">In no event shall Ursa Rent be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.</p>
                   <p class="text-muted">The Ursa Rent Service and its contents are provided "as is" and "as available" without any warranty or representations of any kind, whether express or implied. Ursa Rent is a distributor and not a publisher of the content supplied by third parties; as such, Ursa Rent exercises no editorial control over such content and makes no warranty or representation as to the accuracy, reliability or currency of any information, content, service or merchandise provided through or accessible via the Ursa Rent Service. Without limiting the foregoing, Ursa Rent specifically disclaims all warranties and representations in any content transmitted on or in connection with the Ursa Rent Service or on sites that may appear as links on the Ursa Rent Service, or in the products provided as a part of, or otherwise in connection with, the Ursa Rent Service, including without limitation any warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or written information given by Ursa Rent or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty. Price and availability information is subject to change without notice. Without limiting the foregoing, Ursa Rent does not warrant that the Ursa Rent Service will be uninterrupted, uncorrupted, timely, or error-free.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Contact Us</h1>
                   <p class="text-muted">Don't hesitate to contact us if you have any questions.</p>
                   <ul class="text-muted">
                   <li>Via Phone Number:  (+62) 812-3957-09-46</li>                  </ul>
                </div>`,
        isHtml: true,
        isUpdateUrl: false
      },
      {
        title: 't-privacy-policy',
        id: 2,
        text: `<div class="responsive-margin">
                    <div style="text-align: center!important; margin: 0 auto!important;" class="col-12 col-md-8">
                        <h1 class="display-2 mb-3" style="color:#3f4554;">Privacy Policy</h1>
                        <p class="mb-0" style="color:#3f4554;">Updated at December 17th, 2023</p>
                    </div>
                    <br><br><br>
                    <p class="text-muted"> Ursa Rent (“we,” “our,” or “us”) is committed to protecting your privacy.  This Privacy Policy explains how your personal information is collected, used, and disclosed by Ursa Rent.</p>
                    <p class="text-muted">This Privacy Policy applies to our website, and its associated subdomains (collectively, our “Service”) alongside our application, Ursa Rent. By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.</p>
                    <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Definitions and key terms</h1>
                   <p class="text-muted">To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictly defined as:</p>
                     <ul class="text-muted">
                       <li>Cookie:&nbsp;small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</li>
                                              <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Mikes Hub Solutions, (Graha Merdeka Renon Unit 15, Jl. Merdeka, Desa/Kelurahan Sumerta Kelod, Kec. Denpasar Timur, Kota Denpasar, Provinsi Bali, Kode Pos: 80239), that is responsible for your information under this Privacy Policy.</li>
                                                                     <li>Country: where Ursa Rent or the owners/founders of Ursa Rent are based, in this case is Indonesia</li>
                       <li>Customer:&nbsp;refers to the company, organization or person that signs up to use the Ursa Rent Service to manage the relationships with your consumers or service users.</li>
                       <li>Device:&nbsp;any internet connected device such as a phone, tablet, computer or any other device that can be used to visit Ursa Rent and use the services.</li>
                       <li>IP address: Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.</li>
                       <li>Personnel:&nbsp;refers to those individuals who are employed by Ursa Rent or are under contract to perform a service on behalf of one of the parties.</li>
                       <li>Personal Data: any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</li>
                       <li>Service: refers to the service provided by Ursa Rent as described in the relative terms (if available) and on this platform. </li>
                       <li>Third-party service:&nbsp;refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.</li>
                                              <li>Website: Ursa Rent’s site, which can be accessed via this URL: http://ursarent.com/</li>
                                                                     <li>You: a person or entity that is registered with Ursa Rent to use the Services.</li>
                     </ul>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">What Information Do We Collect?</h1>
                   <p class="text-muted">We collect information from you when you visit our website, register on our site, place an order, subscribe to our newsletter, respond to a survey or fill out a form.</p>
                   <ul class="text-muted">
                     <li>Name / Username</li><li>Phone Numbers</li><li>Email Addresses</li><li>Mailing Addresses</li>                   </ul>
                                      <p class="text-muted">We also collect information from mobile devices for a better user experience, although these features are completely optional:</p>
                   <ul class="text-muted">
                     <li>Location (GPS): Location data&nbsp;helps to create an accurate representation of your interests, and this can be used to bring more targeted and relevant ads to potential customers.</li><li>Phonebook (Contacts list): Your contacts list allows the platform to be much more easy to use by the user, since accessing your contacts from the app makes you save tons of time.</li><li>Camera (Pictures): Granting camera&nbsp;permission&nbsp;allows the user to upload any picture straight from the platform, you can safely deny camera permissions for this platform.</li><li>Photo Gallery (Pictures): Granting photo gallery access&nbsp;allows the user to upload any picture from their photo gallery, you can safely deny photo gallery access for this platform.</li>                   </ul>
                                      <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">When does Ursa Rent use end user information from third parties?</h1>
                   <p class="text-muted">Ursa Rent will collect End User Data necessary to provide the Ursa Rent services to our customers.
                   End users may voluntarily provide us with information they have made available on social media websites. If you provide us with any such information, we may collect publicly available information from the social media websites you have indicated. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">When does Ursa Rent use customer information from third parties?</h1>
                   <p class="text-muted">We receive some information from the third parties when you contact us. For example, when you submit your email address to us to show interest in becoming a Ursa Rent customer, we receive information from a third party that provides automated fraud detection services to Ursa Rent. We also occasionally collect information that is made publicly available on social media websites. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Do we share the information we collect with third parties?</h1>
                   <p class="text-muted">We may share the information that we collect, both personal and non-personal, with third parties such as advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you. We may also share it with our current and future affiliated companies and business partners, and if we are involved in a merger, asset sale or other business reorganization, we may also share or transfer your personal and non-personal information to our successors-in-interest.</p>
                   <p class="text-muted">We may engage trusted third party service providers to perform functions and provide services to us, such as hosting and maintaining our servers and the website, database storage and management, e-mail management, storage marketing, credit card processing, customer service and fulfilling orders for products and services you may purchase through the website. We will likely share your personal information, and possibly some non-personal information, with these third parties to enable them to perform these services for us and for you.</p>
                   <p class="text-muted">We may share portions of our log file data, including IP addresses, for analytics purposes with third parties such as web analytics partners, application developers, and ad networks. If your IP address is shared, it may be used to estimate general location and other technographics such as connection speed, whether you have visited the website in a shared location, and type of the device used to visit the website. They may aggregate information about our advertising and what you see on the website and then provide auditing, research and reporting for us and our advertisers.</p>
                   <p class="text-muted">We may also disclose personal and non-personal information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate in order to respond to claims, legal process (including subpoenas), to protect our rights and interests or those of a third party, the safety of the public or any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to otherwise comply with applicable court orders, laws, rules and regulations.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Where and when is information collected from customers and end users?</h1>
                   <p class="text-muted">Ursa Rent will collect personal information that you submit to us. We may also receive personal information about you from third parties as described above.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">How Do We Use The Information We Collect?</h1>
                   <p class="text-muted">Any of the information we collect from you may be used in one of the following ways:
                     </p><ul class="text-muted">
                       <li>To personalize your experience (your information helps us to better respond to your individual needs)</li>
                       <li>To improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you)</li>
                       <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)</li>
                       <li>To process transactions</li>
                       <li>To administer a contest, promotion, survey or other site feature</li>
                       <li>To send periodic emails</li>
                     </ul>
                   <p></p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">How Do We Use Your Email Address?</h1>
                   <p class="text-muted">By submitting your email address on this website, you agree to receive emails from us. You can cancel your participation in any of these email lists at any time by clicking on the opt-out link or other unsubscribe option that is included in the respective email. We only send emails to people who have authorized us to contact them, either directly, or through a third party. We do not send unsolicited commercial emails, because we hate spam as much as you do. By submitting your email address, you also agree to allow us to use your email address for customer audience targeting on sites like Facebook, where we display custom advertising to specific people who have opted-in to receive communications from us. Email addresses submitted only through the order processing page will be used for the sole purpose of sending you information and updates pertaining to your order. If, however, you have provided the same email to us through another method, we may use it for any of the purposes stated in this Policy. Note: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">How Long Do We Keep Your Information?</h1>
                   <p class="text-muted">We keep your information only so long as we need it to provide Ursa Rent to you and fulfill the purposes described in this policy. This is also the case for anyone that we share your information with and who carries out services on our behalf. When we no longer need to use your information and there is no need for us to keep it to comply with our legal or regulatory obligations, we’ll either remove it from our systems or depersonalize it so that we can't identify you.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">How Do We Protect Your Information?</h1>
                   <p class="text-muted">We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) is never kept on file. We cannot, however, ensure or warrant the absolute security of any information you transmit to Ursa Rent or guarantee that your information on the Service may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Could my information be transferred to other countries?</h1>
                   <p class="text-muted">Ursa Rent is incorporated in Indonesia. Information collected via our website, through direct interactions with you, or from use of our help services may be transferred from time to time to our offices or personnel, or to third parties, located throughout the world, and may be viewed and hosted anywhere in the world, including countries that may not have laws of general applicability regulating the use and transfer of such data. To the fullest extent allowed by applicable law, by using any of the above, you voluntarily consent to the trans-border transfer and hosting of such information.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Is the information collected through the Ursa Rent Service secure?</h1>
                   <p class="text-muted">We take precautions to protect the security of your information. We have physical, electronic, and managerial procedures to help safeguard, prevent unauthorized access, maintain data security, and correctly use your information. However, neither people nor security systems are foolproof, including encryption systems. In addition, people can commit intentional crimes, make mistakes or fail to follow policies. Therefore, while we use reasonable efforts to protect your personal information, we cannot guarantee its absolute security. If applicable law imposes any non-disclaimable duty to protect your personal information, you agree that intentional misconduct will be the standards used to measure our compliance with that duty.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Can I update or correct my information?</h1>
                   <p class="text-muted">The rights you have to request updates or corrections to the information Ursa Rent collects depend on your relationship with Ursa Rent. Personnel may update or correct their information as detailed in our internal company employment policies.</p>
                   <p class="text-muted">Customers have the right to request the restriction of certain uses and disclosures of personally identifiable information as follows. You can&nbsp;contact us in order to (1) update or correct your personally identifiable information, (2) change your preferences with respect to communications and other information you receive from us, or (3) delete the personally identifiable information maintained about you on our systems (subject to the following paragraph), by&nbsp;cancelling your account. Such updates, corrections, changes and deletions will have no effect on other information that we maintain, or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion. To protect your privacy and security, we may take reasonable steps (such as requesting a unique password) to verify your identity before granting you profile access or making corrections. You are responsible for maintaining the secrecy of your unique password and account information at all times.</p>
                   <p class="text-muted">You should be aware that it is not technologically possible to remove each and every record of the information you have provided to us from our system. The need to back up our systems to protect information from inadvertent loss means that a copy of your information may exist in a non-erasable form that will be difficult or impossible for us to locate. Promptly after receiving your request, all personal information stored in databases we actively use, and other readily searchable media will be updated, corrected, changed or deleted, as appropriate, as soon as and to the extent reasonably and technically practicable.</p>
                   <p class="text-muted">If you are an end user and wish to update, delete, or receive any information we have about you, you may do so by contacting the organization of which you are a customer.</p>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Personnel</h1>
                   <p class="text-muted">If you are a Ursa Rent worker or applicant, we collect information you voluntarily provide to us. We use the information collected for Human Resources purposes in order to administer benefits to workers and screen applicants.</p>
                   <p class="text-muted">You may contact us in order to (1) update or correct your information, (2) change your preferences with respect to communications and other information you receive from us, or (3) receive a record of the information we have relating to you. Such updates, corrections, changes and deletions will have no effect on other information that we maintain, or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion.</p>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Sale of Business</h1>
                   <p class="text-muted">We reserve the right to transfer information to a third party in the event of a sale, merger or other transfer of all or substantially all of the assets of Ursa Rent or any of its Corporate Affiliates (as defined herein), or that portion of Ursa Rent or any of its Corporate Affiliates to which the Service relates, or in the event that we discontinue our business or file a petition or have filed against us a petition in bankruptcy, reorganization or similar proceeding, provided that the third party agrees to adhere to the terms of this Privacy Policy.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Affiliates</h1>
                   <p class="text-muted">We may disclose information (including personal information) about you to our Corporate Affiliates. For purposes of this Privacy Policy, "Corporate Affiliate" means any person or entity which directly or indirectly controls, is controlled by or is under common control with Ursa Rent, whether by ownership or otherwise. Any information relating to you that we provide to our Corporate Affiliates will be treated by those Corporate Affiliates in accordance with the terms of this Privacy Policy.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Governing Law</h1>
                   <p class="text-muted">This Privacy Policy is governed by the laws of Indonesia without regard to its conflict of laws provision. You consent to the exclusive jurisdiction of the courts in connection with any action or dispute arising between the parties under or in connection with this Privacy Policy except for those individuals who may have rights to make claims under Privacy Shield, or the Swiss-US framework.</p>
                   <p class="text-muted">The laws of Indonesia, excluding its conflicts of law rules, shall govern this Agreement and your use of the website. Your use of the website may also be subject to other local, state, national, or international laws.</p>
                   <p class="text-muted">By using Ursa Rent or contacting us directly, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, you should not engage with our website, or use our services. Continued use of the website, direct engagement with us, or following the posting of changes to this Privacy Policy that do not significantly affect the use or disclosure of your personal information will mean that you accept those changes.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Your Consent</h1>
                   <p class="text-muted">We've updated our&nbsp;Privacy Policy&nbsp;to provide you with complete transparency into what is being set when you visit our site and how it's being used. By using our Ursa Rent, registering an account, or making a purchase, you hereby consent to our Privacy Policy and agree to its terms.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Links to Other Websites</h1>
                   <p class="text-muted">This Privacy Policy applies only to the Services. The Services may contain links to other websites not operated or controlled by Ursa Rent. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.</p>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Cookies</h1>
                   <p class="text-muted">Ursa Rent uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the performance and functionality of our website but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the website as we would not be able to remember that you had logged in previously. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Blocking and disabling cookies and similar technologies</h1>
                   <p class="text-muted">Wherever you're located you may also set your browser to block cookies and similar technologies, but this action may block our essential cookies and prevent our website from functioning properly, and you may not be able to fully utilize all of its features and services. You should also be aware that you may also lose some saved information (e.g. saved login details, site preferences) if you block cookies on your browser. Different browsers make different controls available to you. Disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser, you should visit your browser's help menu for more information.</p>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Remarketing Services</h1>
                   <p class="text-muted">We use remarketing services. What Is Remarketing? In digital marketing, remarketing (or retargeting) is the practice of serving ads across the internet to people who have already visited your website. It allows your company to seem like they're “following” people around the internet by serving ads on the websites and platforms they use most.</p>
                   <br>
                                                          <h1 style="color: #474c52 !important;" class="text-muted">Payment Details</h1>
                   <p class="text-muted">In respect to any credit card or other payment processing details you have provided us, we commit that this confidential information will be stored in the most secure manner possible.</p>
                   <br>
                                                                            <h1 style="color: #474c52 !important;" class="text-muted">Kids' Privacy</h1>
                   <p class="text-muted">We do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Changes To Our Privacy Policy</h1>
                   <p class="text-muted">We may change our Service and policies, and we may need to make changes to this Privacy Policy so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to this Privacy Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, you can delete your account.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Third-Party Services</h1>
                   <p class="text-muted">We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services").</p>
                   <p class="text-muted">You acknowledge and agree that Ursa Rent shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Ursa Rent does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.</p>
                   <p class="text-muted">Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.</p>
                                                          <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Tracking Technologies</h1>
                                           <ul style="margin-left: 10px;">
                     <br>
                     <li>Google Maps API
                         <br><br>
                         <p class="text-muted">Google Maps API&nbsp;is a robust tool that can be used to create a custom&nbsp;map, a searchable&nbsp;map, check-in functions, display live data synching with location, plan routes, or create a mashup just to name a few.</p>
                         <p class="text-muted">Google Maps API may collect information from You and from Your Device for security purposes.</p>
                         <p class="text-muted">Google Maps API collects information that is held in accordance with its Privacy Policy</p></li><br><li>Cookies
                              <br><br>
                              <p class="text-muted">We use Cookies to enhance the performance and functionality of our platform but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the platform as we would not be able to remember that you had logged in previously.</p>
                              </li><br><li>Local Storage
                         <br><br>
                         <p class="text-muted">Local Storage sometimes known as DOM storage, provides web apps with methods and protocols for storing client-side data. Web storage supports persistent data storage, similar to cookies but with a greatly enhanced capacity and no information stored in the HTTP request header.</p></li><br><li>Sessions
                            <br><br>
                            <p class="text-muted">We use "Sessions" to identify the areas of our platform that you have visited. A Session is a small piece of data stored on your computer or mobile device by your web browser.</p></li><br>                    </ul>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Information about General Data Protection Regulation (GDPR)</h1>
                   <p class="text-muted">We may be collecting and using information from you if you are from the European Economic Area (EEA), and in this section of our Privacy Policy we are going to explain exactly how and why is this data collected, and how we maintain this data under protection from being replicated or used in the wrong way.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">What is GDPR?</h1>
                   <p class="text-muted">GDPR is an EU-wide privacy and data protection law that regulates how EU residents' data is protected by companies and enhances the control&nbsp;the EU residents have, over their personal data.</p>
                   <p class="text-muted">The GDPR is relevant to any globally operating company and not just the EU-based businesses and EU residents. Our customers’ data is important irrespective of where they are located, which is why we have implemented GDPR controls as our baseline standard for all our operations worldwide.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">What is personal data?</h1>
                   <p class="text-muted">Any data that relates to an identifiable or identified individual. GDPR covers a broad spectrum of information that could be used on its own, or in combination with other pieces of information, to identify a person. Personal data extends beyond a person’s name or email address. Some examples include financial information, political opinions, genetic data, biometric data, IP addresses, physical address, sexual orientation, and ethnicity.</p>
                   <p class="text-muted">The Data Protection Principles include requirements such as:</p>
                   <ul class="text-muted">
                     <li>Personal data collected must be processed in a fair, legal, and transparent way and should only be used in a way that a person would reasonably expect.</li>
                     <li>Personal data should only be collected to fulfil a specific purpose and it should only be used for that purpose. Organizations must specify why they need the personal data when they collect it.</li>
                     <li>Personal data should be held no longer than necessary to fulfil its purpose.</li>
                     <li>People covered by the GDPR have the right to access their own personal data. They can also request a copy of their data, and that their data be updated, deleted, restricted, or moved to another organization.</li>
                   </ul>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Why is GDPR important?</h1>
                   <p class="text-muted">GDPR adds some new requirements regarding how companies should protect individuals' personal data that they collect and process. It also raises the stakes for compliance by increasing enforcement and imposing greater fines for breach. Beyond these facts it's simply the right thing to do. At Ursa Rent we strongly believe that your data privacy is very important and we already have solid security and privacy practices in place that go beyond the requirements of this new regulation.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Individual Data Subject's Rights - Data Access, Portability and Deletion</h1>
                   <p class="text-muted">We are committed to helping our customers meet the&nbsp;data subject rights requirements of GDPR. Ursa Rent processes or stores all personal data in fully vetted, DPA compliant vendors. We do store all conversation and personal data for up to 6 years unless your account is deleted. In which case, we dispose of all data in accordance with our Terms of Service and Privacy Policy, but we will not hold it longer than 60 days.</p>
                   <p class="text-muted">We are aware that if you are working with EU customers, you need to be able to provide them with the ability to access, update, retrieve and remove personal data. We got you! We've been set up as self service from the start and have always given you access to your data and your customers data. Our customer&nbsp;support team is here for you to answer any questions you might have about working with the API.</p>
                   <br>
                                                         <h1 style="color: #474c52 !important;" class="text-muted">California Residents</h1>
                   <p class="text-muted">The California Consumer Privacy Act (CCPA) requires us to disclose categories of Personal Information we collect and how we use it, the categories of sources from whom we collect Personal Information, and the third parties with whom we share it, which we have explained above.</p>
                   <p class="text-muted">We are also required to communicate information about rights California residents have under California law. You may exercise the following rights:</p>
                   <ul class="text-muted">
                     <li>Right to Know and Access. You may submit a verifiable request for information regarding the: (1) categories of Personal Information we collect, use, or share; (2) purposes for which categories of Personal Information are collected or used by us; (3) categories of sources from which we collect Personal Information; and (4) specific pieces of Personal Information we have collected about you.</li>
                     <li>Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.</li>
                     <li>Right to Delete. You may submit a verifiable request to close your account and we will delete Personal Information about you that we have collected.</li>
                     <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
                   </ul>
                   <p class="text-muted">If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
                   <p class="text-muted">We do not sell the Personal Information of our users.</p>
                   <p class="text-muted">For more information about these rights, please contact us.</p>
                   <br>
                                                         <h1 style="color: #474c52 !important;" class="text-muted">California Online Privacy Protection Act (CalOPPA)</h1>
                   <p class="text-muted">CalOPPA requires us to disclose categories of Personal Information we collect and how we use it, the categories of sources from whom we collect Personal Information, and the third parties with whom we share it, which we have explained above.</p>
                   <p class="text-muted">CalOPPA users have the following rights:</p>
                   <ul class="text-muted">
                     <li>Right to Know and Access. You may submit a verifiable request for information regarding the: (1) categories of Personal Information we collect, use, or share; (2) purposes for which categories of Personal Information are collected or used by us; (3) categories of sources from which we collect Personal Information; and (4) specific pieces of Personal Information we have collected about you.</li>
                     <li>Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.</li>
                     <li>Right to Delete. You may submit a verifiable request to close your account and we will delete Personal Information about you that we have collected.</li>
                     <li>Right to request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
                   </ul>
                   <p class="text-muted">If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
                   <p class="text-muted">We do not sell the Personal Information of our users.</p>
                   <p class="text-muted">For more information about these rights, please contact us.</p>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Contact Us</h1>
                   <p class="text-muted">Don't hesitate to contact us if you have any questions.</p>
                   <ul class="text-muted">
                   <li>Via Phone Number:  (+62) 812-3957-09-46</li>                  </ul>
                </div>`,
        isHtml: true,
        isUpdateUrl: false
      },
      {
        title: 't-disclaimer',
        id: 3,
        text: `<div class="responsive-margin">
                    <div style="text-align: center!important; margin: 0 auto!important;" class="col-12 col-md-8">
                        <h1 class="display-2 mb-3" style="color:#3f4554;">Disclaimer</h1>
                        <p class="mb-0" style="color:#3f4554;">Updated at December 17th, 2023</p>
                    </div>
                    <br><br><br>
                    <p class="text-muted">Ursa Rent hereby grants you access to https://ursarent.com/ (“the Website”) and invites you to purchase the services offered here.</p>
                    <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Definitions and key terms</h1>
                   <p class="text-muted">To help explain things as clearly as possible in this Disclaimer, every time any of these terms are referenced, are strictly defined as:</p>
                     <ul class="text-muted">
                       <li>Cookie:&nbsp;small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</li>
                                              <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Mikes Hub Solutions, (Graha Merdeka Renon Unit 15, Jl. Merdeka, Desa/Kelurahan Sumerta Kelod, Kec. Denpasar Timur, Kota Denpasar, Provinsi Bali, Kode Pos: 80239), that is responsible for your information under this Disclaimer.</li>
                                                                     <li>Service: refers to the service provided by Ursa Rent as described in the relative terms (if available) and on this platform. </li>
                                              <li>Website: Ursa Rent’s site, which can be accessed via this URL: https://ursarent.com/</li>
                                                                     <li>You: a person or entity that is registered with Ursa Rent to use the Services.</li>
                     </ul>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Limited liability</h1>
                   <p class="text-muted">Ursa Rent endeavours to update and/or supplement the content of the website on a regular basis. Despite our care and attention, content may be incomplete and/or incorrect.</p>
                   <p class="text-muted">The materials offered on the website are offered without any form of guarantee or claim to their correctness. These materials can be changed at any time without prior notice from Ursa Rent.</p>
                   <p class="text-muted">Particularly, all prices on the website are stated subject to typing and programming errors. No liability is assumed for the implications of such errors. No agreement is concluded on the basis of such errors.</p>
                   <p class="text-muted">Ursa Rent shall not bear any liability for hyperlinks to websites or services of third parties included on the website. From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone ‘bad’.</p>
                   <p class="text-muted">Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.</p>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Links to Other Websites Disclaimer</h1>
                   <p class="text-muted">This Disclaimer applies only to the Services. The Services may contain links to other websites not operated or controlled by Ursa Rent. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy and Terms of every site You visit.</p>
                   <p class="text-muted">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                   <br>
                                       <h1 style="color: #474c52 !important;" class="text-muted">Errors and Omissions Disclaimer</h1>
                    <p class="text-muted">Ursa Rent is not responsible for any content, code or any other imprecision.</p>
                    <p class="text-muted">Ursa Rent does not provide warranties or guarantees.</p>
                    <p class="text-muted">In no event shall Ursa Rent be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. Ursa Rent reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.</p>
                    <br>
                    <h1 style="color: #474c52 !important;" class="text-muted">General Disclaimer</h1>
                    <p class="text-muted">The Ursa Rent Service and its contents are provided "as is" and "as available" without any warranty or representations of any kind, whether express or implied. Ursa Rent is a distributor and not a publisher of the content supplied by third parties; as such, Ursa Rent exercises no editorial control over such content and makes no warranty or representation as to the accuracy, reliability or currency of any information, content, service or merchandise provided through or accessible via the Ursa Rent Service. Without limiting the foregoing, Ursa Rent specifically disclaims all warranties and representations in any content transmitted on or in connection with the Ursa Rent Service or on sites that may appear as links on the Ursa Rent Service, or in the products provided as a part of, or otherwise in connection with, the Ursa Rent Service, including without limitation any warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or written information given by Ursa Rent or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty. Price and availability information is subject to change without notice. Without limiting the foregoing, Ursa Rent does not warrant that the Ursa Rent Service will be uninterrupted, uncorrupted, timely, or error-free.</p>
                    <br>
                                        <h1 style="color: #474c52 !important;" class="text-muted">Copyright Disclaimer</h1>
                    <p class="text-muted">All intellectual property rights concerning these materials are vested in Ursa Rent. Copying, distribution and any other use of these materials is not permitted without the written permission of Ursa Rent, except and only to the extent otherwise provided in regulations of mandatory law (such as the right to quote), unless otherwise stated for certain materials.</p>
                    <br>
                                                          <h1 style="color: #474c52 !important;" class="text-muted">Affiliate Links Disclosure</h1>
                   <p class="text-muted">Ursa Rent has affiliate links and in this section of the Disclaimer we will address how we use those affiliate links from other websites/companies and products. These “affiliate links” are specific URLs that contain the&nbsp;affiliate's&nbsp;ID or username.</p>
                   <p class="text-muted">In compliance with the FTC guidelines, please assume the following about links and posts on this site:</p>
                     <ul class="text-muted">
                       <li>Any/all of the links on Ursa Rent are affiliate links of which we receive a small commission from sales of certain items, but the price is the same for you. As Ursa Rent has grown, so have costs associated with running and maintaining it, and affiliate links are a way we help offset these costs.</li>
                       <li>If we post an affiliate link to a product, it is something that we personally use, support and would recommend without an affiliate link.</li>
                       <li>Unless otherwise noted, all reviews are of items we have purchased and we are not paid or compensated in any way.</li>
                     </ul>
                     <br>
                                        <p class="text-muted">We might participate in affiliate programs such as:</p>
                   <ul class="text-muted">
                     <li>Others</li>                   </ul>
                   <br>
                                                          <h1 style="color: #474c52 !important;" class="text-muted">Health Disclosure</h1>
                   <p class="text-muted">The information on this website such as text, graphics, images, and other materials created by Ursa Rent or obtained from Ursa Rent’s licensors, and other materials contained on Ursa Rent (collectively, “content”) is intended solely for informational purposes and may not be used as a substitute for professional advice and/or information, as circumstances will vary from person to person. You should not act or rely upon this information without seeking professional advice. Do not attempt any of the suggested actions, solutions, remedies, or instructions found on this website without first consulting with a qualified professional. The materials are not intended to be nor do they constitute actionable professional advice. Transmissions of this information is not intended to create a professional-client relationship between Ursa Rent and you. The owners, editors, contributors, administrators, and other staff of Ursa Rent are not qualifies professionals, and are simply aggregating information found online for informational purposes only.</p>
                   <p class="text-muted">If you think you may have a medical emergency, call your doctor or 911 immediately. Ursa Rent does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned on the website. Reliance on any information provided by Ursa Rent, Ursa Rent employees, others appearing on the website at the invitation of Ursa Rent, or other visitors to the website is solely at your own risk.</p>
                   <br>
                                                          <h1 style="color: #474c52 !important;" class="text-muted">Fitness Disclosure</h1>
                   <p class="text-muted">Please read this Fitness disclosure before applying any of the information on the website.</p>
                   <p class="text-muted">We show information about Fitness and Nutritional advice. The information on this website such as text, graphics, images, and other materials created by Ursa Rent or obtained from Ursa Rent’s licensors, and other materials contained on Ursa Rent (collectively, “content”) is intended solely for informational purposes and may not be used as a substitute for professional advice and/or information, as circumstances will vary from person to person. You should not act or rely upon this information without seeking professional advice. Do not attempt any of the suggested actions, solutions, remedies, or instructions found on this website without first consulting with a qualified professional. The materials are not intended to be nor do they constitute actionable professional advice. If you use any of the information that we provide on Ursa Rent is at your own risk.</p>
                   <p class="text-muted">Regular exercise is not always without risk, even for healthy individuals. Certain types of exercise are riskier thank others and all exercise is risky for some individuals.</p>
                   <p class="text-muted">It’s the same with diet. Some dietary recommendations are healthy for the majority of people but potentially dangerous to others.</p>
                   <p class="text-muted">The exercises provided by Ursa Rent are for educational and entertainment purposes only, and is not to be interpreted as a recommendation for a specific treatment plan, product, or course of action. Exercise is not without risks, and this or any other exercise program may result in injury. They include but are not limited to: risk of injury, aggravation of a pre-existing condition, or adverse effect or over-exertion such as muscle strain, abnormal blood pressure, fainting, disorders of heartbeat, and very rare instances of heart attack. To reduce the risk of injury, before beginning this or any exercise program, please consult a healthcare provider for appropriate exercise prescription and safety precautions. The exercise instruction and advice presented are in no way intended as a substitute for medical consultation. Ursa Rent disclaims any liability from and in connection with this program. As with any exercise program, if at any point during your workout you begin to feel faint, dizzy, or have physical discomfort, you should stop immediately and consult a physician.</p>
                   <br>
                                                          <h1 style="color: #474c52 !important;" class="text-muted">Legal Disclosure</h1>
                   <p class="text-muted">The materials, any comments or information provided by Ursa Rent are for educational purposes only and nothing conveyed or provided should be considered legal, accounting or tax advice. You are responsible for whatever you may do with the information you obtain from Ursa Rent. As such, by visiting and using this website you acknowledge and agree that you have been assessed by a qualified law expert (i.e your attorney) who has given you consent to take part in any legal activity. Please contact your own attorney, accountant or tax professional with any specific questions you have related to the information provided that are of legal, accounting or tax nature.</p>
                   <br>
                                                          <h1 style="color: #474c52 !important;" class="text-muted">Financial Disclosure</h1>
                   <p class="text-muted">Gross merchandise sales is a non-GAAP metric. We use it to express the total demand across all of our web sites and stores. This number measures the dollar value of the order placed in the year before accruing for certain items such as returns, and it ignores certain timing cut-offs that are required by GAAP for revenue recognition purposes. If we were a public company, we would have to reconcile gross merchandise sales to the nearest GAAP metric (net sales), but we are currently a private company so the gross merchandise sales number should be viewed just as an interesting number that we want to share with our friends.</p>
                   <p class="text-muted">This document contains forward-looking statements that involve risks and uncertainties, as well as assumptions that, if they ever materialise or prove incorrect, could cause or results to differ materially from those expressed or implied by the forward-looking statements and assumptions. These risks and uncertainties include, but are not limited to, the risk of economic slowdown, the risk of over or underbuying, the risk of consumers not shopping online at our website at the rate we expected, the risk of supplier shortages, the risk of new or growing competition, the risk of natural or some other type of disaster affecting our fulfilment of historical operations or web servers, and the risk of the world generally coming to an end. All statements other than statements historical fact are statements that could be deemed forward-looking statements, including statements of expectation or belief; and any statement of assumptions underlying any of the foregoing. Ursa Rent assumes no obligation and does not intend to update these forward-looking statements.</p>
                   <br>
                                                          <h1 style="color: #474c52 !important;" class="text-muted">Educational Disclosure</h1>
                   <p class="text-muted">Any Information provided by Ursa Rent is for educational purposes only, and is not to be interpreted as a recommendation for a specific treatment plan, product, or course of action. Ursa Rent is a distributor and not a publisher of the content supplied by third parties; as such, Ursa Rent exercises no editorial control over such content and makes no warranty or representation as to the accuracy, reliability or currency of any information or educational content provided through or accessible via Ursa Rent. Without limiting the foregoing, Ursa Rent specifically disclaims all warranties and representations in any content transmitted on or in connection with Ursa Rent or on sites that may appear as links on Ursa Rent, or in the products provided as a part of, or otherwise in connection with, the Ursa Rent. No oral advice or written information given by Ursa Rent or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty.</p>
                   <br>
                                                          <h1 style="color: #474c52 !important;" class="text-muted">Advertising Disclosure</h1>
                   <p class="text-muted">This website may contain third party advertisements and links to third party sites. Ursa Rent does not make any representation as to the accuracy or suitability of any of the information contained in those advertisements or sites and does not accept any responsibility or liability for the conduct or content of those advertisements and sites and the offerings made by the third parties.</p>
                   <p class="text-muted">Advertising keeps Ursa Rent and many of the websites and services you use free of charge. We work hard to make sure that ads are safe, unobtrusive, and as relevant as possible.</p>
                   <p class="text-muted">Third party advertisements and links to other sites where goods or services are advertised are not endorsements or recommendations by Ursa Rent of the third party sites, goods or services. Ursa Rent takes no responsibility for the content of any of the ads, promises made, or the quality/reliability of the products or services offered in all advertisements.</p>
                   <br>
                                                          <h1 style="color: #474c52 !important;" class="text-muted">Testimonials Disclosure</h1>
                   <p class="text-muted">Any testimonials provided on this platform are opinions of those providing them. The information provided in the testimonials is not to be relied upon to predict results in your specific situation. The results you experience will be dependent on many factors including but not limited to your level of personal responsibility, commitment, and abilities, in addition to those factors that you and/or Ursa Rent may not be able to anticipate.</p>
                   <p class="text-muted">We will give honest testimonials to our visitors regardless of any discount. Any product or service that we test are individual experiences, reflecting real life experiences. The testimonials could be displayed on audio, text or video and are not necessarily representative of all of those who will use our products and/or services.</p>
                   <p class="text-muted">Ursa Rent does not guarantee the same results as the testimonials given on our platform. Testimonials presented on Ursa Rent are applicable to the individuals writing them, and may not be indicative of future success of any other individuals.</p>
                   <p class="text-muted">Please don’t hesitate to contact us if you would like to know more about testimonials, discounts, or any of the products/services that we review.</p>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Your Consent</h1>
                   <p class="text-muted">We've updated our&nbsp;Disclaimer&nbsp;to provide you with complete transparency into what is being set when you visit our site and how it's being used. By using our website, registering an account, or making a purchase, you hereby consent to our Disclaimer and agree to its terms.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Changes To Our Disclaimer</h1>
                   <p class="text-muted">Should we update, amend or make any changes to this document so that they accurately reflect our Service and policies. Unless otherwise required by law, those changes will be prominently posted here. Then, if you continue to use the Service, you will be bound by the updated Disclaimer. If you do not want to agree to this or any updated Disclaimer, you can delete your account.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Contact Us</h1>
                   <p class="text-muted">Don't hesitate to contact us if you have any questions regarding this Disclaimer.</p>
                   <ul class="text-muted">
                   <li>Via Phone Number:  (+62) 812-3957-09-46</li>                  </ul>
                </div>`,
        isHtml: true,
        isUpdateUrl: false
      },
      {
        title: 't-cookie-policy',
        id: 4,
        text: `<div class="responsive-margin">
                    <div style="text-align: center!important; margin: 0 auto!important;" class="col-12 col-md-8">
                        <h1 class="display-2 mb-3" style="color:#3f4554;">Cookie Policy</h1>
                        <p class="mb-0" style="color:#3f4554;">Updated at December 17th, 2023</p>
                    </div>
                    <br><br><br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Definitions and key terms</h1>
                   <p class="text-muted">To help explain things as clearly as possible in this Cookie Policy, every time any of these terms are referenced, are strictly defined as:</p>
                     <ul class="text-muted">
                       <li>Cookie:&nbsp;small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</li>
                                                                     <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Ursa Rent, that is responsible for your information under this Cookie Policy.</li>
                                              <li>Device:&nbsp;any internet connected device such as a phone, tablet, computer or any other device that can be used to visit Ursa Rent and use the services.</li>
                       <li>Personal Data: any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</li>
                       <li>Service: refers to the service provided by Ursa Rent as described in the relative terms (if available) and on this platform. </li>
                       <li>Third-party service:&nbsp;refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.</li>
                                              <li>Website: Ursa Rent’s site, which can be accessed via this URL: https://ursarent.com/</li>
                                                                     <li>You: a person or entity that is registered with Ursa Rent to use the Services.</li>
                     </ul>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Introduction</h1>
                   <p class="text-muted">This Cookie Policy explains how Ursa Rent and its affiliates (collectively "Ursa Rent", "we", "us", and "ours"), use cookies and similar technologies to recognize you when you visit our website, including without limitation https://ursarent.com/ and any related URLs, mobile or localized versions and related domains / sub-domains ("Websites"). It explains what these technologies are and why we use them, as well as the choices for how to control them.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">What is a cookie?</h1>
                   <p class="text-muted">A cookie is a small text file that is stored on your computer or other internet connected device in order to identify your browser, provide analytics, remember information about you such as your language preference or login information. They're completely safe and can't be used to run programs or deliver viruses to your device.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Why do we use cookies?</h1>
                   <p class="text-muted">We use first party and/or third party cookies on our website for various purposes such as:</p>
                   <ul class="text-muted">
                     <li>To facilitate the operation and functionality of our website;</li>
                     <li>To improve your experience of our website and make navigating around them quicker and easier;</li>
                     <li>To allow us to make a bespoke user experience for you and for us to understand what is useful or of interest to you;</li>
                     <li>To analyze how our website is used and how best we can customize it;</li>
                     <li>To identify future prospects and personalize marketing and sales interactions with it;</li>
                     <li>To facilitate the tailoring of online advertising to your interests.</li>
                   </ul>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">What type of cookies does Ursa Rent use?</h1>
                   <p class="text-muted">Cookies can either be session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires or you delete your cookies. Expiration dates are set in the cookies themselves; some may expire after a few minutes while others may expire after multiple years. Cookies placed by the website you’re visiting are called “first party cookies”.</p>
                   <p class="text-muted">Strictly Necessary cookies are necessary for our website to function and cannot be switched off in our systems. They are essential in order to enable you to navigate around the website and use its features. If you remove or disable these cookies, we cannot guarantee that you will be able to use our website.</p>
                   <p class="text-muted">We use the following types of cookies in our website:</p>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Essential Cookies</h1>
                   <p class="text-muted">We use essential cookies to make our website work. These cookies are strictly necessary to enable core functionality such as security, network management, your cookie preferences and accessibility. Without them you wouldn't be able to use basic services. You may disable these by changing your browser settings, but this may affect how the Websites function.</p>
                   <br>
                                                         <h1 style="color: #474c52 !important;" class="text-muted">Performance and Functionality Cookies</h1>
                   <p class="text-muted">These cookies are used to enhance the performance and functionality of our website but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the website as we would not be able to remember that you had logged in previously.</p>
                   <br>
                                                         <h1 style="color: #474c52 !important;" class="text-muted">Marketing Cookies</h1>
                   <p class="text-muted">These account-based marketing cookies enable us to identify future prospects and personalize sales and marketing interactions with them.</p>
                   <br>
                                                         <h1 style="color: #474c52 !important;" class="text-muted">Analytics and Customization Cookies</h1>
                   <p class="text-muted">These cookies collect information that is used to help us understand how our website is being used or how effective our marketing campaigns are, or to help us customize our website for you.</p>
                   <p class="text-muted">We use cookies served by Google Analytics to collect limited data directly from end-user browsers to enable us to better understand your use of our website. Further information on how Google collects and uses this data can be found at:&nbsp;https://www.google.com/policies/privacy/partners/. You can opt-out of all Google supported analytics on our Websites by visiting:&nbsp;https://tools.google.com/dlpage/gaoptout.</p>
                   <br>
                                                         <h1 style="color: #474c52 !important;" class="text-muted">Advertising Cookies</h1>
                   <p class="text-muted">These cookies collect information over time about your online activity on the website and other online services to make online advertisements more relevant and effective to you. This is known as interest-based advertising. They also perform functions like preventing the same ad from continuously reappearing and ensuring that ads are properly displayed for advertisers. Without cookies, it’s really hard for an advertiser to reach its audience, or to know how many ads were shown and how many clicks they received.</p>
                   <br>
                                                         <h1 style="color: #474c52 !important;" class="text-muted">Social Media Cookies</h1>
                   <p class="text-muted">These cookies are used when you share information using a social media sharing button or “like” button on our Site or you link your account or engage with our content on or through a social networking website such as Facebook, Twitter or Google+. The social network will record that you have done this. These cookies may also include certain code that has been placed on the platform to help track conversions from ads, optimize ads based on collected data, build targeted audiences for future ads, and remarket to qualified users who have already taken certain action on the platform.</p>
                   <br>
                                                         <h1 style="color: #474c52 !important;" class="text-muted">Third Party Cookies</h1>
                   <p class="text-muted">Some cookies that have been set on our website are not set on a first party basis by Ursa Rent. The Websites can be embedded with content from third parties to serve advertising. These third party service providers may set their own cookies on your web browser. Third party service providers control many of the performance and functionality, advertising, marketing and analytics cookies described above. We do not control the use of these third party cookies as cookies can only be accessed by the third party that originally set them.</p>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">How you can manage cookies?</h1>
                   <p class="text-muted">Most browsers allow you to control cookies through their 'settings' preferences. However, if you limit the ability of websites to set cookies, you may worsen your overall user experience, since it will no longer be personalized to you. It may also stop you from saving customized settings like login information. Browser manufacturers provide help pages relating to cookie management in their products.</p>
                   <p class="text-muted">Browser manufacturers provide help pages relating to cookie management in their products. Please see below for more information.</p>
                   <ul class="text-muted">
                     <li>Google Chrome</li>
                     <li>Internet Explorer</li>
                     <li>Mozilla Firefox</li>
                     <li>Safari (Desktop)</li>
                     <li>Safari (Mobile)</li>
                     <li>Android Browser</li>
                     <li>Opera</li>
                     <li>Opera Mobile</li>
                   </ul>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Blocking and disabling cookies and similar technologies</h1>
                   <p class="text-muted">Wherever you're located you may also set your browser to block cookies and similar technologies, but this action may block our essential cookies and prevent our website from functioning properly, and you may not be able to fully utilize all of its features and services. You should also be aware that you may also lose some saved information (e.g. saved login details, site preferences) if you block cookies on your browser. Different browsers make different controls available to you. Disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser, you should visit your browser's help menu for more information.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Changes To Our Cookie Policy</h1>
                   <p class="text-muted">We may change our Service and policies, and we may need to make changes to this Cookie Policy so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to this Cookie Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Cookie Policy. If you do not want to agree to this or any updated Cookie Policy, you can delete your account.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Your Consent</h1>
                   <p class="text-muted">By using our website, registering an account, or making a purchase, you hereby consent to our Cookie Policy and agree to its terms.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Contact Us</h1>
                   <p class="text-muted">Don't hesitate to contact us if you have any questions regarding our Cookie Policy.</p>
                   <ul class="text-muted">
                   <li>Via Phone Number:  (+62) 812-3957-09-46</li>                  </ul>
                </div>`,
        isHtml: true,
        isUpdateUrl: false
      },
      {
        title: 't-end-user-license-agreement',
        id: 5,
        text: `<div class="responsive-margin">
                    <div style="text-align: center!important; margin: 0 auto!important;" class="col-12 col-md-8">
                        <h1 class="display-2 mb-3" style="color:#3f4554;">End-User License Agreement</h1>
                        <p class="mb-0" style="color:#3f4554;">Updated at December 17th, 2023</p>
                    </div>
                    <br><br><br>
                    <p class="text-muted">Ursa Rent hereby grants you access to https://ursarent.com/ (“the website”) and invites you to purchase the services offered here.</p>
                    <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Definitions and key terms</h1>
                   <p class="text-muted">To help explain things as clearly as possible in this Eula, every time any of these terms are referenced, are strictly defined as:</p>
                     <ul class="text-muted">
                       <li>Cookie:&nbsp;small amount of data generated by a website/app and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</li>
                                              <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Mikes Hub Solutions, (Graha Merdeka Renon Unit 15, Jl. Merdeka, Desa/Kelurahan Sumerta Kelod, Kec. Denpasar Timur, Kota Denpasar, Provinsi Bali, Kode Pos: 80239), that is responsible for your information under this Eula.</li>
                                                                     <li>Country: where Ursa Rent or the owners/founders of Ursa Rent are based, in this case is Indonesia</li>
                       <li>Service: refers to the service provided by Ursa Rent as described in the relative terms (if available) and on this platform.</li>
                       <li>Third-party service:&nbsp;refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.</li>
                                              <li>Website: Ursa Rent’s site, which can be accessed via this URL: https://ursarent.com/</li>
                                                                      <li>You: a person or entity that is registered with Ursa Rent to use the Services.</li>
                     </ul>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Introduction</h1>
                   <p class="text-muted">This End User License Agreement (the “Agreement”) is a binding agreement between you (“End User”,“you” or “your”) and Mikes Hub Solutions (“Company”, “we”, “us” or “our”). This Agreement governs the relationship between you and us, and your use of the Company Ursa Rent. Throughout this Agreement, End User and Company may each be referred to as a “Party” or collectively, the “Parties”.</p>
                   <p class="text-muted">If you are using the website on behalf of your employer or other entity (an “Organisation”) for whose benefit you utilise the website or who owns or otherwise controls the means through which you utilise or access the website, then the terms “End User”, “you”, and “your” shall apply collectively to you as an individual and to the Organisation. If you use, or purchase a license or to, the website on behalf of an Organisation, you hereby acknowledge, warrant, and covenant that you have the authority to 1) purchase a license to the website on behalf of the Organisation; 2) bind the Organisation to the terms of this Agreement.</p>
                   <p class="text-muted">By downloading, installing, accessing, or using the website you: (a) affirm that you have all of the necessary permissions and authorisations to access and use the website; (b) if you are using the website pursuant to a license purchased by an organisation, that you are authorised by that organisation to access and use the website(c) acknowledge that you have read and that you understand this agreement; (d) represent that you are of sound mind to enter into a binding agreement; and (e) accept and agree to be legally bound by the terms and conditions of this agreement.</p>
                   <p class="text-muted">If you do not agree to these terms, do not download, install, access, or use the software. if you have already downloaded the software, delete it from your computing device.</p>
                   <p class="text-muted">The Application is licensed, not sold, to you by Ursa Rent for use strictly in accordance with the terms of this Agreement.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">License</h1>
                   <p class="text-muted">Subject to the terms of this Agreement and, if applicable, those terms provided in the License Agreement, Ursa Rent grants you a limited, non-exclusive, perpetual, revocable, and non-transferable license to:</p>
                   <p class="text-muted">(a) download, install and use the Software on one (1) Computing Device per single user license that you have purchased and been granted. If you have multiple Computer Devices in which you wish to use the Software, you agree to acquire a license for the number of devices you intend to use;</p>
                   <p class="text-muted">(b) access, view, and use on such Computing Device the End User Provided Materials made available in or otherwise accessible through the Software, strictly in accordance with this Agreement, and any other terms and conditions applicable to such End User Provided Materials;</p>
                   <p class="text-muted">(c) install and use the trial version of the Software on any number of Computing Devices for a trial period of fifteen (15) unique days after installation.</p>
                   <p class="text-muted">(d) receive updates and new features that become available during the one (1) year period from the date on which you purchased the license to the Software.</p>
                   <br>
                    <h1 style="color: #474c52 !important;" class="text-muted">Restrictions</h1>
                    <p class="text-muted">You agree not to, and you will not permit others to:</p>
                    <ul class="text-muted">
                      <li>License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the Application or make the Application available to any third party.</li>
                      <li>Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the Application.</li>
                      <li>Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of Ursa Rent or its affiliates, partners, suppliers or the licensors of the Application.</li>
                    </ul>
                    <br>
                                                                                <h1 style="color: #474c52 !important;" class="text-muted">Intellectual Property</h1>
                    <p class="text-muted">All intellectual property rights, including copyrights, patents, patent disclosures and inventions (whether patentable or not), trademarks service marks, trade secrets, know-how and other confidential information, trade dress, trade names, logos, corporate names and domain names, together with all of the good will associated there with, derivative works and all other rights (collectively, “Intellectual Property Rights”) that are part of the Software that are otherwise owned by Ursa Rent shall always remain the exclusive property of Ursa Rent (or of its suppliers or licensors, if and when applicable). Nothing in this Agreement grants you (or any Organisation) a license to Ursa Rent’s Intellectual Property Rights.</p>
                    <p class="text-muted">You agree that this is Agreement conveys a limited license to use Ursa Rent’s Intellectual Property Rights, solely as part of the Software (and not independently of it), and only for the effective Term of the license granted to you hereunder. Accordingly, your use of any of Ursa Rent’s Intellectual Property Rights independently of the Software or outside the scope of this Agreement shall be considered an infringement of Ursa Rent’s Intellectual Property Rights. This shall not limit, however, any claim Ursa Rent may have for a breach of contract in the event you breach a term or condition of this Agreement. You shall use the highest standard of care to safeguard all Software (including all copies thereof) from infringement, misappropriation, theft, misuse or unauthorised access. Except as expressly granted in this Agreement, Ursa Rent reserves and shall retain all rights, title, and interest in the Software, including all copyrights and copyrightable subject matter, trademarks and trademark able subject matter, patents and patentable subject matter, trade secrets, and other intellectual property rights, registered, unregistered, granted, applied-for, or both now in existence or that may be created, relating to the thereto.</p>
                    <p class="text-muted">You (or the Organisation, if and as applicable) shall retain ownership of all Intellectual Property Rights in and to the work products that you create through or with the assistance of the Software.</p>
                    <br>
                    <h1 style="color: #474c52 !important;" class="text-muted">Your Suggestions</h1>
                    <p class="text-muted">Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you to Ursa Rent with respect to the Application shall remain the sole and exclusive property of Ursa Rent.</p>
                    <p class="text-muted">Ursa Rent shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any way without any credit or any compensation to you.</p>
                    <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Modifications to Application</h1>
                   <p class="text-muted">Ursa Rent reserves the right to modify, suspend or discontinue, temporarily or permanently, the Application or any service to which it connects, with or without notice and without liability to you.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Updates to Application</h1>
                   <p class="text-muted">Ursa Rent may from time to time provide enhancements or improvements to the features/ functionality of the Application, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").</p>
                   <p class="text-muted">Updates may modify or delete certain features and/or functionalities of the Application. You agree that Ursa Rent has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the Application to you. </p>
                   <p class="text-muted">You further agree that all Updates will be (i) deemed to constitute an integral part of the Application, and (ii) subject to the terms and conditions of this Agreement. </p>
                   <br>
                                       <h1 style="color: #474c52 !important;" class="text-muted">Third-Party Services</h1>
                   <p class="text-muted">The Application may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services").</p>
                   <p class="text-muted">You acknowledge and agree that Ursa Rent shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Ursa Rent does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.</p>
                   <p class="text-muted">Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.</p>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Term and Termination</h1>
                   <p class="text-muted">This Agreement shall remain in effect until terminated by you or Ursa Rent.</p>
                   <p class="text-muted">Ursa Rent may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.</p>
                   <p class="text-muted">This Agreement will terminate immediately, without prior notice from Ursa Rent, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all copies thereof from your computer.</p>
                   <p class="text-muted">Upon termination of this Agreement, you shall cease all use of the Application and delete all copies of the Application from your computer.</p>
                   <p class="text-muted">Termination of this Agreement will not limit any of Ursa Rent’s rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Indemnification</h1>
                   <p class="text-muted">You agree to indemnify, defend and hold harmless Ursa Rent and its officers, directors, employees, agents, affiliates, successors, and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys’ fees, arising from or relating to: i) your use or misuse of the Software; ii) your failure to comply with any applicable law, regulation, or government directive; iii) your breach of this Agreement; or iv) your agreement or relationship with an Organisation (if applicable) or any third party. Furthermore, you agree that Ursa Rent assumes no responsibility for the information or content you submit or make available through this Software or the content that is made available to you by third parties.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">No Warranties</h1>
                   <p class="text-muted">The Application is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, Ursa Rent, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Application, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, Ursa Rent provides no warranty or undertaking, and makes no representation of any kind that the Application will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
                   <p class="text-muted">Without limiting the foregoing, neither Ursa Rent nor any Ursa Rent’s provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Application, or the information, content, and materials or products included thereon; (ii) that the Application will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Application; or (iv) that the Application, its servers, the content, or e-mails sent from or on behalf of Ursa Rent are free of viruses, scripts, trojan horses, worms, malware, time bombs or other harmful components.</p>
                   <p class="text-muted">Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Limitation of Liability</h1>
                   <p class="text-muted">Notwithstanding any damages that you might incur, the entire liability of Ursa Rent and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you for the Application.</p>
                   <p class="text-muted">To the maximum extent permitted by applicable law, in no event shall Ursa Rent or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the Application, third-party software and/or third-party hardware used with the Application, or otherwise in connection with any provision of this Agreement), even if Ursa Rent or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
                   <p class="text-muted">Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Severability</h1>
                   <p class="text-muted">If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Waiver</h1>
                   <p class="text-muted">No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under this Agreement shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under this Agreement preclude further exercise of that or any other right granted herein. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Amendments to this Agreement</h1>
                   <p class="text-muted">Ursa Rent reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                   <p class="text-muted">By continuing to access or use our Application after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Application.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Governing Law</h1>
                   <p class="text-muted">The laws of Indonesia, excluding its conflicts of law rules, shall govern this Agreement and your use of the Application. Your use of the Application may also be subject to other local, state, national, or international laws. </p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Changes to this agreement</h1>
                   <p class="text-muted">We reserve the exclusive right to make changes to this Agreement from time to time. Your continued access to and use of the website constitutes your agreement to be bound by, and your acceptance of, the terms and conditions posted at such time. You acknowledge and agree that you accept this Agreement (and any amendments thereto) each time you load, access, or use the website. Therefore, we encourage you to review this Agreement regularly.</p>
                   <p class="text-muted">If, within thirty (30) days of us posting changes or amendments to this Agreement, you decide that you do not agree to the updated terms, you may withdraw your acceptance to the amended terms by providing us with written notice of your withdrawal. Upon providing us with the written notice of the withdrawal of your acceptance, you are no longer authorised to access or use the website.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">No Employment or Agency Relationship</h1>
                   <p class="text-muted">No provision of this Agreement, or any part of relationship between you and Ursa Rent, is intended to create nor shall they be deemed or construed to create any relationship between you and Ursa Rent other than that of and end user of the website and services provided.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Equitable Relief</h1>
                   <p class="text-muted">You acknowledge and agree that your breach of this Agreement would cause Ursa Rent irreparable harm for which money damages alone would be inadequate. In addition to damages and any other remedies to which Ursa Rent may be entitled, you acknowledge and agree that we may seek injunctive relief to prevent the actual, threatened or continued breach of this Agreement.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Headings</h1>
                   <p class="text-muted">The headings in this Agreement are for reference only and shall not limit the scope of, or otherwise affect, the interpretation of this Agreement.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Geographic Restrictions</h1>
                   <p class="text-muted">The Company is based in Indonesia and provided for access and use primarily by persons located in Indonesia, and is maintains compliance with Indonesia laws and regulations. If you use the website from outside Indonesia, you are solely and exclusively responsible for compliance with local laws.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Limitation of Time to File Claims</h1>
                   <p class="text-muted">Any cause of action or claim you may have arising out of or relating to this agreement or the website must be commenced within one (1) year after the cause of action accrues, otherwise, such cause of action or claim is permanently barred.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Entire Agreement</h1>
                   <p class="text-muted">The Agreement constitutes the entire agreement between you and Ursa Rent regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements between you and Ursa Rent.</p>
                   <p class="text-muted">You may be subject to additional terms and conditions that apply when you use or purchase other Ursa Rent’s services, which Ursa Rent will provide to you at the time of such use or purchase.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Contact Us</h1>
                   <p class="text-muted">Don't hesitate to contact us if you have any questions about this Agreement.</p>
                   <ul class="text-muted">
                   <li>Via Phone Number:  (+62) 812-3957-09-46</li>                  </ul>
                </div>`,
        isHtml: true,
        isUpdateUrl: false
      },
      {
        title: 't-return-refund-policy',
        id: 6,
        text: `<div class="responsive-margin">
                    <div style="text-align: center!important; margin: 0 auto!important;" class="col-12 col-md-8">
                        <h1 class="display-2 mb-3" style="color:#3f4554;">Return &amp; Refund Policy</h1>
                        <p class="mb-0" style="color:#3f4554;">Updated at December 17th, 2023</p>
                    </div>
                    <br><br><br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Definitions and key terms</h1>
                   <p class="text-muted">To help explain things as clearly as possible in this Return &amp; Refund Policy, every time any of these terms are referenced, are strictly defined as:</p>
                     <ul class="text-muted">
                                                                     <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Ursa Rent, that is responsible for your information under this Return &amp; Refund Policy.</li>
                                              <li>Customer:&nbsp;refers to the company, organization or person that signs up to use the Ursa Rent Service to manage the relationships with your consumers or service users.</li>
                       <li>Device:&nbsp;any internet connected device such as a phone, tablet, computer or any other device that can be used to visit Ursa Rent and use the services.</li>
                       <li>Service: refers to the service provided by Ursa Rent as described in the relative terms (if available) and on this platform. </li>
                                              <li>Website: Ursa Rent’s site, which can be accessed via this URL: https://ursarent.com/</li>
                                                                     <li>You: a person or entity that is registered with Ursa Rent to use the Services.</li>
                     </ul>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Return &amp; Refund Policy</h1>
                   <p class="text-muted">Thanks for shopping at Ursa Rent. We appreciate the fact that you like to buy the stuff we build. We also want to make sure you have a rewarding experience while you’re exploring, evaluating, and purchasing our products.</p>
                   <p class="text-muted">As with any shopping experience, there are terms and conditions that apply to transactions at Ursa Rent. We’ll be as brief as our attorneys will allow. The main thing to remember is that by placing an order or making a purchase at Ursa Rent, you agree to the terms set forth below along with Ursa Rent’s&nbsp;Privacy Policy.</p>
                   <p class="text-muted">If there’s something wrong with the product/service you bought, or if you are not happy with it, you have 1 week    to issue a refund and return your product/service.</p>
                   <p class="text-muted">If you would like to return a product, the only way would be if you follow the next guidelines:</p>
                   <ul class="text-muted">
                                           <li>The package needs to come back with the original receipt of the purchase.</li>
                                                                <li>The product has to be in the same packaging we sent it.</li>
                                                                <li>The product has to be damage free, if we find any damage on the product we will cancel your refund immediately.</li>
                                        </ul>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Refunds</h1>
                   <p class="text-muted">We at&nbsp;Ursa Rent,&nbsp;commit ourselves to serve our customers with the best products. Every single product that you choose is thoroughly inspected, checked for defects and packaged with utmost care. We do this to ensure that you fall in love with our products.</p>
                   <p class="text-muted">Sadly, there are times when we may not have the product(s) that you choose in stock, or may face some issues with our inventory and quality check. In such cases, we may have to cancel your order. You will be intimated about it in advance so that you don't have to worry unnecessarily about your order. If you have purchased via Online payment (not Cash on Delivery), then you will be refunded once our team confirms your request.</p>
                   <p class="text-muted">We carry out thorough quality check before processing the ordered item. We take utmost care while packing the product. At the same time we ensure that the packing is good such that the items won’t get damaged during transit. Please note that Ursa Rent is not liable for damages that are caused to the items during transit or transportation.</p>
                   <p class="text-muted">We will revise your returned product as soon as we receive it and if it follows the guidelines addressed above, we will proceed to issue a refund of your purchase. Your refund may take a couple of days to process but you will be notified when you receive your money.</p>
                   <br>
                                      <h1 style="color: #474c52 !important;" class="text-muted">Shipping</h1>
                   <p class="text-muted">Ursa Rent is not responsible for return shipping costs. Every shipping has to be paid by the customer, even if the item had free shipping, the customer has to pay for the shipping in return.</p>
                   <br>
                                                                                                                   <h1 style="color: #474c52 !important;" class="text-muted">Your Consent</h1>
                   <p class="text-muted">By using our website, registering an account, or making a purchase, you hereby consent to our Return &amp; Refund Policy and agree to its terms.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Changes To Our Return &amp; Refund Policy</h1>
                   <p class="text-muted">Should we update, amend or make any changes to this document so that they accurately reflect our Service and policies. Unless otherwise required by law, those changes will be prominently posted here. Then, if you continue to use the Service, you will be bound by the updated Return &amp; Refund Policy. If you do not want to agree to this or any updated Return &amp; Refund Policy, you have the right to delete your account.</p>
                   <br>
                   <h1 style="color: #474c52 !important;" class="text-muted">Contact Us</h1>
                   <p class="text-muted">If, for any reason, You are not completely satisfied with any good or service that we provide, don't hesitate to contact us and we will discuss any of the issues you are going through with our product.</p>
                   <ul class="text-muted">
                   <li>Via Phone Number:  (+62) 812-3957-09-46</li>                  </ul>
                </div>`,
        isHtml: true,
        isUpdateUrl: false
      }
    ]
    return {
      items
    }
  }
}
</script>

<style scoped lang="scss">
.info {
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile-max-width) {
    align-items: center;
  }
}

.info-title {
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding: 0 30px 0 0;

  @media (max-width: $mobile-max-width) {
    margin-top: 52px;
    padding-right: 0;
  }
}

.info-collapse {
  margin-top: 24px;
}
</style>
