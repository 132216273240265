<template>
  <div class="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">

      <div class="auth-page-content overflow-hidden p-0">
          <b-container>
              <b-row class="justify-content-center">
                  <b-col xl="7" lg="8">
                      <div class="text-center">
                          <img src="@/assets/images/error400-cover.png" alt="error img" class="img-fluid">
                          <div class="mt-3">
                              <h3 class="text-uppercase">{{ $t("t-page-not-found") }} 😭</h3>
                              <p class="text-muted mb-4">{{ $t("t-page-not-available-text") }}</p>
                              <router-link to="/" class="btn btn-success"><i class="mdi mdi-home me-1"></i>{{ $t("t-back-to-home") }}</router-link>
                          </div>
                      </div>
                  </b-col>
              </b-row>
          </b-container>
      </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>
h1 {
  font-size: 6em;
}

p {
  font-size: 2em;
}

.text-muted {

  color: #2c1d2a;
}
</style>
