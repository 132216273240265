<template>
    <div v-if="isLoading" class="spinner-container">
      <AtomSpinner class="overlay" :size="size" :color="color" />
    </div>
  </template>

<script>
import { AtomSpinner } from 'epic-spinners'

export default {
  components: {
    AtomSpinner
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    size: {
      type: Number,
      default: 200
    },
    color: {
      type: String,
      default: '#fc9544'
    }
  }
}
</script>

  <style scoped>
 .spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10000;
}
  </style>
