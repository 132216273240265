import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import BootstrapVue3 from 'bootstrap-vue-next'
import * as Sentry from '@sentry/vue'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/scss/fonts.scss'
import posthog from 'posthog-js'

import { createPinia } from 'pinia'

const app = createApp(App)
const pinia = createPinia()
posthog.init('phc_FJEquph9rHCUIGv86in1RDkIrg2ivikwFJGXSZ8WNsk', { api_host: 'https://app.posthog.com' })

Sentry.init({
  app,
  dsn: 'https://ef8bc74ae5c4c539a9a6d9bb16212a04@o4505782950297600.ingest.sentry.io/4505792064258048',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(router)
  .use(pinia)
  .use(BootstrapVue3)
  .use(i18n)
  .use(Toast)
  .mount('#app')
