<template>
  <div class="company-block">
    <img class="company-logo" :src="this.logo.replace('https', 'http')" :alt="this.companyName" />
    <div>
      <strong>{{ this.companyName }}</strong>
      <div>
        <img src="@/assets/images/icons/star.svg" alt="rating" />
        {{ this.companyRating === 0 ? this.$t('t-not-enough-rating') : this.companyRating }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['logo', 'companyName', 'companyRating']
}
</script>

<style scoped>
.company-block {
  display: flex;
  gap: 16px;
  font-size: 14px;
  overflow: hidden;
}
.company-logo {
  width: 40px;
  height: 40px;
}
</style>
