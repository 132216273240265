<template>
  <div class="switch-container" @click="this.toggleCheckbox">
    <label class="switch-label" :class="{ checked: this.selected }">
      <span class="switch-inner"></span>
      <span class="switch-switch"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: ['serviceId', 'selected'],
  emits: ['toggleCheckbox'],
  methods: {
    toggleCheckbox () {
      this.$emit('toggleCheckbox', this.serviceId)
    }
  },
  computed: {
    checked () {
      console.log(this.selected)
      return this.selected ? 'checked' : undefined
    }
  }
}
</script>
<style scoped>
.switch-container {
  display: inline-block;
}
.switch-checkbox {
  display: none;
}
.switch-label {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 26px;
  border-radius: 13px;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}
.switch-label .switch-inner {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.3s;
}
.switch-label .switch-switch {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 3px;
  left: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: left 0.3s;
}
.switch-label.checked {
  background-color: #fc9544;
}
.switch-label.checked .switch-inner {
  left: 28px;
}
.switch-label.checked .switch-switch {
  left: 27px;
}
</style>
