<template>
  <div class="scooter-wrapper" :class="{ 'not-available': minimal_available_days }">
    <img
      class="not-available-image"
      v-if="minimal_available_days"
      src="@/assets/not-available.png"
      alt="Not available"
    />
    <img class="scooter-image" :src="img_url.replace('https', 'http')" :alt="name" />
    <div class="scooter-info">
      <div>
        <div class="scooter-brand">{{ name }}</div>
        <p class="similar" v-if="!is_custom">{{ $t('t-or-similar') }}</p>
      </div>
      <div class="scooter-text">{{ $t('t-category') }}: {{ category }}</div>
      <div class="scooter-text">{{ $t('t-transmission') }}: {{ transmission }}</div>
      <div class="scooter-description" v-html="description"></div>
    </div>
    <div class="book-block">
      <div class="price-block">
        <div class="price">{{ priceConverter(price) }} {{ currencySymbol }}</div>
        <div :class="{ 'small-text': true, 'align-text-end': minimal_available_days }">
          {{ $t('t-price-for') }} {{ order_days }} {{ $t('t-days') }}
          <div class="minimal-days-error" v-if="minimal_available_days">
            {{
              $t('t-error-not-available-for-rent-need-more-days', {
                days: minimal_available_days
              })
            }}
          </div>
        </div>
      </div>
      <CompanyProfile
        :logo="company_logo"
        :companyName="company_name"
        :companyRating="company_rating"
      />
      <div :class="{ 'disable-block': !!minimal_available_days, 'buttons-block': true }">
        <button :disabled="minimal_available_days" class="book-button" @click="viewDeal">
          {{ $t('t-book-scooter') }}
        </button>
        <button @click="onLearnMoreClick">{{ $t('t-learn-more') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useCurrencyStore } from '@/stores/currency'
import { storeToRefs } from 'pinia'
import CompanyProfile from '@/components/CompanyProfile.vue'

export default {
  props: [
    'name',
    'category',
    'transmission',
    'description',
    'price',
    'company_name',
    'company_logo',
    'company_rating',
    'img_url',
    'order_days',
    'id',
    'minimal_available_days',
    'is_custom'
  ],
  emits: ['viewDeal'],
  components: {
    CompanyProfile
  },
  setup () {
    const currencyStore = useCurrencyStore()
    const { currencySymbol } = storeToRefs(currencyStore)

    return {
      currencySymbol
    }
  },
  methods: {
    priceConverter (price) {
      return `${price
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
    },
    viewDeal () {
      this.$emit('viewDeal', this.id)
    },
    onLearnMoreClick () {
      this.$emit('show-learn-more', this.id)
    }
  }
}
</script>

<style scoped>
.scooter-wrapper {
  display: flex;
  gap: 24px;
  color: #0e0e0e;
  font-family: Raleway;
  position: relative;
  justify-content: space-between;
  transition: opacity 0.3s ease;
}
.scooter-wrapper.not-available {
  opacity: 0.5;
  pointer-events: none;
}
.not-available-image {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  mix-blend-mode: multiply;
}
.scooter-image {
  width: 242px;
  max-height: 242px;
}
.scooter-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: 400;
  width: 340px;
}
.scooter-brand {
  font-size: 24px;
}
.scooter-text {
  font-size: 16px;
  line-height: 140%;
}
.small-text {
  font-size: 12px;
}
.align-text-end {
  text-align: end;
}
.minimal-days-error {
  color: #d9432b;
  width: 90px;
}
.scooter-description {
  max-height: 45px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.book-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.price {
  font-size: 24px;
  font-weight: 600;
}
.buttons-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.buttons-block button {
  outline: none;
  border-radius: 100px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  background-color: #fff;
  padding: 10px 25px;
  border: 1px solid rgb(118, 118, 118);
}
.disable-block .book-button {
  color: #f2f2f2;
  border: 1px solid #f2f2f2;
}
.disable-block .book-button:hover {
  background: #fff;
}

.buttons-block .book-button {
  border: 1px solid #fc9544;
  color: #fc9544;
  transition: background-color 0.3s;
}

.book-button:hover {
  background: #fff5ed;
}
@media (max-width: 1200px) {
  .scooter-image {
    width: 120px;
    max-height: 120px;
  }
  .scooter-info {
    width: 200px;
  }
}
@media (max-width: 1000px) {
  .scooter-wrapper {
    flex-direction: column;
  }
  .scooter-image {
    width: 240px;
    max-height: 240px;
  }
  .scooter-info {
    width: 100%;
  }
  .price-block {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .scooter-image {
    position: absolute;
    top: 55px;
    right: 0;
    width: 140px;
    max-height: 140px;
    overflow-clip-margin: content-box;
    overflow: clip;
  }
  .scooter-text,
  .scooter-description {
    width: 50%;
  }
}
</style>
