<template>
  <div class="wrapper">
    <h2 class="title">{{ $t('t-additional-services') }}</h2>
    <div class="service-list">
      <ServiceRecord
        v-for="service in limitedServices"
        :key="service.id"
        :id="service.id"
        :name="service.name"
        :icon="service.photo_url"
        :priceOneTime="service.price_one_time"
        :pricePerDay="service.price_per_day"
        :count="service.count"
        :maxCount="service.max_count"
        :selected="service.selected"
        @set-count="this.setCount"
        @toggle-checkbox="this.toggleCheckbox"
      />
    </div>
    <button v-if="this.services.length > 3" @click="this.toggleExpanded" class="more">
      {{ this.expanded ? 'Show less' : 'Show more' }}
    </button>
  </div>
</template>

<script>
import ServiceRecord from './ServiceRecord.vue'

export default {
  props: ['services'],
  emits: ['setCount', 'toggleCheckbox'],
  data () {
    return {
      expanded: false
    }
  },
  components: {
    ServiceRecord
  },
  methods: {
    setCount (count, id) {
      this.$emit('setCount', count, id)
    },
    toggleCheckbox (id) {
      this.$emit('toggleCheckbox', id)
    },
    toggleExpanded () {
      this.expanded = !this.expanded
    }
  },
  computed: {
    limitedServices () {
      return !this.expanded ? this.services.slice(0, 3) : this.services
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 24px;
  border-radius: 32px;
  border: 1px solid #d4d1d0;
  font-family: Raleway;
}
.title {
  color: #2c1d2a;
  font-size: 24px;
  font-weight: 600;
}
.service-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.more {
  display: flex;
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  outline: none;
  border-radius: 100px;
  border: 0.5px solid #2c1d2a;
  color: #2c1d2a;
  font-size: 18px;
  font-weight: 600;
  background-color: #fff;
}
</style>
