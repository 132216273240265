<template>
  <div class="wrapper-confirmation">
    <h2 class="title">{{ statusTitle }}</h2>
    <div>{{ statusText }}</div>
    <div :style="{ 'background-color': statusBackground }" class="status-text">
      {{ $t(this.status) }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['status'],
  computed: {
    statusTitle () {
      switch (this.status) {
        case 'WAITING_APPROVE':
          return this.$t('WAITING_APPROVE')
        case 'CLIENT_USE':
          return this.$t('CLIENT_USE')
        case 'IN_PROGRESS':
          return this.$t('IN_PROGRESS')
        case 'WAITING_PAYMENT':
          return this.$t('WAITING_PAYMENT')
        case 'WAITING_VEHICLE':
          return this.$t('WAITING_VEHICLE')
        case 'PAYMENT_NOT_CONFIRMED':
          return this.$t('PAYMENT_NOT_CONFIRMED')
        case 'CANCELLED':
          return this.$t('CANCELLED')
        case 'OPEN':
          return this.$t('OPEN')
        case 'RETURN_MONEY':
          return this.$t('RETURN_MONEY')
        case 'FINISHED':
          return this.$t('FINISHED')
        default:
          return ''
      }
    },
    statusText () {
      switch (this.status) {
        case 'WAITING_APPROVE':
          return this.$t('t-text-about-confirmation-waiting-approve')
        case 'CLIENT_USE':
          return this.$t('t-text-about-confirmation-client-use')
        case 'IN_PROGRESS':
          return this.$t('t-text-about-confirmation-in-progress')
        case 'WAITING_PAYMENT':
          return this.$t('t-text-about-confirmation-waiting-payment')
        case 'WAITING_VEHICLE':
          return this.$t('t-text-about-confirmation-waiting-vehicle')
        case 'PAYMENT_NOT_CONFIRMED':
          return this.$t('t-text-about-confirmation-payment-not-confirmed')
        case 'CANCELLED':
          return this.$t('t-text-about-confirmation-cancelled')
        case 'OPEN':
          return this.$t('t-text-about-confirmation-open')
        case 'RETURN_MONEY':
          return this.$t('t-text-about-confirmation-return-money')
        case 'FINISHED':
          return this.$t('t-text-about-confirmation-finished')
        default:
          return ''
      }
    },
    statusBackground () {
      switch (this.status) {
        case 'WAITING_APPROVE':
        case 'CLIENT_USE':
        case 'IN_PROGRESS':
        case 'WAITING_PAYMENT':
        case 'WAITING_VEHICLE':
          return '#F6F1BD'
        case 'PAYMENT_NOT_CONFIRMED':
        case 'CANCELLED':
          return 'rgba(224, 82, 40, 0.30)'
        case 'OPEN':
          return 'rgba(86, 204, 242, 0.30)'
        case 'RETURN_MONEY':
          return '#FFF5ED'
        case 'FINISHED':
          return '#D1EAB8'
        default:
          return ''
      }
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.wrapper-confirmation {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 24px;
  border-radius: 32px;
  border: 1px solid #d4d1d0;
  font-family: Raleway;
}
.title {
  color: #2c1d2a;
  font-size: 24px;
  font-weight: 600;
}
.status-text {
  text-transform: capitalize;
  padding: 4px 16px;
  border-radius: 100px;
  align-self: flex-start;
}
</style>
