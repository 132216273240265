<template>
  <input
    class="pickr"
    :placeholder="$t('t-pick-up-place')"
    v-model="searchStore.pickUpPlace"
    @input="searchStore.isPlaceSelected=false"
    ref="autocompleteInput"
  />
</template>

<script>
import { ref, watchEffect } from 'vue'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import { useSearchStore } from '@/stores/search'
import { useUserGeolocationStore } from '@/stores/user-geolocation'
import { getPlaces } from '@/utils/googleMaps'

export default {
  setup () {
    const searchStore = useSearchStore()
    const userGeolocationStore = useUserGeolocationStore()

    const autocompleteInput = ref(null)
    const autocomplete = ref(null)

    const validationRules = {
      pickUpPlace: { required }
    }

    const v$ = useVuelidate(validationRules, searchStore)

    watchEffect(async () => {
      const googlePlaces = await getPlaces()
      // Используем ref DOM элемента напрямую
      if (autocompleteInput.value) {
        autocomplete.value = new googlePlaces.Autocomplete(autocompleteInput.value)

        autocomplete.value.addListener('place_changed', () => {
          const place = autocomplete.value.getPlace()
          if (place.geometry) {
            userGeolocationStore.lat = place.geometry.location.lat()
            userGeolocationStore.lng = place.geometry.location.lng()
            searchStore.pickUpPlace = place.formatted_address
            searchStore.isPlaceSelected = true
          } else {
            window.alert('Unable to get location for the entered address.')
          }
        })
      }
    })

    return {
      searchStore,
      autocompleteInput,
      autocomplete,
      v$
    }
  }
}
</script>

<style scoped lang="scss">
.pickr {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  padding: 12px 24px 12px 24px;
  border: 1px solid #d4d1d0;
  border-radius: 100px;
  overflow-x: scroll;

  &:hover,
  &:focus {
    border-color: #fc9544;
    outline: none;
    caret-color: #fc9544;
  }
}

.is-invalid {
  border: 1px solid #D9432B;
}
</style>
