<template>
  <div>
    <header class="header">
      <button
        class="open-nav-btn mobile-only"
        type="button"
        @click="showSideNavBar"
        aria-expanded="false"
        aria-controls="container"
      >
        <span class="visually-hidden">{{ $t('t-open-menu') }}</span>
      </button>
      <logo class="mobile-only" />
      <div class="container multi-collapse" id="container">
        <div v-if="this.showOverlay" class="overlay multi-collapse"></div>
        <button
          class="close-nav-btn mobile-only"
          type="button"
          aria-expanded="false"
          aria-controls="container"
          @click="hideSideNavBar"
        >
          <span class="visually-hidden">{{ $t('t-open-menu') }}</span>
        </button>
        <nav class="navigation">
          <logo class="logo" />
          <ul class="navigation-list" id="navigation-list">
            <!-- <li>
              <router-link to="/about" class="navigation-item">{{ $t('t-about-us') }}</router-link>
            </li> -->
            <li>
              <router-link @click="hideSideNavBar" to="/" class="navigation-item">{{
                $t('t-home page')
              }}</router-link>
            </li>
            <li>
              <router-link @click="hideSideNavBar" to="/faq" class="navigation-item">{{
                $t('t-faq')
              }}</router-link>
            </li>
            <!-- <li>
              <router-link to="/contacts" class="navigation-item">{{
                $t('t-contact')
              }}</router-link>
            </li> -->
          </ul>
        </nav>
        <ul class="menu" id="navigation-list">
          <li>
            <ul class="menu-btns">
              <li>
                <currency />
              </li>
              <li>
                <languages />
              </li>
            </ul>
          </li>
          <li>
            <router-link
              v-slot="{ navigate }"
              to="/manage-booking"
              custom
              data-key="t-manage-booking"
            >
              <button class="btn-primary" @click="navigate">
                {{ $t('t-manage-booking') }}
              </button>
            </router-link>
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>

<script>
import Logo from './components/Logo.vue'
import Languages from './components/Languages.vue'
import Currency from './components/Currency.vue'

export default {
  components: {
    Logo,
    Languages,
    Currency
  },
  data () {
    return {
      showOverlay: false
    }
  },
  methods: {
    showSideNavBar () {
      const sideNavBar = document.querySelector('.multi-collapse')
      sideNavBar.classList.add('show')
      this.showOverlay = true
    },
    hideSideNavBar () {
      const sideNavBar = document.querySelector('.multi-collapse')
      sideNavBar.classList.remove('show')
      this.showOverlay = false
    }
  }
}
</script>

<style scoped lang="scss">
.overlay {
  width: 27vw;
  height: 110vh;
  position: absolute;
  z-index: 5;
  left: 0;
  top: -60px;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translate(-100%, 0);
}
.header {
  position: relative;
  z-index: 6;

  @media (max-width: $mobile-max-width) {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 92px;
    padding-top: 24px;
  }
}

.container {
  display: grid;
  grid-template-columns: calc(50% - 97px) 193px calc(50% - 97px);
  grid-template-areas: 'nav nav menu';
  min-height: 104px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;

  @media (max-width: $mobile-max-width) {
    position: fixed;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 57px 22.5px 36px 22.5px;
    grid-area: container;
    right: -100%;
    top: 0;
    z-index: 1;

    &.show {
      position: fixed;
      width: 78%;
      right: 0;
      transition: right 0.3s;
    }
  }
}
.navigation {
  display: grid;
  grid-template-columns: 1fr 193px;
  gap: 10px;
  align-items: center;
  grid-area: nav;

  @media (max-width: $mobile-max-width) {
    grid-template-columns: 1fr;
    align-items: center;
    gap: 40px;
  }
}

.logo {
  order: 1;

  @media (max-width: $mobile-max-width) {
    order: 0;
  }
}

.navigation-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  column-gap: 36px;
  padding: 0;
  margin: 0;

  @media (max-width: $mobile-max-width) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}
.navigation-item {
  text-decoration: none;
  color: #050505;
  font-family: 'Raleway';
  font-size: 16px;
  white-space: nowrap;
}

.menu {
  display: flex;
  justify-self: flex-end;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
  column-gap: 24px;
  padding: 0;
  margin: 0;
  margin-left: auto;
  list-style: none;
  grid-area: menu;

  & li {
    display: flex;
    justify-content: center;
  }

  @media (max-width: $mobile-max-width) {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    row-gap: 24px;
    margin-top: auto;
  }
}

.menu-btns {
  display: flex;
  gap: 10px 16px;

  @media (max-width: $mobile-max-width) {
    justify-content: space-between;
    padding: 0 5px;
    width: 100%;
  }
}

.open-nav-btn,
.close-nav-btn {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 22px;
  height: 18px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;

  &::after,
  &::before {
    content: '';
    height: 2px;
    width: 100%;
    background-color: #2c1d2a;
    border-radius: 2px;
  }

  &::after {
    box-shadow: 0 -8px;
  }

  @media (max-width: $mobile-max-width) {
    display: flex;
  }
}

.open-nav-btn {
  position: absolute;
  inset: 37px 5px auto auto;
}

.close-nav-btn {
  position: absolute;
  inset: 27px 19px auto auto;

  transform: rotate(45deg);

  &::after,
  &::before {
    position: absolute;
    top: 8px;
  }

  &::after {
    transform: rotate(90deg);
    box-shadow: none;
  }
}

.btn-primary {
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 18px;
  background-color: #fc9544;
  border-radius: 100px;
  color: #fff;
  padding: 10px 25px;
  border: none;
  transition-duration: 0.3s;

  &:hover {
    background-color: #861920;
  }

  &:active {
    background-color: #ff7e18;
  }

  @media (max-width: $mobile-max-width) {
    width: 100%;
  }
}

.mobile-only {
  @media (min-width: $desktop-min-width) {
    display: none;
  }
}
</style>
