import i18n from '@/i18n'
import { useToast } from 'vue-toastification'

export default async function fetchWrapper (
  url,
  options = {},
  showSuccessMessage = false,
  successMessage = 'operation-successful'
) {
  const toast = useToast()
  const response = await fetch(url, options)

  if (!response.ok) {
    const errorData = await response.json()
    let errorMessage = i18n.global.t('error-occurred')

    // Проверяем, содержит ли detail ключ для перевода и параметры
    if (errorData.detail && typeof errorData.detail === 'object' && errorData.detail.key) {
      errorMessage = i18n.global.t(errorData.detail.key, errorData.detail.params)
    } else if (errorData.detail) {
      // Если detail - это просто строка, не объект, используем её как ключ для перевода
      errorMessage = i18n.global.t(errorData.detail)
    }

    toast.error(errorMessage, {
      position: 'top-right',
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: 'button',
      icon: true,
      rtl: false
    })

    throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorMessage}`)
  }

  if (showSuccessMessage) {
    const translatedSuccessMessage = i18n.global.t(successMessage)
    toast.success(translatedSuccessMessage, {
      position: 'top-right',
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: 'button',
      icon: true,
      rtl: false
    })
  }

  return await response
}
