<template>
    <router-link
        class="logo"
        to="/"
      >
        <img
          src="@/assets/logo-header.svg"
          alt="logo"
        >
        <span class="visually-hidden">{{ $t('t-back-to-home') }}</span>
      </router-link>
</template>

<script>

</script>

<style scoped lang="scss">
.logo {
  display: flex;
  justify-content: center;
  cursor: pointer;

  & img {
    width: 193px;
    height: 80px;

    @media(max-width: $mobile-max-width) {
      width: 146px;
      height: 60px;
    }
  }
}
</style>
