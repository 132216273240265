import { Loader } from '@googlemaps/js-api-loader'

let places = null
let geocoder = null

const loader = new Loader({
  apiKey: 'AIzaSyAtlBsZb98wzKn9iAxeQAwx4pu0a-E0YhA',
  version: 'weekly',
  libraries: ['places', 'geocoding']
})

const getPlaces = async () => {
  if (!places) {
    places = await loader.importLibrary('places')
  }
  return places
}

const getGeocoder = async () => {
  if (!geocoder) {
    const response = await loader.importLibrary('geocoding')
    geocoder = new response.Geocoder()
    geocoder.language = 'en'
  }
  return geocoder
}

export {
  getPlaces,
  getGeocoder
}
