<template>
  <div>
    <button
      type="button"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      class="btn tick"
    >
      {{ state.currency }} ({{ state.currency_symbol }})
    </button>
    <ul class="dropdown-menu dropdown-menu-end" @click="setCurrencyId">
      <li v-for="curr in state.currencies" :key="curr.id">
        <button class="dropdown-item notify-item" :data-value="curr.id" type="button">
          {{ curr.code }} ({{ curr.symbol }})
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import fetchWrapper from '@/utils/fetchWrapper'
import { useCurrencyStore } from '@/stores/currency'
import posthog from 'posthog-js'
import { reactive, watch, watchPostEffect } from 'vue'
import { storeToRefs } from 'pinia'

export default {
  setup () {
    const currencyStore = useCurrencyStore()
    const { id, currencySymbol, currencyCode } = storeToRefs(currencyStore)

    const state = reactive({
      currency: localStorage.getItem('selectedCurrencyCode') || 'USD',
      currency_symbol: localStorage.getItem('selectedCurrencySymbol') || '$',
      currencies: []
    })

    const fetchCurrencies = async () => {
      try {
        fetchWrapper(`${API_BASE_URL}v1/currency/all`)
          .then((response) => response.json())
          .then((data) => {
            state.currencies = data.content
            if (
              !localStorage.getItem('selectedCurrencyCode') ||
              !localStorage.getItem('selectedCurrencyId') ||
              !localStorage.getItem('selectedCurrencySymbol')
            ) {
              posthog.capture('Default Currency Initialized', {
                currencyCode: data.content[0].code,
                currencyId: data.content[0].id
              })
              localStorage.setItem('selectedCurrencyCode', data.content[0].code)
              localStorage.setItem('selectedCurrencyId', data.content[0].id)
              localStorage.setItem('selectedCurrencySymbol', data.content[0].symbol)
            }
          })
      } catch (error) {
        console.error('Ошибка при получении валют:', error)
        posthog.capture('Currency Fetch Error', { error: error.toString() })
      }
    }

    const setCurrencyId = (e) => {
      const oldCurrencyId = id.value
      const newCurrencyId = e.target.dataset.value
      id.value = newCurrencyId

      posthog.capture('Currency Changed', {
        oldCurrencyId,
        newCurrencyId
      })
    }

    watch(id, (newCurrencyId) => {
      const selectedCurrency = state.currencies.find(
        (curr) => Number(curr.id) === Number(newCurrencyId)
      )
      if (selectedCurrency) {
        currencySymbol.value = selectedCurrency.symbol
        currencyCode.value = selectedCurrency.code
        state.currency = selectedCurrency.code
        state.currency_symbol = selectedCurrency.symbol
        localStorage.setItem('selectedCurrencyCode', selectedCurrency.code)
        localStorage.setItem('selectedCurrencyId', newCurrencyId)
        localStorage.setItem('selectedCurrencySymbol', selectedCurrency.symbol)
      }
    })

    watchPostEffect(fetchCurrencies)

    return {
      state,
      currencyStore,
      setCurrencyId
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  position: relative;
  display: flex;
  column-gap: 8px;
  font-family: 'Raleway';
  font-size: 16px;
  border: none;
  margin: 0;
  padding: 0;
  appearance: none;
  white-space: nowrap;
}

.tick {
  position: relative;
  padding-right: 20px;

  &::after {
    position: absolute;
    inset: 6px 0 auto auto;
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 1px solid #0d0815;
    border-right: 1px solid #0d0815;
    transform: rotate(45deg);
  }
}
</style>
