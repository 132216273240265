<template>
  <div>
    <spinner-view v-if="this.isLoading" :isLoading="this.isLoading" />
    <div v-else class="page">
      <SearchForm class="search-form" @submit="fetchScootersWithFilters" />
      <Categories
        class="categories"
        :categories="this.categories"
        :categoriesList="this.categoriesList"
        @toggle-category="this.toggleCategory"
      />
      <FilterAndSortButtons
        @showFilters="state.isFiltersOpened = true"
        @showSorting="state.isSortOpened = true"
      />
      <Total class="total" :quantity="this.total" />
      <Sort class="sort-desktop" :sortType="this.sortType" @set-sort-type="this.setSortType" />
      <Filters
        class="filters-desktop"
        :minPrice="this.minPrice"
        :maxPrice="this.maxPrice"
        :transmissions="this.transmissions"
        :highestPrice="this.highestPrice"
        :categoriesList="this.categoriesList"
        :brandsList="this.brandsList"
        :modelsList="this.modelsList"
        :tagsList="this.tagsList"
        :categories="this.categories"
        :brands="this.brands"
        :models="this.models"
        :tags="this.tags"
        @set-transmissions="setTransmissions"
        @set-categories="setCategories"
        @set-brands="setBrands"
        @set-price="setPrice"
        @set-models="setModels"
        @set-tags="setTags"
      />
      <Catalogue
        class="catalogue"
        :transmissions="this.transmissions"
        :categories="this.categories"
        :brands="this.brands"
        :priceRange="[this.minPrice, this.maxPrice]"
        :scooters="this.scooters"
      />
      <Pagination
        class="pagination"
        :total="this.total"
        @change-page="changePage"
        :offset="this.offset"
      />
      <FullModal v-model:open="state.isFiltersOpened">
        <Filters
          class="filters-mobile"
          :minPrice="this.minPrice"
          :maxPrice="this.maxPrice"
          :transmissions="this.transmissions"
          :highestPrice="this.highestPrice"
          :categoriesList="this.categoriesList"
          :brandsList="this.brandsList"
          :tagsList="this.tagsList"
          :modelsList="this.modelsList"
          :categories="this.categories"
          :brands="this.brands"
          :models="this.models"
          :tags="this.tags"
          @set-transmissions="setTransmissions"
          @set-categories="setCategories"
          @set-brands="setBrands"
          @set-price="setPrice"
          @set-models="setModels"
          @set-tags="setTags"
        />
      </FullModal>
      <SortDrawer
        :sortType="this.sortType"
        @set-sort-type="this.setSortType"
        @closeSortDrawer="state.isSortOpened = false"
        :open="this.state.isSortOpened"
      />
    </div>
  </div>
</template>

<script>
import { reactive, watch, onMounted, ref } from 'vue'
import Catalogue from './components/Catalogue/Catalogue.vue'
import Filters from './components/Filters.vue'
import Categories from './components/Categories.vue'
import Total from './components/Total.vue'
import Sort from './components/Sort.vue'
import SearchForm from './components/SearchForm.vue'
import { useSearchStore } from '@/stores/search'
import { useUserGeolocationStore } from '@/stores/user-geolocation'
import fetchWrapper from '@/utils/fetchWrapper'

import { SORT_TYPES } from '../../constants/constants'
import { useCurrencyStore } from '@/stores/currency'

import { storeToRefs } from 'pinia'
import SpinnerView from '@/components/SpinnerView.vue'
import Pagination from './components/Pagination.vue'
import FilterAndSortButtons from './components/FilterAndSortButtons.vue'
import FullModal from '../../components/FullModal.vue'
import SortDrawer from './components/SortDrawer.vue'

export default {
  components: {
    Filters,
    Categories,
    Total,
    Sort,
    Catalogue,
    SearchForm,
    SpinnerView,
    Pagination,
    FilterAndSortButtons,
    FullModal,
    SortDrawer
  },
  data () {
    return {
      filterTimeout: null
    }
  },
  methods: {
    setTransmissions (transmissions) {
      this.transmissions = transmissions
      this.debouncedFetchScootersWithFilters()
    },
    setCategories (categories) {
      this.categories = categories
      this.debouncedFetchScootersWithFilters()
    },
    toggleCategory (category) {
      if (this.categories.includes(category)) {
        this.categories = this.categories.filter((c) => c !== category)
      } else {
        this.categories.push(category)
      }
      this.debouncedFetchScootersWithFilters()
    },
    setBrands (brands) {
      this.brands = brands
      this.debouncedFetchScootersWithFilters()
    },
    changePage (page) {
      this.offset = page - 1
      this.debouncedFetchScootersWithFilters()
    },
    setModels (models) {
      this.models = models
      this.debouncedFetchScootersWithFilters()
    },
    setTags (tags) {
      this.tags = tags
      this.debouncedFetchScootersWithFilters()
    },
    setPrice (range) {
      this.minPrice = +range[0]
      this.maxPrice = +range[1]
      this.debouncedFetchScootersWithFilters()
    },
    setSortType (type) {
      this.sortType = type
      this.debouncedFetchScootersWithFilters()
    },
    debouncedFetchScootersWithFilters () {
      if (this.filterTimeout) {
        clearTimeout(this.filterTimeout)
      }
      this.filterTimeout = setTimeout(this.fetchScootersWithFilters, 700)
    }
  },
  setup () {
    const state = reactive({
      isFiltersOpened: false,
      isSortOpened: false
    })
    const searchStore = useSearchStore()
    const { pickUpDate, dropOffDate } = storeToRefs(searchStore)
    const userGeolocationStore = useUserGeolocationStore()
    const { lat, lng } = storeToRefs(userGeolocationStore)
    const currencyStore = useCurrencyStore()
    const { id: currencyId } = storeToRefs(currencyStore)

    const categoriesList = ref([])
    const modelsList = ref([])
    const tagsList = ref([])
    const brandsList = ref([])
    const scooters = ref([])
    const total = ref(0)
    const highestPrice = ref(0)
    const minPrice = ref(0)
    const maxPrice = ref(0)
    const isLoading = ref(false)
    const transmissions = ref([])
    const categories = ref([])
    const brands = ref([])
    const models = ref([])
    const tags = ref([])
    const sortType = ref(SORT_TYPES.RECOMMENDED)
    const offset = ref(0)

    const fetchScooterModels = async () => {
      try {
        const response = await fetchWrapper(`${API_BASE_URL}v1/scooter_model/all`)
        const data = await response.json()
        modelsList.value = data.content
      } catch (error) {
        console.error('Error when get scooter models:', error)
      }
    }

    const fetchScooterCategories = async () => {
      try {
        const categoriesResponse = await fetchWrapper(`${API_BASE_URL}v1/scooter_category/all`)
        const categoriesData = await categoriesResponse.json()
        categoriesList.value = categoriesData.content
      } catch (error) {
        console.error('Error when get scooter categories:', error)
      }
    }

    const fetchScooterTags = async () => {
      try {
        const tagsResponse = await fetchWrapper(`${API_BASE_URL}v1/tags/all`)
        const tagsData = await tagsResponse.json()
        tagsList.value = tagsData.content
      } catch (error) {
        console.error('Error when get scooter categories:', error)
      }
    }

    const fetchScooterBrands = async () => {
      try {
        const response = await fetchWrapper(`${API_BASE_URL}v1/scooter_brand/all`)
        const data = await response.json()
        brandsList.value = data.content
      } catch (error) {
        console.error('Error when get scooter brands:', error)
      }
    }

    const fetchScootersWithFilters = async (updateCurrency) => {
      try {
        isLoading.value = true
        const transmission = transmissions.value.length !== 1 ? null : transmissions.value[0]
        const scooterCategoryIds = categoriesList.value
          .filter((category) => categories.value.includes(category.name))
          .map((category) => category.id)

        const scooterBrandIds = brandsList.value
          .filter((brand) => brands.value.includes(brand.name))
          .map((brand) => brand.id)

        const scooterModelsIds = modelsList.value
          .filter((model) => models.value.includes(model.model))
          .map((model) => model.id)

        const scooterTagsIds = tagsList.value
          .filter((tag) => tags.value.includes(tag.name))
          .map((tag) => tag.id)

        const SORT_TYPE =
          sortType.value === SORT_TYPES.RECOMMENDED ? SORT_TYPES.ASC : SORT_TYPES[sortType.value]

        const payload = {
          lat: lat.value,
          lng: lng.value,
          price_from: minPrice.value && !updateCurrency ? parseFloat(minPrice.value) : null,
          price_to:
            updateCurrency || maxPrice.value === highestPrice.value
              ? null
              : Math.ceil(parseFloat(maxPrice.value)),
          scooter_category_ids: scooterCategoryIds,
          scooter_model_ids: scooterModelsIds,
          brand_ids: scooterBrandIds,
          pick_up_date: pickUpDate.value,
          drop_off_date: dropOffDate.value,
          company_rating_from: 0, // TODO remove hardcoded value and add design or remove from
          transmission,
          currency_id: currencyId.value,
          scooter_tags_ids: scooterTagsIds,
          sort_order: SORT_TYPE,
          limit: 10,
          offset: offset.value
        }

        const response = await fetchWrapper(`${API_BASE_URL}v1/scooter/filter_scooters`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        })

        const data = await response.json()
        total.value = data.total
        scooters.value = data.content
        if (updateCurrency) {
          highestPrice.value = data.max_price
          maxPrice.value = data.max_price
        }
      } catch (error) {
        console.error('Error when try to filter scooter:', error)
      } finally {
        isLoading.value = false
      }
    }

    const fetchScooters = async () => {
      try {
        const queryParams = {
          lat: lat.value,
          lng: lng.value,
          pick_up_date: pickUpDate.value,
          drop_off_date: dropOffDate.value,
          currency_id: currencyId.value
        }

        const queryString = new URLSearchParams(queryParams).toString()
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/scooter/get_all_scooters?${queryString}`
        )
        const data = await response.json()
        scooters.value = data.content
        total.value = data.total
        highestPrice.value = data.max_price.toFixed(2)
        maxPrice.value = data.max_price.toFixed(2)
      } catch (error) {
        console.error('Error when fetching data:', error)
      }
    }

    watch(currencyId, async () => {
      isLoading.value = true
      await fetchScootersWithFilters(true)
      isLoading.value = false
    })

    onMounted(async () => {
      isLoading.value = true
      await Promise.all([
        fetchScooterCategories(),
        fetchScooterModels(),
        fetchScooterBrands(),
        fetchScooters(),
        fetchScooterTags()
      ])

      isLoading.value = false
    })

    return {
      state,
      categoriesList,
      modelsList,
      brandsList,
      scooters,
      total,
      highestPrice,
      minPrice,
      maxPrice,
      isLoading,
      fetchScootersWithFilters,
      transmissions,
      categories,
      brands,
      models,
      sortType,
      tags,
      tagsList,
      offset
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  display: grid;
  justify-content: start;
  align-items: start;
  gap: 24px;
  grid-template-columns: min-content 1fr 1fr;
  grid-template-rows: min-content minmax(126px, auto) minmax(24px, auto) auto;
  grid-template-areas:
    'search search search'
    'filters categories categories'
    'filters total sort'
    'filters catalogue catalogue'
    'filters pagination pagination';

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    align-items: normal;
  }
}
.search-form {
  grid-area: search;
}

.total {
  grid-area: total;
  justify-self: start;
}

.sort-desktop {
  grid-area: sort;
  justify-self: end;

  @media (max-width: $mobile-max-width) {
    justify-self: center;
  }
  @media (max-width: 576px) {
    display: none;
  }
}

.categories {
  grid-area: categories;
}

.filters-desktop {
  grid-area: filters;

  @media (max-width: $mobile-max-width) {
    justify-self: center;
  }

  @media (max-width: 576px) {
    display: none;
  }
}

.filters-mobile {
  width: 100%;
  max-width: 100%;
  padding-top: 25px;
}

.catalogue {
  grid-area: catalogue;
}

.pagination {
  grid-area: pagination;
  justify-self: end;

  @media (max-width: $mobile-max-width) {
    justify-self: center;
  }
}
</style>
