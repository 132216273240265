/* eslint-disable */
export default function performRedirect(router, status, bookingReferenceNumber) {
  // Проверяем, определен ли статус и не является ли пустой строкой
  if (status) {
    if (status === 'OPEN') {
      router.push(`/deal?bookingReferenceNumber=${bookingReferenceNumber}`);
    } else if (status === 'IN_PROGRESS') {
      router.push(`/checkout?bookingReferenceNumber=${bookingReferenceNumber}`);
    } else {
      // Для всех остальных определенных статусов перенаправляем на manage-my-booking
      router.push(`/manage-my-booking/${bookingReferenceNumber}`);
    }
  } else {
    // Если статус не определен или пуст, перенаправляем на not-found
    router.push('/not-found');
  }
}