<template>
    <div></div>
  </template>

<script>
export default {
  name: 'TawkToChat',
  mounted () {
    this.loadTawkToChat()
  },
  methods: {
    loadTawkToChat () {
      if (window.Tawk_API) return

      const s1 = document.createElement('script')
      const s0 = document.getElementsByTagName('script')[0]

      s1.async = true
      s1.src = 'https://embed.tawk.to/64e4512994cf5d49dc6bc38a/1h8dtq2v8'
      s1.charset = 'UTF-8'
      s1.setAttribute('crossorigin', '*')
      s0.parentNode.insertBefore(s1, s0)
    }
  }
}
</script>
