<template>
  <div class="advice">
    <img class="icon" :src="iconPath" />
    <div :class="classObject">
      <strong>{{ $t(title) }}</strong>
      <div v-if="this.description">{{ $t(description) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'description', 'icon'],
  computed: {
    iconPath () {
      return require(`@/assets/images/advices/${this.icon}.svg`)
    },
    classObject () {
      return {
        'advice-text': true,
        'vertical-align': !this.description
      }
    }
  }
}
</script>

<style scoped>
.vertical-align {
  align-self: center;
}
.advice {
  display: flex;
  gap: 25px;
  width: 50%;
  padding: 0 24px;
}
.advice-text {
  display: flex;
  flex-direction: column;
  color: #2c1d2a;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: 160%;
}
.icon {
  width: 100px;
  height: 100px;
}
@media (max-width: 1200px) {
  .advice {
    width: auto;
  }
}
@media (max-width: 576px) {
  .advice {
    flex-direction: column;
  }
}
</style>
