<template>
  <div>
    <SpinnerView :isLoading="isLoading" />
    <div v-if="booking.status === 'FINISHED'" class="wrapper-review">
      <div class="title">
        {{ $t('t-review') }}
      </div>
      <div class="input-group">
        <div class="rating-box">
          <ion-icon
            @click="this.setRating(5)"
            name="star-sharp"
            :class="{ 'selected-rating': form.rating > 4, 'rating-b1': true }"
          ></ion-icon>
          <ion-icon
            @click="this.setRating(4)"
            name="star-sharp"
            :class="{ 'selected-rating': form.rating > 3, 'rating-b2': true }"
          ></ion-icon>
          <ion-icon
            @click="this.setRating(3)"
            name="star-sharp"
            :class="{ 'selected-rating': form.rating > 2, 'rating-b3': true }"
          ></ion-icon>
          <ion-icon
            @click="this.setRating(2)"
            name="star-sharp"
            :class="{ 'selected-rating': form.rating > 1, 'rating-b4': true }"
          ></ion-icon>
          <ion-icon
            @click="this.setRating(1)"
            name="star-sharp"
            :class="{ 'selected-rating': form.rating > 0, 'rating-b5': true }"
          ></ion-icon>
        </div>
      </div>

      <div class="comment-box">
        <label for="comment">{{ $t('t-comment') }}</label>
        <textarea class="comment-area" v-model="form.comment" id="comment" placeholder="Your comment..."></textarea>
      </div>
       <button class="review-button" :disabled="!this.form.rating" @click="submitReview">
          {{ $t('t-submit-review') }}
        </button>
    </div>
  </div>
</template>

<script>
import fetchWrapper from '@/utils/fetchWrapper'
import SpinnerView from '@/components/SpinnerView.vue'
import { useToast } from 'vue-toastification'

export default {
  setup () {
    const toast = useToast()
    return { toast }
  },
  components: {
    SpinnerView
  },
  name: 'ReviewTab',
  data () {
    return {
      booking: {},
      form: {
        rating: null,
        comment: ''
      },
      companyId: null,
      bookingReferenceNumber: this.$route.params.uuid,
      selectedCurrencyId: localStorage.getItem('selectedCurrencyId'),
      isLoading: true
    }
  },
  mounted () {
    this.fetchBookingDetails()
  },
  methods: {
    async fetchBookingDetails () {
      try {
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/booking/${this.bookingReferenceNumber}?currency_id=${this.selectedCurrencyId}`
        )
        if (response.ok) {
          const data = await response.json()
          this.booking = data

          if (this.booking.status !== 'FINISHED') {
            this.$router.push(`/manage-my-booking/${this.bookingReferenceNumber}`)
              .then(() => {
                location.reload()
              })
          } else {
            this.fetchScooterDetails()
          }
        } else {
          this.$router.push('/not-found')
        }
      } catch (error) {
        console.error('Error fetching booking details:', error)
        this.$router.push('/not-found')
      }
      this.isLoading = false
    },
    async fetchScooterDetails () {
      try {
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/scooter/${this.booking.scooter_id}?currency_id=${this.selectedCurrencyId}`
        )
        if (response.ok) {
          const data = await response.json()
          this.companyId = data.company_id
        } else {
          console.error('Error fetching scooter details:', response.statusText)
        }
      } catch (error) {
        console.error('Error fetching scooter details:', error)
      }
    },
    async submitReview () {
      try {
        const payload = {
          rating: this.form.rating,
          comments: this.form.comment
        }

        const response = await fetch(
          `${API_BASE_URL}v1/company_rating/${this.companyId}/set_rating/${this.bookingReferenceNumber}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
          }
        )

        if (response.ok) {
          const data = await response.json()
          this.toast.success(data.detail)
        }
      } catch (error) {
        console.error('Error submitting review:', error)
        alert('Error submitting review. Please try again.')
      }
    },
    setRating (rating) {
      this.form.rating = rating
    }
  }
}
</script>

<style scoped>
.manage-booking-container {
  padding: 20px;
  background-color: #f6f6f6;
}
.review-form {
  flex-direction: column;
  gap: 20px;
}
.input-group {
  flex-direction: column;
  gap: 10px;
}
.comment-area {
  resize: none;
  min-height: 100px;
}
nav {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  align-items: center;
  background-color: #f6f6f6;
}
nav a {
  padding: 10px 20px;
  background-color: #f1f1f1;
  border-radius: 5px;
  transition: background-color 0.3s;
}
nav a:hover {
  background-color: #e0e0e0;
}
.wrapper-review {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 24px;
  border-radius: 32px;
  border: 1px solid #d4d1d0;
  font-family: Raleway;
}
.title {
  color: #2c1d2a;
  font-size: 24px;
  font-weight: 600;
}
.rating-box {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.rating-box ion-icon {
  font-size: 32px;
  color: #313131;
  transition: 0.3s all;
  cursor: pointer;
  visibility: inherit !important;
}
.rating-box .selected-rating {
  color: #f1c40f;
}
.rating-box ion-icon:hover {
  color: #f1c40f;
}
.rating-b1:hover ~ ion-icon {
  color: #f1c40f;
}
.rating-b2:hover ~ ion-icon {
  color: #f1c40f;
}
.rating-b3:hover ~ ion-icon {
  color: #f1c40f;
}
.rating-b4:hover ~ ion-icon {
  color: #f1c40f;
}
.rating-b5:hover ~ ion-icon {
  color: #f1c40f;
}
.review-button {
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 18px;
  background-color: #fc9544;
  border-radius: 100px;
  color: #fff;
  padding: 10px 25px;
  border: none;
  transition-duration: 0.3s;
  cursor: pointer;
}
.review-button:hover {
  background-color: #861920;
}
.review-button:active {
  background-color: #ff7e18;
}
.comment-area {
  background-color: #dddddd;
  color: #666666;
  padding: 1em;
  border-radius: 10px;
  border: 2px solid transparent;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  width: 100%;
  height: 150px;
  transition: all 0.2s;
}
.comment-area:hover {
  cursor: pointer;
  background-color: #eeeeee;
}
.comment-area:focus {
  cursor: text;
  color: #333333;
  background-color: white;
  border-color: #333333;
}
.comment-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 767px) {
  .review-button {
    width: 100%;
  }
}
</style>
