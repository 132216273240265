<template>
  <div>
    <spinner-view v-if="this.isLoading" :isLoading="this.isLoading" />
    <div v-else class="wrapper">
      <h2 class="title">{{ $t('t-manage-my-booking') }}</h2>

      <div v-if="!requestResend" class="booking-details">
        <div>
          <div class="find-booking">{{ $t('t-find-my-booking') }}</div>
          <p class="find-booking-description">
            {{ $t('t-resend-booking-by-email-and-booking-reference-number-text') }}
          </p>
        </div>

        <form @submit.prevent="submitForm" class="form">
          <input
            class="input"
            type="text"
            required
            :placeholder="$t('t-email-address')"
            v-model="email"
          />
          <input
            class="input"
            type="text"
            required
            :placeholder="$t('t-booking-reference-number')"
            v-model="bookingReference"
          />
          <p>
            <button type="button" class="resend-button" @click="requestResend = true">
              {{ $t('t-resend-booking-by-email') }}
            </button>
          </p>

          <button class="manage-booking-submit">{{ $t('t-submit') }}</button>
        </form>
      </div>

      <div v-if="requestResend" class="booking-details form">
        <button type="button" class="back-button" @click="requestResend = false">
          {{ $t('t-back') }}
        </button>
        <p class="find-booking-description">{{ $t('t-resend-booking-by-email-text') }}</p>

        <input
          class="input"
          type="text"
          required
          :placeholder="$t('t-email')"
          v-model="resendEmail"
        />

        <button
          type="button"
          class="manage-booking-submit"
          :disabled="!isValidEmail(resendEmail)"
          @click="resendConfirmation"
        >
          {{ $t('t-resend-confirmation-email') }}
        </button>
      </div>

      <div v-if="emailSent" class="email-sent-confirmation border p-4 rounded">
        <p>{{ $t('t-an-email-has-been-sent') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import fetchWrapper from '@/utils/fetchWrapper'
import SpinnerView from '@/components/SpinnerView.vue'
import performRedirect from '@/utils/redirectLogic'

export default {
  components: {
    SpinnerView
  },
  data () {
    return {
      email: '',
      bookingReference: '',
      requestResend: false,
      resendEmail: '',
      emailSent: false,
      isLoading: false
    }
  },
  methods: {
    isValidEmail (email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return regex.test(email)
    },
    async submitForm () {
      try {
        this.isLoading = true
        const response = await fetchWrapper(
          `${API_BASE_URL}v1/booking/${this.bookingReference}/${this.email}`
        )

        const data = await response.json()
        this.data = data

        const status = data.status
        performRedirect(this.$router, status, this.bookingReference)
      } catch (error) {
        console.error('Error when fetching data:', error)
      }
      this.isLoading = false
    },
    resendConfirmation () {
      this.isLoading = true
      fetchWrapper(
        `${API_BASE_URL}v1/booking/resend_by_booking_email/${this.resendEmail}`,
        {},
        true,
        this.$t('t-email-resent-successfully')
      )
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          this.data = data
        })
        .catch((error) => {
          console.error('Error when fetching data:', error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-bottom: 120px;
  font-family: Raleway;
}
.title {
  font-style: 42px;
  font-weight: 700;
  text-align: center;
  margin-top: 48px;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.manage-booking-submit {
  border: none;
  outline: none;
  border-radius: 100px;
  background: #fc9544;
  height: 60px;
  color: #fff;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  transition: background-color 0.3s;
  width: 100%;
  cursor: pointer
}
.manage-booking-submit:hover {
  background: #861920;
}
.input {
  display: flex;
  padding: 12px 40px 12px 24px;
  height: 53px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 100px;
  border: 1px solid #d4d1d0;
  background: #fff;
  border-top-right-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
}
.input::placeholder {
  color: #888;
  font-size: 18px;
  font-weight: 400;
}
.booking-details {
  border-radius: 32px;
  background-color: #fff5ed;
  padding: 24px 32px;
}
.find-booking {
  font-size: 24px;
  font-weight: 600;
  color: #2c1d2a;
}
.find-booking-description {
  font-size: 16px;
  font-weight: 400;
}
.resend-button {
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  color: #fc9544;
  border: none;
  outline: none;
  background-color: transparent;
}
.back-button {
  border: none;
  outline: none;
  background-color: transparent;
  align-self: flex-start;
  font-size: 24px;
  font-weight: bold;
  color: #fc9544;
  transition: color 0.3s;
}
.back-button:hover {
  color: #861920;
}
</style>
