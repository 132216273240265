<template>
  <div>
    <a @click.prevent="state.isOpened = true" class="info-button" href="#">
      <svg
        class="info-icon"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_820_389)">
          <path
            d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6818 14.6666 7.99992C14.6666 4.31802 11.6818 1.33325 7.99992 1.33325C4.31802 1.33325 1.33325 4.31802 1.33325 7.99992C1.33325 11.6818 4.31802 14.6666 7.99992 14.6666Z"
            stroke="#FC9544"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8 10.6667V8"
            stroke="#FC9544"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8 5.33325H8.00667"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_820_389">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
      {{ $t('t-important-information') }}
    </a>
    <ImportantInfoModal v-model:open="state.isOpened" />
  </div>
</template>

<script>
import { reactive } from 'vue'
import ImportantInfoModal from './ImportantInfoModal.vue'
export default {
  components: {
    ImportantInfoModal
  },
  setup () {
    const state = reactive({
      isOpened: false
    })

    return {
      state
    }
  }
}
</script>

<style scoped lang="scss">
.info-button {
  display: flex;
  gap: 6px;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  color: #fc9544;
  transition-duration: $transition-duration;

  & .info-icon path {
    transition-duration: $transition-duration;
  }

  &:hover,
  &:focus {
    color: #ff7e18;
  }
  &:hover .info-icon path,
  &:focus .info-icon path {
    stroke: #ff7e18;
  }

  &:active {
    color: #861920;
  }
  &:active .info-icon path {
    stroke: #861920;
  }

  @media (max-width: $mobile-max-width) {
    align-self: center;
  }
}
</style>
