import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '../pages/HomePage/HomePage.vue'
import SearchPage from '../pages/SearchPage/SearchPage.vue'
import DealPage from '../pages/DealPage/DealPage.vue'
import CheckoutPage from '../pages/CheckoutPage/CheckoutPage.vue'
import ManageMyBookingPage from '../pages/ManageMyBookingPage/ManageMyBookingPage.vue'
import ManageBookingPage from '../pages/ManageBookingPage/ManageBookingPage.vue'
import NotFoundView from '../views/NotFoundView.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

import FaqView from '../views/FaqView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/search',
    name: 'SearchResults',
    component: SearchPage
  },
  {
    path: '/deal',
    name: 'Deal',
    component: DealPage
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: CheckoutPage
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/manage-booking',
    name: 'ManageBooking',
    component: ManageBookingPage
  },
  {
    path: '/faq',
    name: 'Faq',
    component: FaqView
  },
  {
    path: '/manage-my-booking/:uuid',
    name: 'ManageMyBooking',
    component: ManageMyBookingPage
  },
  {
    path: '/manage-my-booking/:uuid/chat',
    component: ManageMyBookingPage,
    name: 'ChatTab'
  },
  {
    path: '/manage-my-booking/:uuid/review',
    component: ManageMyBookingPage,
    name: 'ReviewTab'
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
