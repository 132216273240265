<template>
  <a-checkbox :checked="this.checked" @change="this.toggleCheckbox">{{
    this.label
  }}</a-checkbox>
</template>

<script>
import { Checkbox } from 'ant-design-vue'
export default {
  props: ['label', 'checked'],
  emits: ['change'],
  components: {
    ACheckbox: Checkbox
  },
  methods: {
    toggleCheckbox () {
      this.$emit('change')
    }
  }
}
</script>

<style>
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fc9544 !important;
  border-color: #fc9544 !important;
}
</style>
