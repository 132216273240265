<template>
  <div class="advices">
    <div class="advices-title">{{ $t('t-advices-title') }}</div>
    <div class="advices-items">
      <AdvicesItem
        v-for="advice in advices"
        :key="advice.id"
        :title="advice.title"
        :description="advice.description"
        :icon="advice.iconName"
      />
    </div>
  </div>
</template>

<script>
import AdvicesItem from './AdvicesItem.vue'

export default {
  components: {
    AdvicesItem
  },
  data: function () {
    return {
      advices: [
        {
          id: 1,
          title: 't-advices-be-on-time',
          description: 't-advices-be-on-time-description',
          iconName: 'timer'
        },
        {
          id: 2,
          title: 't-advices-check-scooter',
          description: 't-advices-check-scooter-description',
          iconName: 'scooter'
        },
        {
          id: 3,
          title: 't-advices-documents',
          description: 't-advices-documents-description',
          iconName: 'documents'
        },
        {
          id: 4,
          title: 't-advices-helmets',
          description: null,
          iconName: 'helm'
        },
        {
          id: 5,
          title: 't-advices-insurance',
          description: null,
          iconName: 'insurance'
        }
      ]
    }
  }
}
</script>

<style scoped>
.advices {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
}
.advices-title {
  color: #2c1d2a;
  font-family: 'Raleway', sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
}
.advices-items {
  display: flex;
  flex-wrap: wrap;
  height: 355px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}
@media (max-width: 1200px) {
  .advices-items {
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    gap: 24px;
  }
}
@media (max-width: 576px) {
  .advices-title {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }
}
</style>
