<template>
  <a-collapse
      v-model:activeKey="state.activeKey"
      :bordered="false"
      expand-icon-position="end"
      class="custom-collapse">
      <template v-slot:expandIcon="{isActive}">
          <span class="open-btn" :class="isActive ? 'rotated-btn' : ''"></span>
      </template>
      <a-collapse-panel
          v-for="item in props.items"
          :key="item.id"
          class='item'>
          <template v-slot:header>
              <h3 class="title">{{ $t(item.title) }}</h3>
          </template>
          <!-- Условное отображение содержимого -->
          <div v-if="item.isHtml" v-html="item.text" class="content"></div>
          <a-typography-text v-else class="content">
              {{ $t(item.text) }}
          </a-typography-text>
      </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { Collapse, CollapsePanel, TypographyText } from 'ant-design-vue'
import { reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  props: {
    items: Array
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      activeKey: route.query.activeKey ? [route.query.activeKey] : []
    })

    watch(() => state.activeKey, (newActiveKey) => {
      const activeItem = props.items.find(item => item.id.toString() === newActiveKey[0])

      // Если активный элемент найден и его свойство isUpdateUrl не равно false, обновляем URL
      if (activeItem && activeItem.isUpdateUrl !== false) {
        router.replace({ query: { activeKey: newActiveKey[0] } })
      } else if (!activeItem || activeItem.isUpdateUrl === false) {
        // Если элемент не найден или явно установлено isUpdateUrl: false, не обновляем URL
      }
    })
    return {
      state,
      props
    }
  },
  components: {
    ACollapse: Collapse,
    ACollapsePanel: CollapsePanel,
    ATypographyText: TypographyText

  }
}
</script>

<style scoped lang="scss">
.custom-collapse {
  align-self: stretch;
  font-family: 'Raleway', sans-serif;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: $mobile-max-width) {
    gap: 24px;
  }

  & > .ant-collapse-item:last-child {
    border-radius: 24px;
  }
}

.item {
  background-color: #FFF5ED;
  border: none;
  border-radius: 24px;
  padding: 10px 10px 10px 5px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.content {
  font-size: 16px;
  font-weight: 400;
  margin: 0;

  @media (max-width: $mobile-max-width) {
    font-size: 18px;
  }
}

.open-btn {
  position: relative;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  transition-duration: $transition-duration;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 11px;
    left: 5px;
    width: 14px;
    height: 2px;
    border-radius: 2px;
    background-color: #2C1D2A;
  }

  &::before {
    transform: rotate(90deg);
  }
}

.rotated-btn {
  transform: rotate(45deg);
}
</style>
