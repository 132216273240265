<template>
  <div class="sort">
    <div>
      <strong>{{ $t('t-sort-by') }}:</strong> {{ $t(sortName) }}
    </div>

    <a-dropdown :trigger="['click']">
      <img src="@/assets/images/icons/arrow.svg" alt="arrow" />
      <template #overlay>
        <a-menu>
          <a-menu-item @click="setSortType(SORT_TYPES.ASC)" key="1">{{
            $t('t-price-low-to-high')
          }}</a-menu-item>
          <a-menu-item @click="setSortType(SORT_TYPES.DESC)" key="2">{{
            $t('t-price-high-to-low')
          }}</a-menu-item>
          <a-menu-item @click="setSortType(SORT_TYPES.RECOMMENDED)" key="3">{{
            $t('t-recommended')
          }}</a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
import { SORT_TYPES } from '../../../constants/constants'
import { Dropdown, Menu, MenuItem } from 'ant-design-vue'

export default {
  props: ['sortType'],
  emits: ['setSortType'],
  components: {
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: MenuItem
  },
  data () {
    return {
      SORT_TYPES
    }
  },
  methods: {
    setSortType (type) {
      this.$emit('setSortType', type)
    }
  },
  computed: {
    sortName () {
      switch (this.sortType) {
        case SORT_TYPES.ASC:
          return 't-price-low-to-high'
        case SORT_TYPES.DESC:
          return 't-price-high-to-low'
        default:
          return 't-recommended'
      }
    }
  }
}
</script>

<style scoped>
.sort {
  display: flex;
  cursor: pointer;
  align-items: center;
}
</style>
