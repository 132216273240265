<template>
  <div>
    <div class="catalogue" v-if="!!this.scooters.length">
      <ScooterBlock
        v-for="scooter in this.scooters"
        :key="scooter.id"
        :img_url="scooter.photo_urls[0]"
        :name="`${scooter.scooter_brand_name} ${scooter.scooter_model_name}`"
        :category="scooter.scooter_category_name"
        :id="scooter.id"
        :transmission="scooter.transmission"
        :description="scooter.description"
        :price="scooter.total_cost"
        :order_days="scooter.total_days"
        :company_logo="scooter.company.logo_url"
        :company_name="scooter.company.name"
        :company_rating="scooter.company.average_rating"
        :minimal_available_days="scooter.minimal_available_days"
        :is_custom="scooter.is_custom"
        @view-deal="viewDeal"
        @show-learn-more="showLearnMore"
      />
      <LearnMore
        v-model:open="learnMorePopup.isOpened"
        :scooter="learnMorePopup.scooter"
        @view-deal="viewDeal"
      />
    </div>
    <div class="not-found-scooters-wrapper" v-else>
      <img src="@/assets/images/search/scooters-not-found.png" alt="No scooter found" />
      <div>{{ $t('t-error-scooter-not-found') }}</div>
    </div>
  </div>
</template>

<script>
import ScooterBlock from './Scooter.vue'
import fetchWrapper from '@/utils/fetchWrapper'
import { useSearchStore } from '@/stores/search'
import { useUserGeolocationStore } from '@/stores/user-geolocation'
import LearnMore from '../LearnMore/LearnMore.vue'

export default {
  props: ['transmissions', 'categories', 'brands', 'priceRange', 'scooters'],
  components: {
    ScooterBlock,
    LearnMore
  },
  data () {
    return {
      learnMorePopup: {
        isOpened: false,
        scooter: null
      }
    }
  },
  methods: {
    viewDeal (id) {
      const searchStore = useSearchStore()
      const userGeolocationStore = useUserGeolocationStore()
      const scooter = this.scooters.find((scooter) => scooter.id === id)
      if (!scooter) {
        console.error('Scooter not found')
        return
      }

      const selectedCurrencyId = localStorage.getItem('selectedCurrencyId')

      const booking = {
        scooter_id: scooter.id,
        lat: userGeolocationStore.lat,
        lng: userGeolocationStore.lng,
        pick_up_place: searchStore.pickUpPlace,
        pickup_date: searchStore.pickUpDate,
        dropoff_date: searchStore.dropOffDate,
        status: 'OPEN',
        currency: localStorage.getItem('selectedCurrencyCode')
      }

      fetchWrapper(`${API_BASE_URL}v1/booking/create`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(booking)
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('HTTP error ' + response.status)
          }
          return response.json()
        })
        .then((data) => {
          this.$router.push(
            `/deal?bookingReferenceNumber=${data.booking_reference_number}&currency_id=${selectedCurrencyId}`
          )
        })
        .catch((error) => {
          console.error('Error when creating booking:', error)
        })
    },
    showLearnMore (id) {
      this.learnMorePopup.scooter = this.scooters.find((scooter) => scooter.id === id)
      this.learnMorePopup.isOpened = true
    }
  }
}
</script>

<style scoped>
.catalogue {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  height: 100%;
}
.not-found-scooters-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #2c1d2a;
}
</style>
