<template>
  <div>
    <p class="title">{{ $t('t-features-title') }}</p>
    <div class="features">
      <div class="feature">
        <img src="@/assets/images/features/approved.svg" alt="approved" />
        <p class="feature-title">{{ $t('t-features-proven-companies') }}</p>
        <div class="feature-description">
          <ul>
            <li>{{ $t('t-only-quality-scooters') }}</li>
            <li>{{ $t('t-fair-prices') }}</li>
            <li>{{ $t('t-decent-service') }}</li>
          </ul>
        </div>
      </div>
      <div class="feature">
        <img src="@/assets/images/features/star.svg" alt="star" />
        <p class="feature-title">{{ $t('t-features-ease') }}</p>
        <div class="feature-description">{{ $t('t-features-ease-description') }}</div>
      </div>
      <div class="feature">
        <img src="@/assets/images/features/money.svg" alt="money" />
        <p class="feature-title">{{ $t('t-features-payment') }}</p>
        <div class="feature-description">
          <ul>
            <li>{{ $t('t-online-payment') }}</li>
            <li>{{ $t('t-cash-payment') }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
<style scoped>
p {
  margin: 0;
  padding: 0;
}
ul {
  padding-left: 1.2rem;
}
.title {
  color: #2c1d2a;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 140%;
}
.features {
  display: flex;
  width: 100%;
  gap: 24px;
  margin-top: 32px;
  align-items: stretch;
}
.feature {
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background-color: #fff5ed;
  flex-basis: 33%;
  font-family: 'Raleway', sans-serif;
  line-height: 140%;
  gap: 8px;
}
.feature-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}
.feature-description {
  font-size: 16px;
}
img {
  width: 100px;
}
@media (max-width: 768px) {
  .features {
    flex-direction: column;
    gap: 20px;
  }
  .feature {
    padding: 0 16px;
  }
  .title {
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
  }
}
</style>
