export default [{
  flag: require('@/assets/images/flags/us.svg'),
  language: 'en',
  title: 'English'
},
{
  flag: require('@/assets/images/flags/id.svg'),
  language: 'id',
  title: 'Bahasa'
},
{
  flag: require('@/assets/images/flags/russia.svg'),
  language: 'ru',
  title: 'Русский'
},
{
  flag: require('@/assets/images/flags/de.svg'),
  language: 'de',
  title: 'German'
},
{
  flag: require('@/assets/images/flags/es.svg'),
  language: 'es',
  title: 'Spanish'
},
{
  flag: require('@/assets/images/flags/fr.svg'),
  language: 'fr',
  title: 'French'
},
{
  flag: require('@/assets/images/flags/ua.svg'),
  language: 'ua',
  title: 'Ukranian'
},
{
  flag: require('@/assets/images/flags/by.svg'),
  language: 'by',
  title: 'Belarusian'
}]
