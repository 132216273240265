<template>
  <div class="service-record-grid">
    <img :src="this.icon" alt="" />
    <div class="service-record-description">
      <div>{{ this.name }}</div>
      <div style="font-size: 16px">{{ servicePrice }}</div>
    </div>
    <div class="service-record-status">
      <div v-if="this.maxCount" class="number-input">
        <div class="icon-wrapper">
          <img
            v-if="this.count > 0"
            @click="this.decreaseCount"
            src="@/assets/images/icons/minus.svg"
            alt="decrease"
          />
        </div>
        <input type="number" :value="this.count" disabled />
        <div class="icon-wrapper">
          <img
            v-if="this.count < this.maxCount"
            @click="this.increaseCount"
            src="@/assets/images/icons/plus.svg"
            alt="increase"
          />
        </div>
      </div>
      <div v-else>
        <ServiceCheckbox
          :selected="this.selected"
          :serviceId="this.id"
          @toggle-checkbox="this.toggleCheckbox"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ServiceCheckbox from './ServiceCheckbox.vue'

export default {
  props: ['icon', 'name', 'pricePerDay', 'priceOneTime', 'count', 'maxCount', 'id', 'selected'],
  components: {
    ServiceCheckbox
  },
  emits: ['setCount'],
  methods: {
    priceConverter (price) {
      return `${price
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
    },
    increaseCount () {
      const increased = this.count + 1 > this.maxCount ? this.maxCount : this.count + 1
      this.$emit('setCount', increased, this.id)
    },
    decreaseCount () {
      const decreased = this.count - 1 < 0 ? 0 : this.count - 1
      this.$emit('setCount', decreased, this.id)
    },
    toggleCheckbox (id) {
      this.$emit('toggleCheckbox', id)
    }
  },
  computed: {
    servicePrice () {
      if (
        (this.pricePerDay === 0 || this.pricePerDay === null) &&
        (this.priceOneTime === 0 || this.priceOneTime === null)
      ) {
        return this.$t('t-free')
      }

      if (this.priceOneTime > 0) {
        const formattedPrice = this.priceConverter(this.priceOneTime)
        return `${formattedPrice} ${localStorage.getItem('selectedCurrencySymbol')} / ${this.$t(
          't-one-time'
        )}`
      }

      if (this.pricePerDay > 0) {
        const formattedPrice = this.priceConverter(this.pricePerDay)
        return `${formattedPrice} ${localStorage.getItem('selectedCurrencySymbol')} / ${this.$t(
          't-price-per-day-additional-services'
        )}`
      }
      return null
    }
  }
}
</script>

<style scoped>
.service-record-grid {
  display: grid;
  grid-template-columns: 72px auto 80px;
  gap: 24px;
  align-items: center;
}
.service-record-grid img {
  width: 72px;
  height: 72px;
}
.service-record-description {
  display: flex;
  flex-direction: column;
  color: #050505;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
}
.number-input {
  display: flex;
  gap: 4px;
  align-items: center;
}
.number-input img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.number-input input::-webkit-outer-spin-button,
.number-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input input {
  opacity: 1;
  border: none;
  outline: none;
  width: 24px;
  color: #050505;
  text-align: center;
  font-family: Raleway;
  font-size: 28px;
  font-weight: 400;
  appearance: auto;
  text-rendering: auto;
  display: inline-block;
  padding-block: 1px;
  padding-inline: 2px;
  padding: 0;
  background-color: #fff
}
.service-record-status {
  display: flex;
  justify-content: end;
  align-items: center;
}

.icon-wrapper {
  display: inline-flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}
</style>
