<template>
  <ul
    class="categories"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <li
      v-for="category in this.categoriesList"
      :key="category.id"
      class="category"
      :class="{ active: this.categories.includes(category.name) }"
      @click="this.toggleCategory(category.name)"
    >
      <img class="icon" :src="category.photo_url" :alt="`${category.photo_url}-icon`" />
      <span class="text">{{ category.name }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['categories', 'categoriesList'],
  emits: ['toggleCategory'],
  data () {
    return {
      startX: 0,
      startY: 0
    }
  },
  methods: {
    toggleCategory (category) {
      this.$emit('toggleCategory', category)
    },
    handleTouchStart (event) {
      this.startX = event.touches[0].clientX
      this.startY = event.touches[0].clientY
    },
    handleTouchMove (event) {
      event.preventDefault()
      this.endX = event.touches[0].clientX
      this.endY = event.touches[0].clientY
    },
    handleTouchEnd () {
      const categoriesBlock = document.querySelector('.categories')
      const diffX = this.startX - this.endX
      const diffY = this.startY - this.endY
      if (Math.abs(diffX) > Math.abs(diffY)) {
        categoriesBlock.scrollLeft += diffX
      }
    }
  },
  mounted () {
    const categoriesBlock = document.querySelector('.categories')
    categoriesBlock?.addEventListener('wheel', (event) => {
      if (window.innerWidth < 767) {
        event.preventDefault()
        categoriesBlock.scrollLeft += event.deltaY
      }
    })
  },
  unmounted () {
    const categoriesBlock = document.querySelector('.categories')
    categoriesBlock?.removeEventListener('wheel', (event) => {
      event.preventDefault()
      categoriesBlock.scrollLeft += event.deltaY
    })
  }
}
</script>

<style scoped lang="scss">
.categories {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  height: min-content;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;

  @media (max-width: $mobile-max-width) {
    flex-wrap: nowrap;
    overflow-x: hidden;
  }
}

.active {
  border: 1px solid #fc9544;
}

.category {
  display: grid;
  grid-template-rows: 1fr min-content;
  gap: 8px;
  background-color: #fff5ed;
  padding: 16px 22px;
  border-radius: 32px;
  box-sizing: border-box;
  min-width: 116px;
  min-height: 120px;
  width: 116px;
  height: 120px;
  cursor: pointer;

  @media (max-width: $mobile-max-width) {
    grid-template-rows: min-content;
    grid-template-columns: auto auto;
    min-width: auto;
    min-height: 46px;
    padding: 8px 10px;
    height: 50px;
  }
}

.icon {
  align-self: center;
  justify-self: center;

  @media (max-width: $mobile-max-width) {
    width: 30px;
    height: 30px;
    justify-self: start;
  }
}

.text {
  color: #050505;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 400;
  justify-self: center;

  @media (max-width: $mobile-max-width) {
    align-self: center;
    justify-self: start;
    white-space: nowrap;
  }
}
</style>
