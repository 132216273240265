import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useSearchStore = defineStore('searchStore', () => {
  const urlParams = new URLSearchParams(window.location.search)
  const pickUpPlaceQuery = urlParams.get('pickUpPlace') || ''
  const pickUpDateQuery = urlParams.get('pickUpDate') || ''
  const dropOffDateQuery = urlParams.get('dropOffDate') || ''
  const pickUpPlace = ref(pickUpPlaceQuery)
  const isPlaceSelected = ref(!!pickUpPlaceQuery)
  const pickUpDate = ref(pickUpDateQuery)
  const dropOffDate = ref(dropOffDateQuery)

  return { pickUpPlace, isPlaceSelected, pickUpDate, dropOffDate }
})
