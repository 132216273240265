import { initializeApp } from 'firebase/app'
import { getDatabase, ref, onValue, push, off, update, query, orderByChild, equalTo, get } from 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyA-_EtziZVfxkmqK5_nbuk4QptD1FJK_dE',
  authDomain: 'ursa-rent-chat.firebaseapp.com',
  databaseURL: 'https://ursa-rent-chat-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'ursa-rent-chat',
  storageBucket: 'ursa-rent-chat.appspot.com',
  messagingSenderId: '980176751981',
  appId: '1:980176751981:web:338146c089a62e0fab9e64',
  measurementId: 'G-R6Y2WVCJSL'
}

const app = initializeApp(firebaseConfig)
const database = getDatabase(app)

export { database, ref, onValue, push, off, update, query, orderByChild, equalTo, get }
