<template>
  <a-drawer @close="this.closeDrawer" :open="open" :placement="'bottom'" :title="'Sort by'">
    <div class="sort-drawer-wrapper">
      <div>
        <input
          @change="setSortType(SORT_TYPES.ASC)"
          type="radio"
          id="price-low-to-high"
          name="sort-type"
          value="low-to-high"
          :checked="this.sortType === SORT_TYPES.ASC"
        />
        <label for="price-low-to-high">{{ $t('t-price-low-to-high') }}</label>
      </div>
      <div>
        <input
          @change="setSortType(SORT_TYPES.DESC)"
          type="radio"
          id="price-high-to-low"
          name="sort-type"
          value="high-to-low"
          :checked="this.sortType === SORT_TYPES.DESC"
        />
        <label for="price-high-to-low">{{ $t('t-price-high-to-low') }}</label>
      </div>
      <div>
        <input
          @change="setSortType(SORT_TYPES.RECOMMENDED)"
          type="radio"
          id="recommended"
          name="sort-type"
          value="recommended"
          :checked="this.sortType === SORT_TYPES.RECOMMENDED"
        />
        <label for="recommended">{{ $t('t-recommended') }}</label>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import { Drawer } from 'ant-design-vue'
import { SORT_TYPES } from '@/constants/constants'

export default {
  components: {
    ADrawer: Drawer
  },
  props: ['open', 'sortType'],
  emits: ['closeSortDrawer', 'setSortType'],
  methods: {
    closeDrawer () {
      this.$emit('closeSortDrawer')
    },
    setSortType (type) {
      this.$emit('setSortType', type)
      this.closeDrawer()
    }
  },
  setup () {
    return {
      SORT_TYPES
    }
  }
}
</script>

<style scoped>
.sort-drawer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.sort-drawer-wrapper div {
  display: flex;
  gap: 8px;
  align-items: center;
}
input[type='radio'],
label {
  cursor: pointer;
}

input[type='radio'] {
  position: relative;
  height: 22px;
  width: 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

input[type='radio']::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #fc9544;
}

input[type='radio']:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fc9544;
  transform: translate(-50%, -50%);
  visibility: visible;
}
</style>
