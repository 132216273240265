<template>
  <div class="wrapper">
    <div class="bg-layer">
      <a name="search-form"></a>
      <div class="padding-layer">
        <p class="title-with-br">
          {{ $t('t-lets-make-bali') }}<br />
          {{ $t('t-experience-unforgettable') }}
        </p>
        <p class="title">{{ $t('t-lets-make-bali') }} {{ $t('t-experience-unforgettable') }}</p>
        <p class="start">{{ $t('t-start-your-ride') }}</p>
        <form class="form" @submit.prevent="onSubmit">
          <div class="inputs">
            <place-picker class="place-picker" />
            <date-picker class="date-picker" />
          </div>
          <button class="submit">{{ $t('t-submit') }}</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification'
import PlacePicker from '@/components/PlacePicker.vue'
import DatePicker from '@/components/DatePicker.vue'
import posthog from 'posthog-js'

import { useSearchStore } from '@/stores/search'
import { useUserGeolocationStore } from '@/stores/user-geolocation'

import 'flatpickr/dist/flatpickr.css'

export default {
  setup () {
    const toast = useToast()
    const router = useRouter()
    const searchStore = useSearchStore()
    const userGeolocationStore = useUserGeolocationStore()

    const v$ = useVuelidate()

    const onSubmit = async () => {
      posthog.capture('Form Submitted')
      const isFormCorrect = await v$.value.$validate()

      if (!searchStore.isPlaceSelected) {
        toast('Please select a Pick-up Place.', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
        return
      }

      if (isFormCorrect) {
        posthog.capture('Form Validated Successfully')

        const query = {
          pickUpPlace: searchStore.pickUpPlace,
          pickUpDate: searchStore.pickUpDate,
          dropOffDate: searchStore.dropOffDate,
          lat: userGeolocationStore.lat,
          lng: userGeolocationStore.lng
        }
        const params = new URLSearchParams(query)
        router.push(`/search?${params.toString()}`)
      } else {
        posthog.capture('Form Validation Failed', { isFormCorrect })
      }
    }
    return {
      searchStore,
      v$,
      onSubmit
    }
  },
  components: {
    DatePicker,
    PlacePicker
  }
}
</script>

<style scoped lang="scss">
p {
  margin: 0;
}
.wrapper {
  width: 100%;
  border-radius: 24px;
  background: #fff5ed;
}
.bg-layer {
  width: 100%;
  height: 100%;
  background-image: url('@/assets/images/search/panda.png');
  background-repeat: no-repeat;
  background-position: 100% 0%;
  color: #2c1d2a;
  font-family: 'Raleway', sans-serif;
}
.padding-layer {
  padding: 100px;
}
.title-with-br,
.title {
  font-size: 48px;
  font-weight: 700;
  line-height: 120%;
}
.title {
  display: none;
}
.start {
  font-size: 32px;
  font-weight: 400;
  line-height: 140%;
  margin-top: 24px;
}

.form {
  margin-top: 36px;
}
.inputs {
  display: grid;
  justify-content: stretch;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'place date date';
  gap: 16px;
  align-self: stretch;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'place'
      'date';
  }
}

.place-picker {
  grid-area: place;
}
.date-picker {
  grid-area: date;
}
.submit {
  padding: 16px 40px;
  border-radius: 100px;
  background-color: #fc9544;
  width: 100%;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 36px;
  transition: background-color 0.3s;
}
.submit:hover {
  background-color: #861920;
}

@media (max-width: 1100px) {
  .title-with-br {
    display: none;
  }
  .title {
    display: block;
    width: 50%;
    font-size: 36px;
  }

  @media (max-width: 768px) {
    .padding-layer {
      padding: 16px;
    }
    .bg-layer {
      background-position: 215% 0%;
      background-size: 90%;
    }
    .title {
      margin-top: 150px;
      font-size: 36px;
      font-weight: 600;
      width: 80%;
    }
    .start {
      font-size: 24px;
      margin-top: 16px;
    }
    .inputs {
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
