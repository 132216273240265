<template>
  <a-checkbox-group
    class="categories-checkbox-group"
    :value="this.value"
    :options="this.options"
    @change="this.toggleCheckbox"
  />
</template>

<script>
import { CheckboxGroup } from 'ant-design-vue'

export default {
  props: ['options', 'value'],
  emits: ['change'],
  components: {
    ACheckboxGroup: CheckboxGroup
  },
  methods: {
    toggleCheckbox (event) {
      this.$emit('change', event)
    }
  }
}
</script>

<style>
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fc9544 !important;
  border-color: #fc9544 !important;
}
.categories-checkbox-group {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
