<template>
    <div>
        <button
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          class="btn tick"
        >
          <img
            id="header-lang-img"
            :src="flag"
            alt="Header Language"
            height="20"
            class="flag-icon"
          >
        <span>{{lan.toUpperCase()}}</span>
    </button>
    <div class="dropdown-menu dropdown-menu-end lang">
          <b-link
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            href="javascript:void(0);"
            :value="entry"
            :class="{ active: lan === entry.language }"
            class="dropdown-item notify-item language py-2"
            data-lang="en"
            title="English"
            @click.prevent="setLanguage(entry.language, entry.title, entry.flag)"
           >
           <img
              :src="entry.flag"
              alt="user-image"
              height="18"
            >
            <span class="align-middle">{{ entry.title }}</span>
        </b-link>
    </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import languages from '@/constants/languages'
import posthog from 'posthog-js'

export default {
  setup () {
    return {
      languages
    }
  },
  data () {
    return {
      lan: localStorage.getItem('selectedLanguage') || 'en',
      flag: localStorage.getItem('selectedFlag') || require('@/assets/images/flags/us.svg'),
      text: localStorage.getItem('selectedLanguageTitle') || 'English'
    }
  },
  methods: {
    setLanguageFromLocalStorage () {
      const storedLanguage = localStorage.getItem('selectedLanguage')
      const storedFlag = localStorage.getItem('selectedFlag')
      const storedTitle = localStorage.getItem('selectedLanguageTitle')
      if (storedLanguage && storedFlag && storedTitle) {
        this.setLanguage(storedLanguage, storedTitle, storedFlag)
        posthog.capture('Language Settings Loaded from LocalStorage', {
          language: storedLanguage
        })
      }
    },

    setLanguage (locale, title, flag) {
      const oldLanguage = this.lan
      this.lan = locale
      this.text = title
      this.flag = flag
      localStorage.setItem('selectedLanguage', locale)
      localStorage.setItem('selectedFlag', flag)
      localStorage.setItem('selectedLanguageTitle', title)
      document.getElementById('header-lang-img').setAttribute('src', flag)
      i18n.global.locale = locale

      posthog.capture('Language Changed', {
        oldLanguage: oldLanguage,
        newLanguage: locale
      })
    }

  },

  mounted () {
    this.setLanguageFromLocalStorage()
    posthog.capture('Language Initialized', {
      language: this.lan
    })
  }

}

</script>

<style scoped lang="scss">

.dropdown-item {
  display: flex;
  gap: 10px;
}
.btn {
  position: relative;
  display: flex;
  column-gap: 8px;
  font-family: 'Raleway';
  font-size: 16px;
  border: none;
  margin: 0;
  padding: 0;
  appearance: none;
  white-space: nowrap;
}

.tick {
  position: relative;
  padding-right: 20px;

  &::after {
    position: absolute;
    inset: 6px 0 auto auto;
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 1px solid #0D0815;
    border-right: 1px solid #0D0815;
    transform: rotate(45deg);
  }
}

.flag-icon {
  border-radius: 10px;
}
</style>
