<template>
  <ol class="steps">
    <li
      v-for="step in steps"
      :key="step.stepNumber"
      :class="`step step-${step.status}`"
      @click="$emit('back', step.stepNumber)"
    >
      <div class="step-container">
        <span class="position">
          {{ step.status !== STEP_STATUSES.COMPLETED ? step.stepNumber : '' }}
          <svg
            v-if="step.status === STEP_STATUSES.COMPLETED"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :class="{ pointer: this.currentStep === 3 && step.stepNumber === 2 }"
          >
            <path
              d="M18.3334 5.5L8.25008 15.5833L3.66675 11"
              stroke="#FFFFFF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span class="title">{{ $t(step.title) }}</span>
      </div>
    </li>
  </ol>
</template>

<script>
import { reactive, watchEffect } from 'vue'

export default {
  props: {
    currentStep: Number
  },
  emits: ['back'],
  setup (props) {
    const STEP_STATUSES = {
      COMPLETED: 'completed',
      CURRENT: 'current',
      PENDING: 'pending'
    }

    const steps = reactive([
      {
        title: 'steps.yourChoice',
        status: STEP_STATUSES.COMPLETED,
        stepNumber: 1
      },
      {
        title: 'steps.confirmation',
        status: STEP_STATUSES.CURRENT,
        stepNumber: 2
      },
      {
        title: 'steps.checkout',
        status: STEP_STATUSES.PENDING,
        stepNumber: 3
      }
    ])

    const setCurrentStep = (currentStep) => {
      steps.forEach((step) => {
        if (step.stepNumber < currentStep) {
          step.status = STEP_STATUSES.COMPLETED
        }

        if (step.stepNumber === currentStep) {
          step.status = STEP_STATUSES.CURRENT
        }

        if (step.stepNumber > currentStep) {
          step.status = STEP_STATUSES.PENDING
        }
      })
    }

    watchEffect(() => {
      setCurrentStep(props.currentStep)
    })

    return {
      steps,
      STEP_STATUSES
    }
  }
}
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.steps {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr min-content;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.step {
  display: flex;
  gap: 16px;
  flex-wrap: nowrap;
  width: 100%;

  &:not(:last-child)::after {
    content: '';
    height: 1px;
    width: 100%;
    margin-top: 20px;
    background-color: #fc9544;
    flex-grow: 1;
  }

  @media (max-width: $mobile-max-width) {
    &:not(.step-current) .title {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      border: 0;
      clip: rect(0 0 0 0);
    }

    &:first-child .title {
      right: auto;
      left: 0;
      transform: translateX(0);
    }

    &:last-child .title {
      right: 0;
      left: auto;
      transform: translateX(0);
    }
  }
}

.step-current,
.step-pending {
  &:not(:last-child)::after {
    background-color: #d4d1d0;
  }
}

.step-pending {
  .position {
    background-color: #d4d1d0;
  }
}

.step-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;

  @media (max-width: $mobile-max-width) {
    position: relative;
    padding-bottom: 28px;
  }
}

.position {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  width: 40px;
  height: 40px;
  background-color: #fc9544;
  border-radius: 40px;
  box-sizing: border-box;
}

.title {
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #0e0e0e;
  white-space: nowrap;

  @media (max-width: $mobile-max-width) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
