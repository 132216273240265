<template>
  <div class="advantages">
    <div class="advantages-text">
      <p class="advantages-title">{{ $t('t-advantages-care') }}</p>
      <p class="advantages-description">{{ $t('t-welcome-description') }}</p>
    </div>
    <div class="advantages-relative-block">
      <div class="advantages-relative-wrapper">
        <div class="advantages-flex-block advantages-flex-block-1">
          <div class="advantage-block-count">X</div>
          <div class="advantage-block-count-item">{{ $t('t-approved-rentals') }}</div>
        </div>
        <img
          class="advantages-image advantages-image-bike"
          src="@/assets/images/advantages/main.png"
          alt="advantages"
        />
        <div class="advantages-image advantages-image-bg">
          <img src="@/assets/images/advantages/main-bg.png" alt="advantages" />
          <div class="advantages-flex-block advantages-flex-block-2">
            <div class="advantage-block-count">X</div>
            <div class="advantage-block-count-item">{{ $t('t-destinations') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="advantages-list">
      <div class="advantages-item">
        <img class="item-icon" src="@/assets/images/advantages/flags.svg" alt="flags" />
        <p class="item-title">{{ $t('t-advantages-multilingual') }}</p>
        <p class="item-description">{{ $t('t-advantages-multilingual-description') }}</p>
      </div>
      <div class="advantages-item">
        <img class="item-icon" src="@/assets/images/advantages/chat.svg" alt="chat" />
        <p class="item-title">{{ $t('t-advantages-chat') }}</p>
        <p class="item-description">{{ $t('t-advantages-chat-description') }}</p>
      </div>
      <div class="advantages-item">
        <img class="item-icon" src="@/assets/images/advantages/hand.svg" alt="hand" />
        <p class="item-title">{{ $t('t-advantages-ease') }}</p>
        <p class="item-description">{{ $t('t-advantages-ease-description') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
<style scoped>
.advantages-relative-wrapper {
  width: 470px;
  height: 580px;
  position: relative;
  margin: 0 auto;
}
.advantages {
  display: flex;
  color: #2c1d2a;
  font-style: normal;
  gap: 24px;
}
.advantages-text {
  flex-basis: 33%;
  flex-shrink: 2;
}
.advantages-title {
  font-family: 'Raleway', sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 140%;
  margin: 0;
}
.advantages-description {
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  margin: 30px 50px 0 0;
}
.advantages-image {
  position: absolute;
  width: 80%;
}
.advantages-image-bg img {
  width: 100%;
}
.advantages-image-bike {
  z-index: 2;
  width: 100%;
}
.advantages-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-basis: 33%;
  flex-shrink: 2;
}
.advantages-item {
  display: flex;
  flex-direction: column;
}
.item-icon {
  width: 62px;
  height: 62px;
}
.item-title {
  color: #050505;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0 0 0;
}
.item-description {
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
}
.advantages-relative-block {
  position: relative;
  flex-grow: 1;
}
.advantages-flex-block {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  border-radius: 24px;
  background: #fff5ed;
  box-sizing: border-box;
  text-align: center;
  z-index: 3;
}
.advantages-flex-block-1 {
  max-width: 120px;
  line-height: 30px;
  bottom: 30px;
  left: -30px;
}
.advantages-flex-block-2 {
  top: 30px;
  right: -60px;
}
.advantage-block-count {
  color: #fc9544;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.advantage-block-count-item {
  color: #939597;
  font-family: 'Inter';
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}
@media (max-width: 1200px) {
  .advantages {
    flex-direction: column;
    align-items: stretch;
  }
  .advantages-relative-block {
    height: 580px;
    align-self: center;
    width: 100%;
  }
  .advantages-image {
    height: 100%;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .advantages-image-bg img {
    width: auto;
  }
  .advantages-image-bg {
    transform: translateX(calc(-48px - 50%));
  }
}
@media (max-width: 576px) {
  .advantages {
    flex-direction: column;
  }
  .advantages-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
  }
  .advantages-description {
    margin: 16px 0 0 0;
  }
  .advantages-relative-wrapper {
    width: 300px;
  }
  .advantages-relative-block,
  .advantages-relative-wrapper {
    height: 437px;
  }
  .advantages-image-bg {
    width: 300px;
    transform: translateX(-50%);
  }
  .advantages-image-bg img {
    width: 100%;
    max-height: 437px;
  }
  .advantages-image-bike {
    width: auto;
    height: 100%;
    transform: translateX(calc(-50% + 26px));
  }
  .advantage-block-count-item {
    font-size: 14px;
    line-height: normal;
  }
  .advantage-block-count {
    font-size: 24px;
  }
  .advantages-flex-block-1 {
    left: -5px;
    width: 96px;
  }
  .advantages-flex-block-2 {
    right: 0;
  }
}
</style>
