<template>
  <div class="wrapper">
    <form class="form" @submit.prevent="onSubmit">
      <place-picker class="place-picker" />
      <date-picker class="date-picker" />
      <button class="submit">{{ $t('t-submit') }}</button>
    </form>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification'
import PlacePicker from '@/components/PlacePicker.vue'
import DatePicker from '@/components/DatePicker.vue'
import posthog from 'posthog-js'

import { useSearchStore } from '@/stores/search'
import { useUserGeolocationStore } from '@/stores/user-geolocation'

import 'flatpickr/dist/flatpickr.css'

export default {
  emits: ['submit'],
  setup (props, { emit }) {
    const toast = useToast()
    const router = useRouter()
    const searchStore = useSearchStore()
    const userGeolocationStore = useUserGeolocationStore()

    const v$ = useVuelidate()

    const onSubmit = async () => {
      posthog.capture('Form Submitted')
      const isFormCorrect = await v$.value.$validate()

      if (!searchStore.isPlaceSelected) {
        toast('Please select a Pick-up Place.', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
        return
      }

      if (isFormCorrect) {
        posthog.capture('Form Validated Successfully')

        const query = {
          pickUpPlace: searchStore.pickUpPlace,
          pickUpDate: searchStore.pickUpDate,
          dropOffDate: searchStore.dropOffDate,
          lat: userGeolocationStore.lat,
          lng: userGeolocationStore.lng
        }
        const params = new URLSearchParams(query)
        router.push(`/search?${params.toString()}`)
        emit('submit')
      } else {
        posthog.capture('Form Validation Failed', { isFormCorrect })
      }
    }
    return {
      searchStore,
      v$,
      onSubmit
    }
  },
  components: {
    DatePicker,
    PlacePicker
  }
}
</script>

<style scoped lang="scss">
p {
  margin: 0;
}
.wrapper {
  width: 100%;
  padding: 24px;
  border-radius: 24px;
  background: #fff5ed;

  @media (max-width: 767px) {
    padding: 24px 16px;
  }
}

.form {
  display: grid;
  justify-content: stretch;
  grid-template-columns: 1fr 1fr 1fr min-content;
  grid-template-areas: 'place date date submit';
  gap: 16px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'place'
      'date'
      'submit';
  }
}

.place-picker {
  grid-area: place;
}
.date-picker {
  grid-area: date;
}
.submit {
  margin-left: 20px;
  padding: 16px 40px;
  border-radius: 100px;
  background-color: #fc9544;
  width: 100%;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  transition: background-color 0.3s;

  grid-area: submit;

  @media (max-width: 767px) {
    margin-left: 0;
    margin-top: 16px;
  }
}
.submit:hover {
  background-color: #861920;
}
</style>
