export const TRANSMISSION_TYPES = {
  MANUAL: 'MANUAL',
  AUTOMATIC: 'AUTOMATIC'
}

export const SORT_TYPES = {
  ASC: 'ASC',
  DESC: 'DESC',
  RECOMMENDED: 'recommended'
}

export const IMAGE_EXTENSIONS = [
  '.jpg',
  '.jpeg',
  '.jpe',
  '.jif',
  '.jfif',
  '.jfi',
  '.png',
  '.gif',
  '.tiff',
  '.pdf',
  '.svg',
  '.ai',
  '.psd',
  '.heic',
  '.heif',
  '.webp'
]
