<template>
  <div v-if="status !== 'WAITING_PAYMENT'" class="upload-files-block-wrapper">
    <h2 class="title">{{ $t('t-upload-photos-or-videos') }}</h2>
    <div>{{ $t('t-upload-description-text') }}</div>
    <div
      class="manage-my-booking-drop-area"
      @click="handleClick"
      @dragover="handleDragOver"
      @drop="handleDrop"
    >
      <div>{{ $t('t-upload-drop-area') }}</div>
    </div>
    <ul v-if="uploadedImages.length" class="manage-my-booking-image-list">
      <li v-for="(image, index) in uploadedImages" :key="index">
        <img :src="image?.url ? image.url : image" alt="Uploaded Image" class="manage-my-booking-image" @click="viewImage(image?.url ? image.url : image)" />
      </li>
    </ul>
    <ul v-if="uploadedVideos.length" class="manage-my-booking-video-list">
      <li v-for="(file, index) in uploadedVideos" :key="index" class="manage-my-booking-video" @click="$emit('playVideo', file)">
        <div style="display: flex; gap: 16px">
          <img
            src="@/assets/images/icons/play-icon.svg"
            alt="play"
          />
          <div class="manage-my-booking-video-description">
            <div>{{ file?.name || 'Video' }}</div>
            <div>{{ file.size }}</div>
          </div>
        </div>
      </li>
    </ul>
    <input
      class="hidden"
      id="manage-my-booking-files-input"
      type="file"
      multiple
      @change="handleChange"
    />
  </div>
</template>

<script>
export default {
  props: ['status', 'uploadedImages', 'uploadedVideos'],
  emits: ['handleFileUpload', 'playVideo', 'viewImage'],
  methods: {
    handleDragOver (event) {
      event.preventDefault()
    },
    handleClick () {
      const input = document.getElementById('manage-my-booking-files-input')
      input.click()
    },
    handleChange (event) {
      this.$emit('handleFileUpload', event.target.files)
    },
    handleDrop (event) {
      event.preventDefault()
      this.$emit('handleFileUpload', event.dataTransfer.files)
    },
    viewImage (url) {
      this.$emit('viewImage', url)
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style-type: none;
}
.upload-files-block-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 24px;
  border-radius: 32px;
  border: 1px solid #d4d1d0;
  font-family: Raleway;
}
.title {
  color: #2c1d2a;
  font-size: 24px;
  font-weight: 600;
}
.manage-my-booking-drop-area {
  width: 100%;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  cursor: pointer;
  border-radius: 24px;
  border: 1px dashed #fc9544;
  font-size: 14px;
}
.hidden {
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%); /* browsers in the future */
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.manage-my-booking-image-list {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.manage-my-booking-image-list li {
  position: relative;
}
.manage-my-booking-image {
  border-radius: 30px;
  width: 100px;
  height: 100px;
}
.manage-my-booking-video-list {
  display: flex;
  gap: 16px;
  flex-direction: column;
  overflow: hidden;
}
.manage-my-booking-video {
  width: 100%;
  padding: 16px;
  height: 80px;
  border-radius: 24px;
  background: #fff5ed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.manage-my-booking-video img {
  cursor: pointer;
}
.manage-my-booking-video-description {
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: #0e0e0e;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
}
.manage-my-booking-video-description > div:nth-child(2) {
  color: #888888;
}
</style>
