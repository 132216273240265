<template>
  <a-pagination
    class="pagination"
    v-model:current="currentPage"
    :total="total"
    defaultPageSize="10"
    :showLessItems="true"
    :showSizeChanger="false"
    :responsive="true"
    @change="changePage"
  >
    <template #itemRender="{ type, originalElement }">
      <a v-if="type === 'prev'">
        <svg
          class="arrow arrow-prev"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.13127 13.316C5.3063 13.4943 5.59008 13.4943 5.76511 13.316L10.202 8.79549C10.2951 8.70068 10.3386 8.57455 10.3327 8.4504C10.339 8.3258 10.2955 8.19909 10.2021 8.10392L5.76518 3.58346C5.59015 3.40513 5.30637 3.40513 5.13134 3.58346C4.95631 3.76178 4.95631 4.05091 5.13134 4.22924L9.27374 8.44967L5.13127 12.6702C4.95624 12.8485 4.95624 13.1376 5.13127 13.316Z"
            fill="#2C1D2A"
          />
        </svg>
      </a>
      <a v-else-if="type === 'next'">
        <svg
          class="arrow"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.13127 13.316C5.3063 13.4943 5.59008 13.4943 5.76511 13.316L10.202 8.79549C10.2951 8.70068 10.3386 8.57455 10.3327 8.4504C10.339 8.3258 10.2955 8.19909 10.2021 8.10392L5.76518 3.58346C5.59015 3.40513 5.30637 3.40513 5.13134 3.58346C4.95631 3.76178 4.95631 4.05091 5.13134 4.22924L9.27374 8.44967L5.13127 12.6702C4.95624 12.8485 4.95624 13.1376 5.13127 13.316Z"
            fill="#2C1D2A"
          />
        </svg>
      </a>
      <component :is="originalElement" v-else></component>
    </template>
  </a-pagination>
</template>

<script>
import { Pagination } from 'ant-design-vue'
export default {
  components: {
    APagination: Pagination
  },
  props: {
    total: Number,
    offset: Number
  },
  methods: {
    changePage (page) {
      this.$emit('changePage', page)
    }
  },
  computed: {
    currentPage () {
      return this.offset + 1
    }
  }
}
</script>

<style lang="scss">
.pagination {
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-next {
    height: 32px;
    width: 32px;
    border-radius: 16px;
    border: none;
    box-sizing: border-box;

    &:focus {
      outline-color: #fc9544;
    }
  }

  .ant-pagination-item {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #2c1d2a;
    margin-right: 4px;

    @media (max-width: $mobile-max-width) {
      font-weight: 400;
    }
  }

  .ant-pagination-item-active {
    color: #ffffff;
    background-color: #fc9544;

    &:hover {
      color: #ffffff;
    }
  }

  .ant-pagination-disabled {
    svg path {
      fill: #888888;
    }
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    svg {
      fill: #fc9544;
    }
  }

  .arrow-prev {
    transform: rotate(180deg);
  }
}
</style>
