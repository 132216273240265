<template>
  <div class="filters">
    <div class="filter-heading">
      <h2>{{ $t('t-filters') }}</h2>
      <div class="reset-block" @click="this.resetFilters">
        <img src="@/assets/images/filters/reset.svg" alt="reset" />
        <div>{{ $t('t-reset-filters') }}</div>
      </div>
    </div>
    <div style="gap: 0; margin-top: 10px" class="flex-column">
      <p class="filter-title">{{ $t('t-price') }}</p>
      <div class="price-inputs">
        <input
          class="price-input"
          type="text"
          :value="roundedMinPrice"
          @input="this.handleMinPrice"
        />
        <input
          class="price-input"
          type="text"
          :value="roundedMaxPrice"
          @input="this.handleMaxPrice"
        />
      </div>
      <SliderVue
        @change="this.handlePriceSliderChange"
        :highestPrice="this.highestPrice"
        :minPrice="this.minPrice"
        :maxPrice="this.maxPrice"
      />
      <div class="price-labels">
        <div>{{ currencySymbol }} {{ roundedMinPrice }}</div>
        <div>{{ currencySymbol }} {{ roundedMaxPrice }}</div>
      </div>
    </div>
    <div class="flex-column">
      <p class="filter-title">{{ $t('t-transmission') }}</p>
      <CheckboxVue
        :checked="this.transmissions.includes('MANUAL')"
        @change="this.toggleManualTransmission"
        :label="'Manual'"
      />
      <CheckboxVue
        :checked="this.transmissions.includes('AUTOMATIC')"
        @change="this.toggleAutomaticTransmission"
        :label="'Automatic'"
      />
    </div>
    <div class="flex-column">
      <p class="filter-title">{{ $t('t-category') }}</p>
      <CheckboxGroupVue
        v-model:value="this.categoriesState"
        :options="this.categoriesOptions"
        @change="this.handleCategoriesChange"
      />
    </div>
    <div class="flex-column">
      <p class="filter-title">{{ $t('t-scooter-brand') }}</p>
      <CheckboxGroupVue
        v-model:value="this.brandsState"
        :options="this.brandsOptions"
        @change="this.handleBrandsChange"
      />
    </div>
    <div class="flex-column">
      <p class="filter-title">{{ $t('t-scooter-model') }}</p>
      <CheckboxGroupVue
        v-model:value="this.modelsState"
        :options="this.modelOptions"
        @change="this.handleModelsChange"
      />
      <div class="flex-column">
        <p class="filter-title">{{ $t('t-tags') }}</p>
        <CheckboxGroupVue
          v-model:value="this.tagsState"
          :options="this.tagsOptions"
          @change="this.handleTagsChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { TRANSMISSION_TYPES } from '../../../constants/constants'
import { useCurrencyStore } from '@/stores/currency'
import { storeToRefs } from 'pinia'
import CheckboxVue from './Checkbox.vue'
import CheckboxGroupVue from './CheckboxGroup.vue'
import SliderVue from './Slider.vue'

export default {
  props: [
    'maxPrice',
    'minPrice',
    'transmissions',
    'highestPrice',
    'categoriesList',
    'brandsList',
    'modelsList',
    'tagsList',
    'categories',
    'brands',
    'models',
    'tags'
  ],
  emits: ['setTransmissions', 'setCategories', 'setBrands', 'setPrice', 'setModels', 'setTags'],
  components: {
    CheckboxVue,
    CheckboxGroupVue,
    SliderVue
  },
  methods: {
    toggleManualTransmission () {
      const updatedTransmission = this.transmissions.includes(TRANSMISSION_TYPES.MANUAL)
        ? this.transmissions.filter((t) => t !== TRANSMISSION_TYPES.MANUAL)
        : [...this.transmissions, TRANSMISSION_TYPES.MANUAL]

      this.$emit('setTransmissions', updatedTransmission)
    },
    toggleAutomaticTransmission () {
      const updatedTransmission = this.transmissions.includes(TRANSMISSION_TYPES.AUTOMATIC)
        ? this.transmissions.filter((t) => t !== TRANSMISSION_TYPES.AUTOMATIC)
        : [...this.transmissions, TRANSMISSION_TYPES.AUTOMATIC]

      this.$emit('setTransmissions', updatedTransmission)
    },
    handleCategoriesChange (categories) {
      this.$emit('setCategories', categories)
    },
    handleBrandsChange (brands) {
      this.$emit('setBrands', brands)
    },
    handleModelsChange (models) {
      this.$emit('setModels', models)
    },
    handleTagsChange (tags) {
      this.$emit('setTags', tags)
    },
    handlePriceSliderChange (range) {
      this.$emit('setPrice', range)
    },
    handleMinPrice (price) {
      const priceWithoutSpaces = price.target.value.replace(/\s/g, '')
      const numberPrice = parseFloat(priceWithoutSpaces)
      numberPrice > this.maxPrice
        ? this.$emit('setPrice', [numberPrice, numberPrice + 1])
        : this.$emit('setPrice', [numberPrice, this.maxPrice])
    },
    handleMaxPrice (price) {
      const priceWithoutSpaces = price.target.value.replace(/\s/g, '')
      const numberPrice = parseFloat(priceWithoutSpaces)
      numberPrice < this.minPrice
        ? this.$emit('setPrice', [numberPrice, numberPrice + 1])
        : this.$emit('setPrice', [this.minPrice, numberPrice])
    },
    resetFilters () {
      this.$emit('setPrice', [0, this.highestPrice])
      this.$emit('setCategories', [])
      this.$emit('setTransmissions', [])
      this.$emit('setBrands', [])
      this.$emit('setModels', [])
      this.$emit('setTags', [])
    }
  },
  setup () {
    const currencyStore = useCurrencyStore()
    const { currencySymbol } = storeToRefs(currencyStore)

    return {
      currencySymbol
    }
  },
  computed: {
    categoriesOptions () {
      return this.categoriesList.map((category) => ({ label: category.name, value: category.name }))
    },
    brandsOptions () {
      return this.brandsList.map((brand) => ({ label: brand.name, value: brand.name }))
    },
    modelOptions () {
      return this.modelsList.map((model) => ({ label: model.model, value: model.model }))
    },
    tagsOptions () {
      return this.tagsList.map((tag) => ({ label: tag.name, value: tag.name }))
    },
    categoriesState () {
      return this.categories
    },
    brandsState () {
      return this.brands
    },
    modelsState () {
      return this.models
    },
    tagsState () {
      return this.tags
    },
    roundedMinPrice () {
      const numberPrice = +this.minPrice
      return `${numberPrice
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
    },
    roundedMaxPrice () {
      const numberPrice = +this.maxPrice
      return `${numberPrice
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
    }
  }
}
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 272px;
}
.filter-title {
  color: #2c1d2a;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.flex-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.filter-heading {
  display: flex;
  justify-content: space-between;
}
.filter-heading h2 {
  color: #2c1d2a;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
}
.reset-block {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 6px;
}
.reset-block img {
  width: 16px;
  height: 16px;
}
.reset-block div {
  color: #fc9544;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
}
.price-labels,
.price-inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.price-inputs {
  margin-top: 16px;
}
.price-input {
  border: none;
  outline: none;
  max-width: 130px;
  height: 45px;
  border-radius: 100px;
  border: 1px solid #d4d1d0;
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: #888;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
