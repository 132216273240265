<template>
  <div class="booking-information-wrapper">
    <h2 class="title">{{ $t('t-booking-information') }}</h2>
    <div class="scooter">
      <img :src="this.photo.replace('https', 'http')" alt="scooter" />
      <div>
        <p class="model">{{ this.brand }} {{ this.model }}</p>
        <p class="similar" v-if="!is_custom">{{ $t('t-or-similar') }}</p>
      </div>
    </div>
    <div v-if="this.bookingNumber">
      <div class="gray">{{ $t('t-booking-number') }}</div>
      <div>
        {{ this.bookingNumber }}
        <Tooltip :title="$t('t-copied')" :open="this.tooltipVisible"
          ><img
            class="booking-information-copy"
            src="@/assets/images/icons/copy.svg"
            @click="this.copyText"
        /></Tooltip>
      </div>
    </div>
    <div>
      <div class="gray">{{ $t('t-pick-up-place') }}</div>
      <a :href="`https://www.google.com/maps/search/${this.lat},${this.lng}`" target="_blank" rel="noopener noreferrer">{{ this.pickUpPlace }}</a>
    </div>
    <div class="dates">
      <div>
        <div class="gray">{{ $t('t-pick-up-date') }}</div>
        <div>{{ formattedPickUpDate }}</div>
      </div>
      <div>
        <div class="gray">{{ $t('t-dropoff-date') }}</div>
        <div>{{ formattedDropOffDate }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from 'ant-design-vue'

export default {
  props: ['brand', 'model', 'pickUpPlace', 'pickUpDate', 'dropOffDate', 'photo', 'bookingNumber', 'lat', 'lng', 'is_custom'],
  components: {
    Tooltip
  },
  data () {
    return {
      tooltipVisible: false
    }
  },
  methods: {
    formatDate (date) {
      const dateArr = date.split('-')
      return dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0]
    },
    copyText () {
      navigator.clipboard.writeText(this.bookingNumber)
      this.tooltipVisible = true
      setTimeout(() => {
        this.tooltipVisible = false
      }, 2000) // Hide the tooltip after 2 seconds
    }
  },
  computed: {
    formattedPickUpDate () {
      return this.formatDate(this.pickUpDate)
    },
    formattedDropOffDate () {
      return this.formatDate(this.dropOffDate)
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.booking-information-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 24px;
  border-radius: 32px;
  border: 1px solid #d4d1d0;
  font-family: Raleway;
}
.title {
  color: #2c1d2a;
  font-size: 24px;
  font-weight: 600;
}
.scooter {
  display: flex;
  gap: 8px;
  align-items: center;
  color: #050505;
  font-weight: 400;
}
.scooter img {
  width: 100px;
  height: 100px;
}
.model {
  font-size: 24px;
}
.similar {
  font-size: 16px;
}
.gray {
  color: #888;
  font-size: 16px;
  font-weight: 400;
}
.dates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}
.booking-information-copy {
  cursor: pointer;
}
</style>
